import React from "react";
import styled from "styled-components";
import WelcomeBanner from "../Components/WelcomeBanner";
import Store from "../Common/Store";
import CSLHeader from "../Components/CSLHeader";
import TaskHeader from "./TaskHeader";
import AlertBox from "./Common/AlertBox";
import AMIndex from "./AM/AMIndex";
import TeamIndex from "./Team/TeamIndex";
import CSLLoader from "./Common/CSLLoader";

const NoPermission = styled.div`
  display: block;
  box-sizing: border-box;
  height: 40px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  position: absolute;
  top: 100px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border: 2px solid #37ada7;
  border-radius: 10px;
  color: #37ada7;
  z-index: 1000;
`;

class Index extends React.Component {
  state = { view_count: 0, cur_view: null };

  constructor(props) {
    super(props);
  }

  componentSetState = () => {
    //console.log("dsbcij",Store.getStoreData('view'))
    let role = Store.getStoreData("view");

    let referenceUrl = false;
    // console.log("document.referrer", document.referrer);
    //new
    let isreferdModule = "";
    let isreferdDocmgmtModule = false;
    if (document.referrer !== "") {
      let pieces = document.referrer.split("/");
      //console.log("pieces", pieces);
      isreferdModule = pieces[pieces.length - 1];
    }
    // console.log("isreferdModule", isreferdModule);
    //new
    let task_id = this.props.match ? this.props.match.params.taskId : 0;
    if (task_id !== 0) {
      referenceUrl = true;
    }
    let isReferenceEmail = false;
    if (referenceUrl && document.referrer === "") {
      isReferenceEmail = true;
    }
    if (referenceUrl && isreferdModule === "docmgmt") {
      isreferdDocmgmtModule = true;
    }
    Store.updateStore("isReferenceEmail", isReferenceEmail);
    Store.updateStore("isReferenceDocmgmt", isreferdDocmgmtModule);
    Store.updateStore("isreferenceUrl", referenceUrl);
    this.setState({ cur_view: role });
  };

  componentDidUpdate = (prevprops) => {
    if (this.props === prevprops) return;
    this.componentSetState();
  };

  componentDidMount = () => {
    this.componentSetState();
  };

  alertHandler = (result, stack) => {
    // this.setState({alert_param: null})
    window.location = "/";
  };

  render() {
    let lp = Store.getStoreData('language_pack')
    let task_id = this.props.match ? this.props.match.params.taskId : 0;
    // console.log("task_id1", task_id);
    let alert_param = {
      title: "No Access",
      message:
        "You are not authorised to access this module. If you think this is in error, please contact your administrator.",
      ok_text: "Ok",
      confirm: false,
      alertHandler: this.alertHandler,
      stack: { id: 0 },
    };
    return (
      <div style={{ backgroundColor: "#f2f2f3", position: "relative" }}>
        {(() => {
          if (task_id > 0) {
            return <TaskHeader />;
          } else {
            return <CSLHeader headerText={"Attestations"} />;
          }
        })()}
        <WelcomeBanner welcome={ (lp['welcome_back'] === undefined ? 'Welcome back' : lp['welcome_back']) +`, `+ Store.getStoreData("contact")} />
        {(() => {
          switch (this.state.cur_view) {
            case "manager_index":
            case "am_index":
              return (
                <AMIndex refreshCallback={this.refreshState} taskId={task_id} />
              );
              break;
            case "team_index":
              return (
                <TeamIndex
                  refreshCallback={this.refreshState}
                  taskId={task_id}
                />
              );
              break;
            case "no_access":
              return <AlertBox alertParam={alert_param} />;
              break;
            default:
              return (
                <NoPermission>
                  Sorry, you do not have permission to view this page.{" "}
                </NoPermission>
              );
          }
        })()}
        {/* <CSLLoader />              */}
      </div>
    );
  }
}

export default Index;
