import React from "react";
import styled from "styled-components";
import { FiMoreVertical } from "react-icons/fi";


const Container = styled.div`
    margin-left: 86px;
    font-size: 28px;
    font-weight: 600;
    color: #7c7a7a;
    position: absolute;
    margin-top: 10px;
`;

class Options extends React.Component {
    closeDropdown = (event) => {
        event.preventDefault();
        this.props.closeDropdown();
    }
    
    performAction = (entity_id, action_type) => (event) => {
        event.preventDefault();
        this.props.performAction(entity_id, action_type);
    }
    render () {
        console.log("In Options");
        //const module_config = Store.getStoreData("module_config");
        return (
            <div onMouseLeave={this.closeDropdown} style={{ width:"120px", right: this.props.home === true ? "3%" : "9%", position: "absolute", backgroundColor: "#ffffff", padding: "0px 10px", boxShadow: "rgb(167 166 166) 0px 0px 5px", zIndex: "1" }}>
                {
                    console.log('actions  ---> ',this.props.actions)
                }
                {
                    this.props.actions.map((item, index) => {
                        // console.log("index:", index);
                        if ( item.show === 1) {
                            return (
                                <div
                                    style={{
                                        fontSize: "14px",
                                        cursor: "pointer",
                                        paddingTop: index === 0 ? "10px" : "none",
                                        paddingBottom: "10px"
                                    }}
                                    key={index}
                                    onClick={this.performAction(this.props.entity_id, item.action)}
                                >
                                    {item.name}
                                </div>
                            )
                        }
                    })
                }
            </div>  
        );
    }
}

class SimpleDropdown extends React.Component {
    
    state = {show_dropdown: false};
    
    toogleDropdown = (event) => {
        event.preventDefault();
        this.setState({show_dropdown: this.state.show_dropdown === true ? false : true});
    }
    
    closeDropdown = () => {
        this.setState({show_dropdown: false});
    }
    
    render () {
        console.log("Dropdown state:", this.state);
        let home = false;
        if ("home" in this.props) {
            home = this.props.home;
        }
        return (
            <Container>
                <FiMoreVertical style={{cursor: "pointer"}} onClick={this.toogleDropdown} />
                {
                    this.state.show_dropdown === true && 
                    <Options 
                        home={home}
                        closeDropdown={this.closeDropdown}
                        entity_id={this.props.entity_id}
                        actions={this.props.actions}
                        performAction={this.props.performAction}
                        show={this.props.show}
                    />
                }
            </Container>
        );
    }
    
}

export default SimpleDropdown;