import React, { Component } from 'react';
import styled from "styled-components";

import { FaPlusCircle } from "react-icons/fa";
import { FaPencilAlt, FaCaretLeft, FaCaretRight, FaLock } from "react-icons/fa";
import Store from '../../../Common/Store.js';
import APICall from '../../../Common/APICall.js';

const LangTab = styled.div`
  width:150px;
  height: 30px;
  padding-top: 10px;
  margin-top:10px;
  margin-left:5px;
  display:flex;
  background-color:blue;
  color:white;
  cursor: pointer;
  border: 1px solid #3961A8;
   font-size: small;
   text-align: center;
`;

const ScrollDiv = styled.div`
    width: 470px;
    height: 50px;
    float: left;
    display: flex;
`;


const FixedDiv = styled.div`
    overflow-x: hidden;
    overflow-y: hidden;
    width: 70%;
    height: 50px;
`;


const OverflowText = styled.div`

white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
width:100px
`

class LanguageTopHeader extends React.Component {
    state ={lang_id: null, lang_code: null, show_modal: false, lang_name: '', is_default: false, languages: [], ready: false, active_lang: '', alert_param: null, slided_amount: 0}
    componentDidMount(){
        let languages = Store.getStoreData('languages')
        this.setState({ready: true, languages: languages, active_lang: this.props.active_lang});
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            let languages = Store.getStoreData('languages')
            this.setState({ready: true, languages: languages, active_lang: this.props.active_lang});
        }
    }
    

    slide = (l) => {
        let dynamycLanguages = this.state.languages.filter((elem, index) => elem.company_id !== 0)
        let max_allowedslide = (dynamycLanguages.length - 4) * 150
        let slided_amount = this.state.slided_amount
        if (l > 0 && slided_amount === 0) {
            console.log('do nothing')
        } else {
            slided_amount = slided_amount + l

        }
        this.setState({ slided_amount })
        //alert(slided_amount)
        console.log(this.state.slided_amount)
    }
    slideCss = () => {
        let slided_amount = this.state.slided_amount
        let styleObj = { marginLeft: `${slided_amount}px` }
        console.log(styleObj)
        return styleObj

    }


    render() {
        if (this.state.ready === false) {
            return <div/>;
        }
        return (
            <div style={{ width: '100%', display: 'flex' }}>
                {console.log('this.props.languages, ', this.state)}
                <FixedDiv>
                    <ScrollDiv style={this.slideCss()}>
                    {

                        Object.keys(this.state.languages).map((elem) => {
                            console.log('sss, ', elem)
                            return (
                                <LangTab style={{backgroundColor: this.state.languages[elem].language_id === this.state.active_lang ? '#4472C4' : '#B4C7E7'}} onClick={()=>this.props.changeLanguage(this.state.languages[elem].language_id)}  >
                                    <OverflowText> {this.state.languages[elem].language_name}  </OverflowText>
                                </LangTab>
                            )
                        })}
                     </ScrollDiv>  
                </FixedDiv>
                <div style={{ display: 'flex', height: '10px', margin: "20px" }} >
                 <FaCaretLeft onClick={() => this.slide(150)} />
                 <FaCaretRight onClick={() => this.slide(-150)} />
                </div>
            </div>
        );
    }
}

export default LanguageTopHeader;