import React from 'react';
import { FaTimes, FaTrashAlt, FaQuestionCircle } from 'react-icons/fa';
import styled from 'styled-components';
import TestEmailModal from './TestEmailModal';
import AlertBox from '../Common/AlertBox';
const EmailContainer = styled.div`
	width: 70%;
	position: absolute;
	background-color: #f7f7f7;
	border-radius: 5px;
	box-shadow: 0 0 20px #a7a6a6;
	z-index: 1;
	padding: 20px;
`;
const EmailInput = styled.input`
	height: 30px;
	width: 98%;
	border: 1px solid #ffffff;
	border-radius: 3px;
	box-shadow: 0 0 4px #c4c4c4;
	padding: 5px;
	color: #222222;
	font-size: 15px;
	font-family: 'Montserrat', sans-serif;
`;
const EmailTextArea = styled.textarea`
	height: 150px;
	width: 98%;
	border: 1px solid #ffffff;
	border-radius: 3px;
	box-shadow: 0 0 4px #c4c4c4;
	padding: 5px;
	color: #222222;
	font-family: 'Montserrat', sans-serif;
`;
const VariablePill = styled.div`
	padding: 10px 15px;
	background-color: #ffffff;
	color: #222222;
	font-weight: 600;
	border: 1px solid #222222;
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 10px;
	border-radius: 2em;
	cursor: pointer;
`;
const CancelBtn = styled.button`
	background-color: #ffffff;
	border: 1px solid #ebebeb;
	border-radius: 4px;
	padding: 10px 20px;
	cursor: pointer;
`;
const SubmitBtn = styled.button`
	background-color: #37ada7;
	border: 1px solid #37ada7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;

const OpenTestEmailModalButton = styled.button`
	background-color: #37ada7;
	border: 1px solid #37ada7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;

class EmailConfigModal extends React.Component {
	state = {
		content: null,
		variables: null,
		subject_cursor: null,
		body_cursor: null,
		isTestEmailModalOpen: false,
		alert_param: null
	};

	componentDidMount() {
		this.setState({ content: this.props.content, variables: this.props.variables });
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({ content: this.props.content, variables: this.props.variables });
		}
	}

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
	};

	handleChange = (event) => {
		event.preventDefault();
		let { content } = this.state;
		content[event.target.name] = event.target.value;
		this.setState({ content });
	};

	setSubjectCursorPosition = (event) => {
		event.preventDefault();
		let { subject_cursor } = this.state;
		subject_cursor = event.target.selectionStart;
		this.setState({ subject_cursor, body_cursor: null });
	};

	setBodyCursorPosition = (event) => {
		event.preventDefault();
		let { body_cursor } = this.state;
		body_cursor = event.target.selectionStart;
		this.setState({ body_cursor, subject_cursor: null });
	};

	insertVariable = (variable_name) => (event) => {
		variable_name = `{{${variable_name}}}`;
		event.preventDefault();
		let { content, variables, subject_cursor, body_cursor } = this.state;
		if (subject_cursor !== null) {
			content.subject = [
				content.subject.slice(0, subject_cursor),
				variable_name,
				content.subject.slice(subject_cursor)
			].join('');
		}
		if (body_cursor !== null) {
			content.body = [ content.body.slice(0, body_cursor), variable_name, content.body.slice(body_cursor) ].join(
				''
			);
		}
		this.setState({ content, subject_cursor: null, body_cursor: null });
	};

	modifyEmail = (event) => {
		event.preventDefault();
		let { content } = this.state;
		this.props.modifyEmails(content);
	};

	OpenTestEmailModal = (e) => {
		//TODO: open modal popup here...
		e.preventDefault();
		let { content } = this.state;
		console.log(content);
		this.setState({ ...this.state, isTestEmailModalOpen: true });
	};
	closeModalonX = () => {
		this.setState({ ...this.state, isTestEmailModalOpen: false });
	};
	closeTestModal = (message, flag) => {
		debugger;
		let alert_param = {
			title: flag == false ? 'Success' : 'Alert',
			message: message,
			ok_text: 'Ok',
			confirm: false,
			alertHandler: this.alertremoveHandler,
			stack: { submit_form: flag }
			//stack: { id: 0 }
		};
		this.setState({ ...this.state, alert_param: alert_param });
	};
	alertremoveHandler = (result, stack) => {
		this.setState({ alert_param: null, isTestEmailModalOpen: stack.submit_form });
	};

	render() {
		if (this.state.content === null) {
			return <div>Loading...</div>;
		}

		return (
			<div>
				<EmailContainer>
					<div style={{ float: 'left' }}>Configure Email - {this.state.content.name}</div>
					<div style={{ float: 'right' }}>
						<FaTimes style={{ cursor: 'pointer' }} onClick={this.closeModal} />
					</div>
					<div style={{ clear: 'both' }} />
					<div style={{ marginTop: '20px' }}>
						<div
							style={{
								float: 'left',
								width: '10%',
								fontWeight: '600',
								color: '#222222',
								fontSize: '15px',
								paddingTop: '8px'
							}}
						>
							Subject
						</div>
						<div style={{ float: 'left', width: '90%', paddingRight: '20px', boxSizing: 'border-box' }}>
							<EmailInput
								name="subject"
								value={this.state.content.subject}
								onChange={this.handleChange}
								onClick={this.setSubjectCursorPosition}
								onKeyUp={this.setSubjectCursorPosition}
							/>
						</div>
						<div style={{ clear: 'both' }} />
					</div>
					<div style={{ marginTop: '20px' }}>
						<div
							style={{
								float: 'left',
								width: '10%',
								fontWeight: '600',
								color: '#222222',
								fontSize: '15px',
								paddingTop: '8px'
							}}
						>
							Body
						</div>
						<div style={{ float: 'left', width: '90%', paddingRight: '20px', boxSizing: 'border-box' }}>
							<EmailTextArea
								name="body"
								value={this.state.content.body}
								onChange={this.handleChange}
								onClick={this.setBodyCursorPosition}
								onKeyUp={this.setBodyCursorPosition}
							/>
						</div>
						<div style={{ clear: 'both' }} />
					</div>
					<div style={{ width: '100%', height: '2px', backgroundColor: '#EBEBEB', marginTop: '20px' }} />
					<div style={{ color: '#222222', fontWeight: '600', marginTop: '15px' }}>
						Variables <FaQuestionCircle style={{ color: '#37ada7' }} />
					</div>
					<div style={{ marginTop: '10px' }}>
						{this.state.variables.map((variable) => {
							return (
								<VariablePill key={variable.name} onClick={this.insertVariable(variable.name)}>
									{variable.nickname}
								</VariablePill>
							);
						})}
					</div>
					<div style={{ width: '100%', height: '2px', backgroundColor: '#EBEBEB', marginTop: '20px' }} />

					<div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
						<OpenTestEmailModalButton onClick={(e) => this.OpenTestEmailModal(e)}>
							Send Test Email
						</OpenTestEmailModalButton>
						<div style={{ textAlign: 'right' }}>
							<CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
							<SubmitBtn style={{ marginLeft: '15px' }} onClick={this.modifyEmail}>
								Submit
							</SubmitBtn>
						</div>
					</div>
					{this.state.isTestEmailModalOpen && (
						<div>
							<TestEmailModal
								content={this.state.content}
								closeModalOnXSign={this.closeModalonX}
								closeTestModal={this.closeTestModal}
							/>
						</div>
					)}
				</EmailContainer>
				<AlertBox alertParam={this.state.alert_param} />
			</div>
		);
	}
}

export default EmailConfigModal;
