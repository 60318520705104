import Store from "./../../Common/Store";
import Utils from "./../../Common/Utils";
import APICall from "../../Common/APICall";

class XcelLoader {
  attestation_export_tasks(tasks) {
    let j = 0;
    let results = [];
    for (let d of tasks) {
      j++;
      let elem = {
        Status: d.status,
        "Attestation Name": d.document_name,
        "Upload Date": d.upload_date,
        Tasks: d.followuptask,
        Flagged: d.flagged,
        "Sent for Information": d.info,
        "Sent for Acknowledgement": d.acknowledge,
        Overdue: d.outstanding_acknowledge,
      };
      results.push(elem);
    }
    return results;
  }


  parseQuestionSetAnswers(qset_answers) {
    let result = qset_answers.map(d => {
      if (d.qset_answer) {
        let sections = { ...d.qset_answer.sections, sections: Object.values(d.qset_answer.sections).map(d => (d.questions)) };
        return sections.sections;
      }
    })

    return result.map(data => {
      if (data) {
        let res = [];
        data.forEach(d => {
          res.push(...d);
        })
        return res;
      }
      return data;
    })
  }

  // sharewith_export_tasks(payload) {
  //   let results = [];
  //   let tasks = payload.tasks;
  //   console.log("TASK PAYLOAD === > ", tasks);
  //   const attest_name = payload.attest_name;
  //   if (payload.atType === "Document") {
  //     for (let task of tasks) {
  //       let elem = {
  //         "Attestation Name": attest_name,
  //         "Shared With": task.name_value,
  //         "Date Shared": task.dateshared,
  //         Type: task.type,
  //         Status: task.status,
  //         "Due Date": task.due_date,
  //         "Completed Date": task.completed_date,
  //         //'Flagged' : d.is_flagged
  //       };
  //       results.push(elem);
  //       return results;
  //     }
  //   }

  //   const cur_modal_data = Store.getStoreData("cur_q_modal_data");
  //   const task_json = JSON.parse(cur_modal_data.task_json);
  //   const q_sections = Object.values(task_json.object_data.qset.sections);
  //   const questions = q_sections.map(q => (q.questions));

  //   const all_ques = [];
  //   questions.forEach(q => {
  //     all_ques.push(...q);
  //   })

  //   // getting the users answers
  //   const qset = tasks.task_json.map(data => ({ qset_answer: data.qset_answer }));
  //   const qset_answers = this.parseQuestionSetAnswers(qset)

  //   const shareUsers = tasks.shareUsersList;

  //   // defining the element
  //   let elem = {
  //     "Attestation Name": attest_name,
  //     "Shared With": "",
  //     "Date Shared": "",
  //     Status: "",
  //     "Due Date": "",
  //     "Completed Date": "",
  //   };

  //   // appending the questions cols
  //   all_ques.forEach((val, idx) => {
  //     let qtext = val.text;

  //     if (Utils.isBase64(qtext)) {
  //       qtext = window.decodeURIComponent(atob(qtext));
  //     }
  //     let regex = /(<([^>]+)>)/gi;

  //     elem[`Q${idx + 1} Title`] = val.title;
  //     elem[`Q${idx + 1} Text`] = qtext.replace(regex, "");
  //     elem[`Q${idx + 1} Response`] = "";
  //     elem[`Q${idx + 1} Comments`] = "";
  //   });

  //   // appending the remaining last cols
  //   elem["Flagged Y/N"] = "";
  //   elem["Comments"] = "";
  //   elem["No of Follow Up Tasks"] = 0;


  //   // appending the users responses
  //   shareUsers.forEach((val, idx) => {
  //     elem["Shared With"] = val.name_value;
  //     elem["Date Shared"] = val.dateshared;
  //     elem["Status"] = val.status;
  //     elem["Due Date"] = val.due_date;
  //     elem["Completed Date"] = val.completed_date;

  //     for (let i = 0; i < all_ques.length; i++) {
  //       elem[`Q${i + 1} Response`] = qset_answers[idx] ? qset_answers[idx][i].answer : "";
  //       elem[`Q${i + 1} Comments`] = qset_answers[idx] ? (qset_answers[idx][i].comment ? qset_answers[idx][i].comment : "") : "";
  //       elem["Flagged Y/N"] = qset_answers[idx] ? (qset_answers[idx][i].answer === 'Yes' ? 'N' : "Y") : "";
  //     }
  //     const follow_up_counts = Store.getStoreData('followupcount');
  //     elem["Comments"] = qset[idx].qset_answer ? qset[idx].qset_answer.overall_comment : "";
  //     elem["No of Follow Up Tasks"] = val.id in follow_up_counts ? follow_up_counts[val.id] : 0;
  //     results.push({ ...elem });
  //   })
  //   return results;
  // }
  sharewith_export_tasks_document(tasks){		
    let form_data = Store.getStoreData('cur_q_modal_data');
    let task_json = JSON.parse(form_data.task_json); 
    let attestationName = task_json.object_data.headerinfo.document_name;
		let j = 0;
		let results = [];
		for(let d of tasks) {
			j++;		  
		    let elem = {	
                'Attestation Name': attestationName,	            
		            'Shared With': d.name_value,
		            'Date Shared': d.dateshared,
		            'Type': d.type,
		            'Status' : d.status,
		            'Due Date' : d.due_date,
		            'Completed Date' : d.completed_date,
					//'Flagged' : d.is_flagged					
		           };		    
		    results.push(elem)
		}
		return results;
	}
  sharewith_export_tasks_questionset(tasks){		
    let form_data = Store.getStoreData('cur_q_modal_data');
    let shareUserQuesAnsw = Store.getStoreData('shareUserQuesAnsw');
    let task_json = JSON.parse(form_data.task_json); 
    let attestationName = task_json.object_data.headerinfo.document_name;
    let allQuestions = [];
    Object.keys(task_json.object_data.qset.sections).map((id, i) => {
      let sec = task_json.object_data.qset.sections[id];
      sec.questions.map((q, j) => {
        allQuestions.push(q);
      });
    });
    console.log("allQuestions==>", allQuestions)

		let j = 0;
		let results = [];
		for(let d of tasks) {
			j++;		  
		    let elem = {	
                'Attestation Name': attestationName,	            
		            'Shared With': d.name_value,
		            'Date Shared': d.dateshared,		            
		            'Status' : d.status,
		            'Due Date' : d.due_date,
		            'Completed Date' : d.completed_date,						
		           };		
        console.log("shareUserQuesAnsw==>", shareUserQuesAnsw)
        if(!(d.id in shareUserQuesAnsw)){
          let m = 0;
          for (let k of allQuestions) {
            m++;
            let qtext = k.text;
            if (Utils.isBase64(qtext)) {
              qtext = window.decodeURIComponent(atob(qtext));
            }
            let regex = /(<([^>]+)>)/gi;
            elem["Q" + m + " Title"] = k.title;
            elem["Q" + m + " Text"] = qtext.replace(regex, "");
            elem["Q" + m + " Response"] = '';
            elem["Q" + m + " Comment"] = '';
          }
          elem["Flagged Y/N"] = "N";
          elem["Overall Comments"] = "";
          elem["No of Follow Up Tasks"] = 0;
        }
        if(d.id in shareUserQuesAnsw){
          let sections = shareUserQuesAnsw[d.id].sections;
          let responceQuestions = [];
          Object.keys(sections).map((id, i) => {
            let sec = sections[id];
            sec.questions.map((q, j) => {
              responceQuestions.push(q);
            });
          });
          let l = 0;
          for (let k of responceQuestions) {
            l++;
            let qtext = k.text;
            if (Utils.isBase64(qtext)) {
              qtext = window.decodeURIComponent(atob(qtext));
            }
            let regex = /(<([^>]+)>)/gi;

            let qanswer = "";
            if (k.answer === "Yes") {
              qanswer = "Yes";
            } else if (k.answer === "No") {
              qanswer = "No";
            } else {
              qanswer = "N/A";
            }
            elem["Q" + l + " Title"] = k.title;
            elem["Q" + l + " Text"] = qtext.replace(regex, "");
            elem["Q" + l + " Response"] = qanswer;
            if (k.answer !== "Yes" && k.answer !== null) {
              elem["Q" + l + " Comment"] = k.comment;
            }else{
              elem["Q" + l + " Comment"] = "";
            }
          }
          elem["Flagged Y/N"] = shareUserQuesAnsw[d.id].flagged ? "Y" : "N";
          elem["Overall Comments"] = shareUserQuesAnsw[d.id].overall_comment;
          elem["No of Follow Up Tasks"] = d.id in Store.getStoreData('followupcount') ? Store.getStoreData('followupcount')[d.id] : 0;
        }
               
        
		    results.push(elem)
		}
		return results;
	}

  completed_export_tasks(state) {
    let results = [];

    let elem = {
      "Attestation Name": state.task.object_data.headerinfo.document_name,
      "Due Date": state.uploadedDate,
      "Completed Date": state.completeDate,
    };

    let allQuestions = [];
    Object.keys(state.task.object_data.qset_answer.sections).map((id, i) => {
      let sec = state.task.object_data.qset_answer.sections[id];
      sec.questions.map((q, j) => {
        allQuestions.push(q);
      });
    });
    console.log("allQuestions==>", allQuestions);
    let j = 0;
    for (let k of allQuestions) {
      j++;
      let qtext = k.text;
      if (Utils.isBase64(qtext)) {
        qtext = window.decodeURIComponent(atob(qtext));
      }
      let regex = /(<([^>]+)>)/gi;

      let qanswer = "";
      if (k.answer === "Yes") {
        qanswer = "Yes";
      } else if (k.answer === "No") {
        qanswer = "No";
      } else {
        qanswer = "N/A";
      }

      elem["Q" + j + " Title"] = k.title;
      elem["Q" + j + " Text"] = qtext.replace(regex, "").replace(/&nbsp;/g," ");
      elem["Q" + j + " Response"] = qanswer;
      if (k.answer !== "Yes" && k.answer !== null) {
        elem["Q" + j + " Comment"] = k.comment;
      }
    }

    elem["Flagged Y/N"] = state.questionSetFlaged ? "Y" : "N";
    elem["Overall Comments"] = state.task.object_data.qset_answer.overall_comment;
    elem["No of Follow Up Tasks"] = Store.getStoreData("updated_followups") === null ? 0 : Store.getStoreData("updated_followups").length;
    results.push(elem);

    console.log("RESULT +++++++ ", results);

    return results;
  }

    attestation_export_questionsets(sections) {
      let j = 0;
      let results = [];
      let q_no = 0
      Object.keys(sections).map((id, i) => {
        let sec = sections[id]
        console.log("section===>", sec)
        sec.questions.map((q, j) => {
          q_no++
          let qtext = q.text;
          if (Utils.isBase64(qtext)) {
            qtext = window.decodeURIComponent(atob(qtext))
          }
          let regex = /(<([^>]+)>)/gi;
          console.log('q===>', q)
          let elem = {};
          elem["#"] = "Q"+q_no;
          elem["Question Title"] = q.title;
          elem["Question Text"] = qtext.replace(regex, "").replace(/&nbsp;/g," ");
          elem["Attestation required at question level"] = q.attestation_required === 0 ? "No" : "Yes";
          elem["Show Default"] = q.attestation_required === 0 ? "-" : q.show_default === 0 ? "No" : "Yes";
          elem["Attestation Text"] = q.attestation_required === 0 ? "-" : q.show_default === 0 ? q.attestation_text : "-";
          elem["Confirmation Text"] = q.attestation_required === 0 ? "-" : q.show_default === 0 ? q.confirmation_text : "-";
          elem["Allow N/A Response"] = 'allow_na' in q ? q.allow_na === 0 ? "No" : "Yes" : "No";
          elem["Correct Answer"] = 'correct_answer' in q ? q.correct_answer : 'Yes';

          let flag_option = 'Incorrect';
          if('flagged_opt' in q && q.flagged_opt === 'no_flag'){
            flag_option = 'No flag';
          }else if('flagged_opt' in q && q.flagged_opt === 'na'){
            flag_option = 'N/A';
          }else if('flagged_opt' in q && q.flagged_opt === 'incorrect_na'){
            flag_option = 'Incorrect and N/A';
          }
          elem["Flagged Options"] = flag_option;
          
          let req_text = 'No Attachment';
          if('req_attach' in q && q.req_attach === 'mand_corrt_ans'){
            req_text = 'Mandatory for Correct Answer';
          }else if('req_attach' in q && q.req_attach === 'mand_incorrt_ans'){
            req_text = 'Mandatory for Incorrect Answer';
          }else if('req_attach' in q && q.req_attach === 'mand_both'){
            req_text = 'Mandatory for Both';
          }else if('req_attach' in q && q.req_attach === 'optional'){
            req_text = 'Optional';
          }
          elem["Requires Attachment for Responses"] = req_text;
          results.push(elem);
        })
      })
      return results;
    }

}
export default new XcelLoader();
