import React from 'react';
import {FaTimes, FaPlusCircle} from 'react-icons/fa';
// import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import Store from '../../Common/Store';
import Dropzone from '../Common/Dropzone';
import Utils from '../../Common/Utils';
import APICall from '../../Common/APICall';
import AlertBox from './../Common/AlertBox';
import '../../App.css';

import DatePicker from 'react-date-picker';
import moment from 'moment';

const FileContainer = styled.div`
    width: 300px;
    display: flex;
`;
const FileInfoContainer = styled.div`
    margin-left:10px;
    color: #969EA2;
    width: 220px;
    height: 70px;
`;

const FileTypeContainer = styled.div`
    width: 100px;
    min-height:70px;
    height: 70px;
    background-color: #fff;
    color: #969EA2;
    font-size:22px;
    text-align:center;
    padding-top:22px;
    font-weight:800;
    border: 1px solid #AA9EA2;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 92%;
    left: -6%;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 15px 24px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
    font-weight: 600;
    font-size: 17px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const ReportModalHRule = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #eaeaea;
    margin-top: 5px;
    margin-bottom: 5px;
`

const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #ffffff;
`;
const MRModalLabel = styled.label`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
`;

const MRModalTextArea = styled.textarea`
    height: 150px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const AddResultConfigButton = styled.button`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    padding-left: 20px;
    font-weight: 600;
`;
const MultisetLayoutContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    margin-bottom: 5px;
`;
const TermDiv = styled.div`
    display: inline-block;
    box-sizing: border-box;
    width: 25%;
    height: auto;
    float: left;
`;
const TermInputDiv = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #414141;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const FCKCommentDiv = styled.div`
    padding-left: 20px;
    padding-right: 8px;
    color: #a0a0a0;
`;
const FCKTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-weight: 100;
    font-family: 'Montserrat',sans-serif;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 20%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 80%;
    padding-top:7px;
`;
const MRModalBackBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 2px solid #dadada;
    margin-left: 5px;
    font-size: 11px;
    margin-right: 10px;
    float: right;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    `;
    const ScoreContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;    
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 100%;
    margin: 0px auto;
    padding: 15px;
    left: 15%;   
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 10px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
`;
const HeaderButton = styled.div`
    color: #ffffff;
    width: 30px;
    height: 29px;
    float: right;
    font-size: 20px;
    cursor: pointer;
`;
const MRModalInput = styled.input`
    height: 26px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    margin-top: 10px;
    font-family: 'Montserrat',sans-serif;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;


class AMFollowupTask extends React.Component
{
    state = { tasks: null, ready: false, followup_sub: '', assigned_to: '', followup_note: '', date: new Date(), review_list: [], parent_task_id: 0,
    due_by: '1970-01-01' ,binFiles: [], cur_files_json: null, isdesable: false, alert_param: null}

	

	componentDidMount() {
        let index = Store.getStoreData('ftask_index') === null ? 0 : Store.getStoreData('ftask_index');
        if(index >= 0) {
            console.log("followuptaskvalue",Store.getStoreData('cur_followup_task'))
            let cur_followup_task = Store.getStoreData('cur_followup_task');
            let objectData = JSON.parse(Store.getStoreData('cur_followup_task').task_json).object_data;

            let followup_sub = objectData.subject;
            let followup_note = objectData.note;
            let assigned_to = cur_followup_task.cur_assigned_to;
            let date = new Date(cur_followup_task.due_date);
            let dateStr = moment(cur_followup_task.due_date).format('YYYY/MM/DD');
            
            let task_json = JSON.parse(cur_followup_task.task_json);
            let cur_files_json = [];
            console.log("task_json.object_data==>", task_json.object_data)
            if('bin_files' in task_json.object_data){
                cur_files_json = task_json.object_data.bin_files;
            } 
            console.log("cur_files_json==>", cur_files_json)
            let reviewee_name = this.props.revieweeList.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );
            this.setState({review_list: reviewee_name, followup_sub, followup_note, assigned_to, cur_files_json,
                due_by: dateStr, date: date, ready: true})
        }else{
            let date = new Date()
            let curmonth = parseInt(date.getMonth());
            let currmonth = curmonth + 1;
            var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/0'+currmonth+'/'+date.getDate();
            let reviewee_name = this.props.revieweeList.sort(function(a,b) {return (a.ContactName > b.ContactName) ? 1 : ((b.ContactName > a.ContactName) ? -1 : 0);} );
            this.setState({due_by: dateStr,review_list: reviewee_name, 
            assigned_to: '', ready: true})
        }		
    }
    
    closeTaskModal = () => {        
        this.props.closeFollowupTaskModal();
    }
    handleChange = (evt) => {
        // const value = evt.target.value;
        this.setState({[evt.target.name]: evt.target.value});
    }

    onDateChangeDueBy = (date) => {
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr = currmonth >= 10 ? date.getFullYear()+'/'+currmonth+'/'+date.getDate() : date.getFullYear()+'/0'+currmonth+'/'+date.getDate();
        this.setState({due_by: dateStr, date: date});
    }

    changeFollowupNote = (event) => {
        event.preventDefault();
        this.setState({followup_note: event.target.value});
    }

    addTask = (event) => {
        event.preventDefault();    
        if(this.state.assigned_to === ''){
            let alert_param = {title: 'ERROR', message: 'Please select a user to assign the task to.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.checkvalidation, stack: {}}
            this.setState({alert_param: alert_param}) 
            return;
        }    
        let task = {}      
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        //console.log("bin_files==>",bin_files)  
        task.subject = this.state.followup_sub;
        task.note = this.state.followup_note;
        task.assignedto = this.state.assigned_to;
        task.dueby = this.state.due_by;
        task.binFiles = bin_files;
        console.log("task==>",task)
        this.props.refreshTaskValue(task)        
        this.setState({ followup_sub: '', followup_note: '',assigned_to: '', date: new Date()})            
    } 

    checkvalidation = (result, stack) => {        
        this.setState({alert_param: null})               
    }

    EditTask = (event) => {
        event.preventDefault(); 
        if(this.state.assigned_to === ''){
            let alert_param = {title: 'ERROR', message: 'Please select a user to assign the task to.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.checkvalidation, stack: {}}
            this.setState({alert_param: alert_param}) 
            return;
        }  
        let task = {}      
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];        
        task.subject = this.state.followup_sub;
        task.note = this.state.followup_note;
        task.assignedto = this.state.assigned_to;
        task.dueby = this.state.due_by;
        task.binFiles = bin_files;
        console.log("task==>",task)
        this.props.EditrefreshTaskValue(task)        
        this.setState({ followup_sub: '', followup_note: '',assigned_to: '', date: new Date(),binFiles: [], cur_files_json: null})  
    }    
    
    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    

	render() {
        let lp = Store.getStoreData('language_pack')  
		let index = Store.getStoreData('ftask_index') === null ? 0 : Store.getStoreData('ftask_index');
        let hederText = lp['add_new_followup_task_header'] === undefined ? 'Add new follow-up task' : lp['add_new_followup_task_header'];
        if(index >= 0)hederText = lp['edit_followup_task_header'] === undefined ? 'Edit follow-up task' : lp['edit_followup_task_header'];
        if(!this.state.ready){
            return(<div></div>)
        }
        
		return (
            <div>
			<ScoreContainer>
                <ScoreHeaderContainer>{hederText}<HeaderButton onClick={this.closeTaskModal}><FaTimes /></HeaderButton></ScoreHeaderContainer>
                <ScoreBodyContainer>
                    <MRModalLabel>{lp['subject_header'] === undefined ? 'Subject' : lp['subject_header']}</MRModalLabel>
                    <MRModalInput type="text" name="followup_sub" onChange={this.handleChange} value={this.state.followup_sub}/>
                    <FCKCommentDiv style={{padding: "0px", marginTop: "10px",marginBottom: "10px"}}>
                        <MRModalLabel>{lp['note_header'] === undefined ? 'Note' : lp['note_header']}</MRModalLabel>
                        <FCKTextarea rows="6" style={{width: "98%",marginTop: "10px"}} value={this.state.followup_note} onChange={this.changeFollowupNote} />
                    </FCKCommentDiv>
                    <MRModalLabel>{lp['assign_dropdown_header'] === undefined ? 'Assign To' : lp['assign_dropdown_header']}</MRModalLabel>
                    <MRModalSelect onChange={this.handleChange} name="assigned_to" value={this.state.assigned_to}>
                        <option key={0} value="">{lp['assign_dropdown_default_option'] === undefined ? 'Select' : lp['assign_dropdown_default_option']}</option>
                        {
                            (() => {
                                if(this.state.review_list.length !== 0) {
                                    let reviewees = [];
                                    //console.log("his.state.reviewee_id", this.state.reviewee_id);
                                    for(let item of this.state.review_list){
                                        reviewees.push(<option key={item.ID} value={item.ID}>{item.ContactName}</option>);
                                        
                                    }
                                    return reviewees;
                                }

                            })()
                        }
                    </MRModalSelect>
                    <MRModalLabel>{lp['due_by_header'] === undefined ? 'Due By' : lp['due_by_header']}</MRModalLabel>
                    <CSLDateCover>
                        <DatePicker
                                onChange={this.onDateChangeDueBy}
                                clearIcon={null}
                                calendarIcon={null}
                                locale={"en-GB"}
                                minDate={new Date()}
                                value={this.state.date}
                                />
                    </CSLDateCover>
                    <ModalNameLabelDiv>{lp['followup_attachments_header'] === undefined ? 'Attachments' : lp['followup_attachments_header']}</ModalNameLabelDiv>
                    <ModalNameInputDiv>
                        <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={!this.state.isdesable}/>
                    </ModalNameInputDiv>
                </ScoreBodyContainer>
                {
                    (() => {                        
                        if(index >= 0) {
                            return(
                                <div style={{height: '60px', width: '100%', paddingRight: "12px"}}>
                                    <MRModalNextBtn onClick={this.EditTask}>Update Task</MRModalNextBtn>
                                    <MRModalCancelBtn onClick={this.closeTaskModal}>Close</MRModalCancelBtn>
                                </div>
                            )
                        }else {
                            return(
                                <div style={{height: '60px', width: '100%', paddingRight: "12px"}}>
                                    <MRModalNextBtn onClick={this.addTask}>Add Task</MRModalNextBtn>
                                    <MRModalCancelBtn onClick={this.closeTaskModal}>Close</MRModalCancelBtn>
                                </div>
                            )
                        }
                    })()
                }      
                
            </ScoreContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
		)
		
	}

}

export default AMFollowupTask