import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store.js';
import Dropzone from '../Common/Dropzone';
import {IoIosArrowUp} from 'react-icons/io';
import CSLTable from '../Common/CSLTable';
import DatePicker from 'react-date-picker';
import '../../App.css';
import Utils from '../../Common/Utils.js';
import AlertBox from '../Common/AlertBox';


const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 38px;
    z-index: 1002;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const ProgressContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 400px;
    position: fixed;
    top: 80px;
    text-align: center;
    left: calc(50vw - 200px);
    z-index: 1008;
    color:  #143151;  
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
`;

const ProgressBarTotal = styled.div`
    width: 380px;
    height: 14px;
    background-color: white;
    border: 1px solid #04ADA8; 
    margin-top: 8px;
    margin-bottom: 8px;
`;

const ProgressBar = styled.div`
    height: 100%;
    background-color: blue;
    float: left;
`;

const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #364E69;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalLabelSub = styled.div`
    color: #000000;    
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #5F6368;    
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const OverviewInfoHeader = styled.div`
    padding: 16px 15px;
    background-color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; 
    margin: 0 auto;
    margin-top: 30px; 
    border: 1px solid #d7dddf;       
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;    
    margin: 0 auto;
    margin-bottom: 30px;
`;
const SearchInput = styled.input`
    height: 25px;
    width: 95%;
    margin-right: 180px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
`;

class AMShareAttestationModal extends React.Component
{
    state = {
        ready: false,
        selected_gc: '0',
        binFiles: [],
        cur_files_json: null,    
        documentVersion: "",
        uploadedDate: new Date(),
        editedDate: new Date(),
        distribution_group: 0,  
        distribution_group_list: [],
        distribution_group_user_list: [],
        distribution_group_user_list_all: [],
        show_share_tab: false,
        rowchecked: false,
        shareUserList: {},
        acknowledgementDueDate: '',
        parrentTask: null,
        show_progress: false,
        gc_users: null,
        stop_polling: false,
        assigned_to: null,
        share_count: 0,
        is_submitting: false,
        is_qset: false,
        alert_param: null,
        isSelectedMsg: false
    };

    stop_polling = false
    
    componentDidMount()
	{
        // console.log("taskid===>", this.props.taskid);
        // let postData = { "command" : "get_task", "task_id" : this.props.taskid }
        // let api = new APICall();
        // api.command(postData, this.processAttestationTaskData);
        this.processAttestationTaskData();  
    }

    processAttestationTaskData(){
        let form_data = {};
        //console.log('task result', result);
        //Store.updateStore('cur_q_modal_data', result.result.parenttask);
        //form_data = result.result.parenttask;
        form_data = Store.getStoreData('cur_task_data');
        let binFiles = Store.getStoreData('prve_bin_files') !== null ? Store.getStoreData('prve_bin_files') : [];
        
        let task_json = JSON.parse(form_data.task_json);
        console.log('task_json', task_json);
        let is_qset = task_json.object_data.at_type === 'QuestionSet' ? true : false
        
        let duedate = new Date(form_data.due_date);
        let uploadmonth = parseInt(duedate.getMonth());
        let uploadedmonth = uploadmonth + 1;
        var uploadedDateStr = duedate.getDate() < 10 ? '0' + duedate.getDate()+'/' : duedate.getDate()+'/';        
        uploadedDateStr += uploadedmonth >= 10 ? uploadedmonth+'/'+duedate.getFullYear() : '0'+uploadedmonth+'/'+duedate.getFullYear();

        let actiondate = new Date(task_json.task_data.actiondate);
        let editmonth = parseInt(actiondate.getMonth());
        let editedmonth = editmonth + 1;
        var editedDateStr = actiondate.getDate() < 10 ? '0' + actiondate.getDate()+'/' : actiondate.getDate()+'/';        
        editedDateStr += editedmonth >= 10 ? editedmonth+'/'+actiondate.getFullYear() : '0'+editedmonth+'/'+actiondate.getFullYear();
        let distribution_group_list = [];

        let moduleconfig = Store.getStoreData('module_config');   
        if('distribution_list' in moduleconfig && moduleconfig.distribution_list.length !== 0){
            distribution_group_list = moduleconfig.distribution_list
        }

        console.log("distribution_group_list==>", distribution_group_list)
        distribution_group_list.sort(function(a,b) {return (a.distributionName > b.distributionName) ? 1 : ((b.distributionName > a.distributionName) ? -1 : 0);} );

        let gc_users = Store.getStoreData('gc_users')

        let distribution_group_user_list = []
        let distribution_group_user_list_new = []
        let show_share_tab = false
        let group_allusers = Store.getStoreData('attestation_share_group_allusers');
        if('general' in moduleconfig && moduleconfig.general.enable_distribution_list === false){
            group_allusers.forEach((user) => {         
                user.select = false;
                user.isInformation = true; 
                user.isAcknowledge = true;
                user.isFlaged = true;
                user.isInformationCheck = false;
                user.isAcknowledgeCheck = is_qset;
                user.isFlagedCheck = false;
                distribution_group_user_list.push(user);
            })
            show_share_tab = true
        }

        console.log("distribution_group_user_list===> ", distribution_group_user_list)

        let role = Store.getStoreData('role')
        let userroles = Store.getStoreData('userroles')
        let contact_id = Store.getStoreData('contact_id')        
        let assignee_role = userroles[task_json.object_data.headerinfo.assignedTo.toString()]
        let show_all = role === 'admin_manager' && (task_json.object_data.headerinfo.assignedTo === '' || assignee_role === 'manager') ? true : false
        let gcuser_all = gc_users;
        
        if(role === 'am' && Object.keys(gc_users).length !== 0){
            distribution_group_user_list.forEach((user) => { 
                if(show_all || (this.hasCommonCompanyCheckFirst(contact_id, user.user_id, gcuser_all) && this.hasCommonCompanyCheckFirst(task_json.object_data.headerinfo.assignedTo, user.user_id, gcuser_all))){
                    if(user.user_id in gc_users){
                        distribution_group_user_list_new.push(user)
                    }
                }
            })
        }else{
            distribution_group_user_list_new = distribution_group_user_list;
        }

        console.log("show_all", show_all, "gcuser_all===> ", gcuser_all)

        console.log("distribution_group_user_list===> ", distribution_group_user_list_new)        

        Store.updateStore('distribution_group_user_list_gc', distribution_group_user_list_new)

        let dtext = task_json.object_data.headerinfo.description;        
        if(Utils.isBase64(dtext)){
            dtext = window.decodeURIComponent(atob(dtext))            
        }

        this.setState({ cur_files_json: binFiles,
            documentVersion: task_json.object_data.headerinfo.document_version,
            assigned_to: task_json.object_data.headerinfo.assignedTo,
            uploadedDate: uploadedDateStr,
            editedDate: editedDateStr,
            gc_users: gc_users,
            description: dtext,
            distribution_group_list: distribution_group_list,
            parrentTask: form_data,
            distribution_group_user_list: distribution_group_user_list_new,
            distribution_group_user_list_all: distribution_group_user_list_new,
            show_share_tab: show_share_tab,
            is_qset: is_qset,
            ready: true});               
    }

    hasCommonCompanyCheckFirst = (manager_id, user_id, gc_users) => {
        if(Object.keys(gc_users).length === 0)return true
        if(manager_id === '') return true
        let manager_companies = gc_users[manager_id]
        let user_companies = gc_users[user_id]
        for(let cid in manager_companies) {
            for(let uid in user_companies) {
                if(cid.toString() === uid.toString())return true
            }
        }
        return false
    }

    closeView = () => {
        console.log("this.props.taskid",this.props.taskid)
        this.stop_polling = true
        this.props.closeModal(this.props.taskid);
    } 
    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    handleChangeDistGc = (evt) => {
        let contactsemail = Store.getStoreData('contactsemail') !== null ? Store.getStoreData('contactsemail') : {};
        let distributionusers = []
        this.state.distribution_group_list.forEach((user) => {
            if (parseInt(user.distributionID) === parseInt(evt.target.value)) {                
                distributionusers = user.distributionGroupUserID;
            }
        })
        
        let userroles = Store.getStoreData('userroles');
        let distribution_group_user_list = [];        
        let role = Store.getStoreData('role')
        let assignee_role = userroles[this.state.assigned_to.toString()]        
        let show_all = role === 'admin_manager' && (this.state.assigned_to === '' || assignee_role === 'manager') ? true : false
        let contact_id = Store.getStoreData('contact_id')       
        
        distributionusers.forEach((user) => {   
            if(user.user_id in userroles){
                user.email = user.user_id in contactsemail ? contactsemail[user.user_id] : '';      
                user.select = false;
                user.isInformation = true; 
                user.isAcknowledge = true;
                user.isFlaged = true;
                user.isInformationCheck = false;
                user.isAcknowledgeCheck = false;
                user.isFlagedCheck = false;                
                if(show_all || (this.hasCommonCompany(contact_id, user.user_id) && this.hasCommonCompany(this.state.assigned_to, user.user_id))){
                    if(this.state.selected_gc === '0' || this.state.selected_gc in this.state.gc_users[user.user_id]) {
                        distribution_group_user_list.push(user);
                    }
                }
                
                
            }
        })
        let show_share_tab = false;
        if(distribution_group_user_list.length !== 0){
            show_share_tab = true;
        }
        console.log("distribution_group_user_list===>sourav",distribution_group_user_list)
        this.setState({distribution_group: evt.target.value, distribution_group_user_list: distribution_group_user_list,distribution_group_user_list_all: distribution_group_user_list,show_share_tab: show_share_tab})
    }

    selectAllshareUser = (event) => {
        let rowchecked = this.state.rowchecked;
        rowchecked = rowchecked ? false : true;        
        let userListAll = this.state.distribution_group_user_list;         
        
       if(rowchecked) {   
            let i = 0;            
            let selectArray = [];
            for(let d of userListAll) {                
                let selectObject = {}           
                i++;   
                let val = {status: "Unopen", type: "acknowledge", isflagged: 0,send_date: Date.now()};        
                this.state.shareUserList[d.user_id] = val;                          
                selectObject.user_id = d.user_id;
                selectObject.company_id = d.company_id;
                selectObject.email = d.email;
                selectObject.name = d.name; 
                selectObject.role = d.role;                
                selectObject.select = true;
                selectObject.isAcknowledge = false;
                selectObject.isAcknowledgeCheck = true;
                selectObject.isFlaged = false;
                selectObject.isFlagedCheck = false;
                selectObject.isInformation = false;
                selectObject.isInformationCheck = false;                             
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,distribution_group_user_list: selectArray, distribution_group_user_list_all:selectArray, shareUserList: this.state.shareUserList})
            console.log("shareUserList", this.state.shareUserList)
       }else{
            let i = 0;            
            let selectArray = [];
            for(let d of userListAll) {                
                let selectObject = {}           
                i++; 
                delete this.state.shareUserList[d.user_id.toString()];                            
                selectObject.user_id = d.user_id;
                selectObject.company_id = d.company_id;
                selectObject.email = d.email;
                selectObject.name = d.name; 
                selectObject.role = d.role;               
                selectObject.select = false;  
                selectObject.isAcknowledge = true;
                selectObject.isAcknowledgeCheck = false;
                selectObject.isFlaged = true;
                selectObject.isFlagedCheck = false;
                selectObject.isInformation = true;
                selectObject.isInformationCheck = false;                              
                selectArray.push(selectObject)
            }
            this.setState({rowchecked: rowchecked,distribution_group_user_list: selectArray, distribution_group_user_list_all:selectArray, shareUserList: this.state.shareUserList})
            console.log("shareUserList", this.state.shareUserList)
       }
   }

   selectShareUser = (event) => {        
        let index = event.target.value;        
        let userListAll = this.state.distribution_group_user_list;       
            
        if(userListAll[index].select){
            userListAll[index].select = false;  
            userListAll[index].isInformation = true;
            userListAll[index].isInformationCheck = false; 
            userListAll[index].isAcknowledge = true; 
            userListAll[index].isAcknowledgeCheck = false;
            userListAll[index].isFlaged = true; 
            userListAll[index].isFlagedCheck = false;         
            delete this.state.shareUserList[userListAll[index].user_id.toString()];                    
        } else {
            userListAll[index].select = true;  
            userListAll[index].isAcknowledge = false;
            userListAll[index].isAcknowledgeCheck = true;            
            userListAll[index].isInformation = false; 
            userListAll[index].isFlaged = false;         
            let userid = userListAll[index].user_id.toString();
            let val = {status: "Unopen", type: "acknowledge", isflagged: 0,send_date: Date.now()};    
            this.state.shareUserList[userid] = val;                            
        }
        this.setState({distribution_group_user_list: userListAll, shareUserList: this.state.shareUserList,rowchecked: false})
        console.log("shareUserList", this.state.shareUserList)
        let allselectcheck = true
        for(let d of userListAll) {            
            if(!d.select){
                allselectcheck = false
            }
        }
        if(allselectcheck){
            this.setState({rowchecked: true})
        }
    }

    selectinformation = (event) => {        
        let index = event.target.value;
        let userListAll = this.state.distribution_group_user_list;  

        if(userListAll[index].isInformationCheck){            
            userListAll[index].isInformationCheck = false;
            userListAll[index].isAcknowledgeCheck = true;
            let userid = userListAll[index].user_id.toString();           
            this.state.shareUserList[userid].type = "acknowledge";                              
        }else{  
            let userid = userListAll[index].user_id.toString();                
            this.state.shareUserList[userid].type = "information"; 
            userListAll[index].isInformationCheck = true; 
            userListAll[index].isAcknowledgeCheck = false;             
        } 

        this.setState({distribution_group_user_list: userListAll, shareUserList: this.state.shareUserList})
        console.log("shareUserList:-",this.state.shareUserList);        
    }

    selectacknowledge = (event) => {        
        let index = event.target.value;
        let userListAll = this.state.distribution_group_user_list;  

        if(userListAll[index].isAcknowledgeCheck){  
            userListAll[index].isAcknowledgeCheck = false;
            userListAll[index].isInformationCheck = true;
            let userid = userListAll[index].user_id.toString();           
            this.state.shareUserList[userid].type = "information";                              
        }else{  
            let userid = userListAll[index].user_id.toString();                
            this.state.shareUserList[userid].type = "acknowledge";             
            userListAll[index].isAcknowledgeCheck = true; 
            userListAll[index].isInformationCheck = false;             
        } 

        this.setState({distribution_group_user_list: userListAll, shareUserList: this.state.shareUserList})
        console.log("shareUserList:-",this.state.shareUserList);        
    }

    selectflagged = (event) => {        
        let index = event.target.value;
        let userListAll = this.state.distribution_group_user_list;  

        if(userListAll[index].isFlagedCheck){  
            userListAll[index].isFlagedCheck = false;            
            let userid = userListAll[index].user_id.toString();           
            this.state.shareUserList[userid].isflagged = 0;                              
        }else{  
            let userid = userListAll[index].user_id.toString();                
            this.state.shareUserList[userid].isflagged = 1;           
            userListAll[index].isFlagedCheck = true;                      
        } 

        this.setState({distribution_group_user_list: userListAll, shareUserList: this.state.shareUserList})
        console.log("shareUserList:-",this.state.shareUserList);        
    }

    hasCommonCompany = (manager_id, user_id) => {
        if(Object.keys(this.state.gc_users).length === 0)return true
        if(manager_id === '') return true
        let userroles = Store.getStoreData('userroles')
        let assignee_role = userroles[manager_id.toString()]
        if(assignee_role === 'manager') return true
        let manager_companies = this.state.gc_users[manager_id]
        let user_companies = this.state.gc_users[user_id]
        for(let cid in manager_companies) {
            for(let uid in user_companies) {
                if(cid.toString() === uid.toString())return true
            }
        }
        return false
    }

    processMyshareGroup = () => {
        let role = Store.getStoreData('role')
        let userroles = Store.getStoreData('userroles')
        let contact_id = Store.getStoreData('contact_id')
        console.log('contact_id, role, userroles, this.state.assigned_to', contact_id, role, userroles, this.state.assigned_to)
        let assignee_role = userroles[this.state.assigned_to.toString()]
        let show_all = role === 'admin_manager' && (this.state.assigned_to === '' || assignee_role === 'manager') ? true : false
        var sharedata = this.state.distribution_group_user_list; 
        console.log("sharedata",sharedata, "show_all=>", show_all);
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns = []
        ret.columns.push({'Header' : <div><input style={{cursor: "pointer"}} type="checkbox" checked={this.state.rowchecked} onClick={this.selectAllshareUser}/></div>,sortable: false,accessor: 'header', Cell: row => (
            <div>
                <input type="checkbox" style={{cursor: "pointer"}} checked={row.original.select} value={row.index} onClick={this.selectShareUser}/>
            </div>
         ), width: 142, headerStyle: {textAlign: 'left'}})
        ret.columns.push({Header: 'Name', accessor: 'name_value', minWidth: 100, headerStyle: {textAlign: 'left'}})
        ret.columns.push({Header: 'Email', accessor: 'email_value', minWidth: 80, headerStyle: {textAlign: 'left'}})
        if(!this.state.is_qset)ret.columns.push({'Header' : 'Information',accessor: 'information', Cell: row => (
            <div>
                <input type="checkbox" style={{cursor: "pointer"}} checked={row.original.is_information_check} value={row.index} disabled={row.original.is_information} onClick={this.selectinformation}/>
            </div>
         ), width: 142, headerStyle: {textAlign: 'left'}})
        if(!this.state.is_qset)ret.columns.push({'Header' : 'Acknowledge',accessor: 'acknowledge', Cell: row => (
            <div>
                <input type="checkbox" style={{cursor: "pointer"}} checked={row.original.is_acknowledge_check} value={row.index} disabled={row.original.is_acknowledge} onClick={this.selectacknowledge}/>
            </div>
         ), width: 142, headerStyle: {textAlign: 'left'}})
        // ret.columns.push({'Header' : 'Flagged',accessor: 'flaged', Cell: row => (
        //     <div>
        //         <input type="checkbox" style={{cursor: "pointer"}} checked={row.original.is_flagged_check} value={row.index} disabled={row.original.is_flagged} onClick={this.selectflagged}/>
        //     </div>
        //  ), width: 142, headerStyle: {textAlign: 'left'}})

         sharedata.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
         for(let d of sharedata) { 
            i++;
            let elem = {
                    'index': i,
                    'id': d.user_id, 
                    'name_value' : d.name,                    
                    'email_value' : d.email,
                    'is_information' : d.isInformation,
                    'is_acknowledge' : d.isAcknowledge,
                    'is_flagged' : d.isFlaged,
                    'is_information_check' : d.isInformationCheck,
                    'is_acknowledge_check' : d.isAcknowledgeCheck,
                    'is_flagged_check' : d.isFlagedCheck,
                    'select' : d.select                    
                   };
            //console.log("show_all==>", show_all, "(this.hasCommonCompany(contact_id, d.user_id)==>", this.hasCommonCompany(contact_id, d.user_id),"this.hasCommonCompany(this.state.assigned_to, d.user_id)=>", this.hasCommonCompany(this.state.assigned_to, d.user_id))
            if(show_all || (this.hasCommonCompany(contact_id, d.user_id) && this.hasCommonCompany(this.state.assigned_to, d.user_id))){
                if(this.state.selected_gc === '0' || this.state.selected_gc in this.state.gc_users[d.user_id]) {
                    ret.data.push(elem);
                    // ret.columns=ret.data.map(d=>{
                    //     return ret.columns[d.index];
                    // })
                }
            }
        }
        console.log('ret ==== >', ret)
        return ret;
    }

    onacknowledgementDueDate = (date) => {        
        this.setState({acknowledgementDueDate: date});
    }

    submitandShare = (event) => {
        event.preventDefault();
        //console.log("Sourav",parseInt(this.state.distribution_group))
        let moduleconfig = Store.getStoreData('module_config');
        let problems = false;
        let message = '';
        let lp = Store.getStoreData('language_pack')
        if('general' in moduleconfig && moduleconfig.general.enable_distribution_list && parseInt(this.state.distribution_group) === 0){
            //alert("Please choose the Distribution Group and select at least one user.")
            problems = true;
            message = 'Please choose the Distribution Group and select at least one user.';
        }else if(Object.keys(this.state.shareUserList).length === 0){
            //alert("Please select atleast one user.")
            problems = true;
            message = 'Please select atleast one user.';
        }else if(this.state.acknowledgementDueDate === ''){
            //alert("Please select Acknowledgement Due Date.")
            problems = true;
            message = 'Please select Acknowledgement Due Date.';
        }
        if(problems) {
            let alert_param = {title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'], message: message, ok_text: 'Ok', confirm: false, 
            alertHandler: this.checkalertparam, stack: {}}
            this.setState({alert_param: alert_param})           
            return false
        }
        else{
            let isAssignTask = 0;
            if('general' in moduleconfig && moduleconfig.general.allow_repeat_functionality_enable){
                isAssignTask = 1;
            }            
            let curmonth = parseInt(this.state.acknowledgementDueDate.getMonth());
            let currmonth = curmonth + 1;
            var dueDateStr = currmonth >= 10 ? this.state.acknowledgementDueDate.getFullYear()+'/'+currmonth+'/' : this.state.acknowledgementDueDate.getFullYear()+'/0'+currmonth+'/';
            dueDateStr += this.state.acknowledgementDueDate.getDate() < 10 ? '0' + this.state.acknowledgementDueDate.getDate() : this.state.acknowledgementDueDate.getDate();


            let task_json = JSON.parse(this.state.parrentTask.task_json);
            console.log("task_json",task_json)
            task_json['task_data'].actiondate = Date.now();
            task_json['object_data'].headerinfo.distribution_group_id = parseInt(this.state.distribution_group);
            task_json['object_data'].headerinfo.distribution_group_user_list = this.state.distribution_group_user_list;
            task_json['object_data'].headerinfo.shareUserList = this.state.shareUserList;
            task_json['object_data'].headerinfo.acknowledgementDueDate = dueDateStr;
            task_json['object_data'].headerinfo.isNextReviewAssignTask = 1;
            task_json['action_data'] = {action: "COMP_ATTESTATION_ASSIGNMENT", actiondate: Date.now(), cur_lane: "COMP_ATTESTATION_ASSIGNMENT", assigned_to: 0};

            let distribution_group_user_list_object = {};			
            for(let k of this.state.distribution_group_user_list){
                distribution_group_user_list_object[k.user_id] = {email: k.email,name: k.name};
            }

            let taskjson = JSON.stringify(task_json);            
            taskjson = JSON.parse(taskjson);
            // let postData = {command: "start_share_attestation_task", task_json: taskjson, taskid: this.props.taskid, isAssignCreateTask: isAssignTask, distribution_group_user_list_object: distribution_group_user_list_object};
            let postData = {command: "share_attestation_task", task_json: taskjson, taskid: this.props.taskid, isAssignCreateTask: isAssignTask, distribution_group_user_list_object: distribution_group_user_list_object};
            console.log('postData', postData)
            this.setState({is_submitting: true})
            const api = new APICall();
            api.command(postData, this.afterCompletedSubmitShare);
        }
    }
    checkalertparam = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
    }

    afterCompletedSubmitShare = (result) => {  
        console.log('afterCompletedSubmitShare result', result)      
        // alert('Policy Attestation shared successfully');               
        // window.location.reload();

        setTimeout(() => {
            this.trackShareProgress()
        }, 2000);

        this.setState({show_progress: true, share_count: 0})
    }

    processTrackProgress = (result) => {
        console.log('result trackprogress', result)
        let count = result.result.count
        Store.updateStore('hide_progress', false)
        if(count < this.state.shareUserList.length - 1 && !this.stop_polling) {
            setTimeout(() => {
                this.trackShareProgress()
            }, 2000);
            this.setState({share_count:count})
        } else {
            window.location.reload();
            // if(Store.getStoreData('isreferenceUrl') === true){
            //     if(Store.getStoreData('isReferenceEmail') === true){
            //         window.location = '/select/microservice/mstop/attest';
            //     }else if(Store.getStoreData('isReferenceDocmgmt') === true){
            //         window.location = '/select/microservice/mstop/docmgmt';
            //     }else{
            //         window.location = '/select/microservice/mstop/tmview';
            //     }            
            // } else {
            //     this.props.closeModalUpdate();
            // }
        }
    }

    trackShareProgress = () => {
        let postData = {command: "get_share_count", task_id: this.props.taskid};
        const api = new APICall();
        Store.updateStore('hide_progress', true)
        api.command(postData, this.processTrackProgress);
    }

    searchValue = (event) => {
		event.preventDefault();
        console.log("Sourav",event.target.value);		
        let search_string = event.target.value;
        if (search_string === "") {
            let user_list_all = this.state.distribution_group_user_list_all;            
            this.setState({distribution_group_user_list: user_list_all});
        }else {
            let user_list_all = this.state.distribution_group_user_list_all;
            let filtered_values = [];
            user_list_all.forEach((user) => {
                if (user.name.toLowerCase().includes(search_string.toLowerCase())) {
                    filtered_values.push(user);
                }                
            })            
            let user_list = filtered_values;
            this.setState({distribution_group_user_list: user_list})
        }
	}

    handleChangeGC = (event) => {  
        
        let role = Store.getStoreData('role')
        let userroles = Store.getStoreData('userroles')
        let contact_id = Store.getStoreData('contact_id')        
        let assignee_role = userroles[this.state.assigned_to.toString()]
        let show_all = role === 'admin_manager' && (this.state.assigned_to === '' || assignee_role === 'manager') ? true : false
        

        let userList = Store.getStoreData('distribution_group_user_list_gc');
        console.log("sssspppp=>", this.state.gc_users)
        let shareDistributionUserList = [];
        userList.forEach((user) => {
            if(show_all || (this.hasCommonCompany(contact_id, user.user_id) && this.hasCommonCompany(this.state.assigned_to, user.user_id))){
                if(event.target.value === '0' || (user.user_id in this.state.gc_users && event.target.value in this.state.gc_users[user.user_id])) {
                    shareDistributionUserList.push(user);
                }   
            }            
        })     
        
        
        console.log("shareDistributionUserList",shareDistributionUserList)
        let show_share_tab = false;
        if(shareDistributionUserList.length !== 0){
            show_share_tab = true;
        }

        let isSelectedMsg = false
        let idx = 0
        this.state.distribution_group_list.map((gc) => {
            console.log('distribution_group_list gc', gc)                                                        
            if(!(event.target.value === '0' || (gc.distributionItem === 'company' && gc.curgcid === event.target.value))) { 
                idx++  
            }
        })

        console.log("idx==>", idx, "===", this.state.distribution_group_list.length)
        if(idx === this.state.distribution_group_list.length){
            isSelectedMsg = true
        }

        this.setState({selected_gc: event.target.value, distribution_group_user_list: shareDistributionUserList,distribution_group_user_list_all: shareDistributionUserList,show_share_tab: show_share_tab, isSelectedMsg})        
    }

    render()
    {  
        let moduleconfig = Store.getStoreData('module_config');
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        let role = Store.getStoreData('role')
        let userroles = Store.getStoreData('userroles')
        let contact_id = Store.getStoreData('contact_id')
        // console.log('contact_id, role, userroles, this.state.assigned_to', contact_id, role, userroles, this.state.assigned_to)
        let assignee_role = this.state.assigned_to === '' ? '' : userroles[this.state.assigned_to.toString()]
        let show_all = role === 'admin_manager' && (this.state.assigned_to === '' || assignee_role === 'manager') ? true : false
        let gcs = Store.getStoreData('gc_company')   
        let lp = Store.getStoreData('language_pack')     
        return (
            <div>           
            <MRModalContainer> 
                <MRModalHeader>
                    <MRModalHeaderText>Share Attestation</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <MRModalSubHeaderText>Define and select the users to share the current attestation with</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalBody>
                    <div style={{clear: "both"}}></div>
                    <div style={{position: "relative",padding: "35px 35px"}}>
                        <div style={{height: "auto",backgroundColor: "#ffffff",borderRadius: "4px",padding: "25px",marginBottom: "25px"}}>
                                {
                                    (() => {
                                        if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                            return(<div>
                                                    <MRModalLabel>Document Information</MRModalLabel>
                                                    <ModalNameInputDiv>
                                                        <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false}/>
                                                    </ModalNameInputDiv>
                                                    <hr style={{marginTop: "20px", color: "#EBEBEB"}}/>
                                            </div>)
                                        }
                                    })()
                                }                                
                                <div style={{display: "inline-block",float: "left"}}>                                
                                    <MRModalLabel>Document Version</MRModalLabel>
                                    <MRModalLabelSub style={{fontWeight: "600"}}>{this.state.documentVersion}</MRModalLabelSub>                                
                                </div>
                                <div style={{display: "inline-block",float: "right"}}>
                                    <div style={{display: "inline-block",marginRight: "80px"}}>
                                    <MRModalLabel>Uploaded Date</MRModalLabel>
                                    <MRModalLabelSub style={{fontSize: "12px"}}>{this.state.uploadedDate}</MRModalLabelSub>
                                    </div>
                                    <div style={{display: "inline-block"}}>
                                    <MRModalLabel>Edited Date</MRModalLabel>
                                    <MRModalLabelSub style={{fontSize: "12px"}}>{this.state.editedDate}</MRModalLabelSub>
                                    </div>
                                </div>
                        <div style={{clear: "both"}}></div>
                        <MRModalLabel>Description</MRModalLabel>
                        {/* <div style={{fontFamily: "'Montserrat', sans-serif",whiteSpace: "pre-line"}}>{this.state.description}</div>  */}
                        <div dangerouslySetInnerHTML={{__html: this.state.description}} style={{textAlign: "justify", whiteSpace: "pre-line", fontFamily: "'Montserrat', sans-serif",fontSize: "14px"}}></div>
                        </div>
                        {
                            (() => {
                                if(Object.keys(this.state.gc_users).length > 0) {
                                    return (<div>
                                            <MRModalLabel style={{color: "#030303"}}>Restrict to Group Company</MRModalLabel> 
                                            <MRModalSelect name="selected_gc" value={this.state.selected_gc} onChange={this.handleChangeGC}>
                                            <option value='0'>Select a Group Company...</option>
                                            {
                                                gcs.map((gc, i) => {
                                                    if(gc.is_active === 1 && gc.is_gc) {
                                                        if(show_all) {
                                                            return <option key={i} value={gc.id}>{gc.company_name}</option>
                                                        } else if(role === 'admin_manager' && assignee_role === 'am') {
                                                            if(gc.id in this.state.gc_users[this.state.assigned_to])return <option key={i} value={gc.id}>{gc.company_name}</option>
                                                        } else if(role === 'am' && assignee_role === 'am') {
                                                            if(gc.id in this.state.gc_users[this.state.assigned_to] && gc.id in this.state.gc_users[contact_id])return <option key={i} value={gc.id}>{gc.company_name}</option>
                                                        } else if (role === 'am' && assignee_role === 'manager') {
                                                            if(gc.id in this.state.gc_users[contact_id])return <option key={i} value={gc.id}>{gc.company_name}</option>
                                                        } else if (role === 'am' && assignee_role === '') {
                                                            if(gc.id in this.state.gc_users[contact_id])return <option key={i} value={gc.id}>{gc.company_name}</option>
                                                        }
                                                    }
                                                })
                                            }
                                            </MRModalSelect>
                                        </div>)
                                }
                            })()
                        }

                        {
                            (() => {
                                if('general' in moduleconfig && moduleconfig.general.enable_distribution_list){
                                    return(
                                        <div>
                                            <MRModalLabel style={{color: "#030303"}}>Share from Distribution Group</MRModalLabel>   
                                            <MRModalSelect name="distribution_group" value={this.state.distribution_group} onChange={this.handleChangeDistGc}>
                                                <option  value={0}>Select</option>
                                                {
                                                    this.state.distribution_group_list.map((gc) => {
                                                        console.log('distribution_group_list gc', gc)                                                        
                                                        if(this.state.selected_gc === '0' || (gc.distributionItem === 'company' && gc.curgcid === this.state.selected_gc)) {                                                            
                                                            if(this.state.selected_gc === '0') return(<option key={gc.distributionID} value={gc.distributionID}>{gc.distributionName}</option>);
                                                            if(show_all) {
                                                                return(<option key={gc.distributionID} value={gc.distributionID}>{gc.distributionName}</option>);
                                                            } else if(role === 'admin_manager' && assignee_role === 'am') {
                                                                if(this.state.assigned_to !== '' && gc.curgcid in this.state.gc_users[this.state.assigned_to]) {
                                                                    return(<option key={gc.distributionID} value={gc.distributionID}>{gc.distributionName}</option>);
                                                                }
                                                            } else if(role === 'am' && assignee_role === 'manager') {
                                                                if(gc.curgcid in this.state.gc_users[contact_id]) {
                                                                    return(<option key={gc.distributionID} value={gc.distributionID}>{gc.distributionName}</option>);
                                                                }
                                                            } else if(role === 'am' && assignee_role === 'am') {
                                                                if(gc.curgcid in this.state.gc_users[contact_id] && gc.curgcid in this.state.gc_users[this.state.assigned_to]) {
                                                                    return(<option key={gc.distributionID} value={gc.distributionID}>{gc.distributionName}</option>);
                                                                }
                                                            }
                                                        }
                                                    })
                                                }  
                                                {
                                                    (() => {
                                                        if(this.state.distribution_group_list.length === 0){
                                                            if(Object.keys(this.state.gc_users).length !== 0) {
                                                                return(<option disabled>No distribution lists are configured for this group company</option>)
                                                            }else{
                                                                return(<option disabled>No distribution lists are configured for this company</option>)
                                                            }
                                                        }
                                                    })()
                                                }                                              
                                                {
                                                    (() => {
                                                        if(this.state.isSelectedMsg){                                                            
                                                            return(<option disabled>No distribution lists are configured for this company</option>)                                                            
                                                        }
                                                    })()
                                                }                                              
                                            </MRModalSelect>
                                        </div>
                                    )
                                }
                            })()
                        }                        
                        {
                            (() => {
                                if(this.state.show_share_tab){
                                
                                    let TableBottomMsg = `${this.processMyshareGroup().data.length} ${lp['contacts_listed_count_title'] === undefined ? 'Contacts Listed' : lp['contacts_listed_count_title']}`;
                                    
                                    return(<div>
                                        <OverviewInfoHeader>
                                                <div style={{float: "left", color: "#000000", fontWeight: "600"}}>Users</div> 
                                                <div style={{float: "right", color: "#ffffff", fontSize: "17px"}}><SearchInput placeholder="Search..." onChange={this.searchValue} /></div>
                                                <div style={{clear: "both"}}></div>
                                        </OverviewInfoHeader>  
                                        <OverviewInfoBody>
                                            <CSLTable add={false} processData={this.processMyshareGroup} headerText={''} tableRows={10} refreshCallback={this.refreshState} TableBottomMsg={TableBottomMsg}/>
                                        </OverviewInfoBody> 
                                        {/* <MRModalLabelSub style={{fontSize: "13px",textDecoration: "underline",color: "#00B5B2"}}>Save as New Distribution Group</MRModalLabelSub>  */}
                                        <hr style={{marginTop: "20px", color: "#EBEBEB"}}/>      
                                        <MRModalLabel style={{color: "#030303"}}>Acknowledgement Due Date</MRModalLabel>
                                        <CSLDateCover style={{display: "inline-block"}}>
                                            <DatePicker
                                                    onChange={this.onacknowledgementDueDate}
                                                    clearIcon={null}
                                                    calendarIcon={null}
                                                    locale={"en-GB"}
                                                    minDate={new Date()}
                                                    value={this.state.acknowledgementDueDate}
                                            />
                                        </CSLDateCover>
                                    </div>)
                                }
                            })()
                        }                                                  
                    </div>
                </MRModalBody>
                <MRModalFooter>
                    <div style={{position: "relative",padding: "17px"}}>
                    {
                        (() => {
                            if(!this.state.is_submitting) return <MRModalSaveCloseBtn onClick={this.submitandShare}>Submit</MRModalSaveCloseBtn>
                        })()
                    }
                    
                    <MRModalCancelBtn onClick={this.closeView}>Cancel</MRModalCancelBtn>
                    </div>
                </MRModalFooter>
                {
                    (() => {
                        if(this.state.show_progress) {
                            console.log('this.state', this.state)
                            let barwidth = `${parseInt(this.state.share_count * 100 / this.state.distribution_group_user_list.length)}%`

                            return (<ProgressContainer> 
                                    Sharing the attestation with recepients.
                                    <ProgressBarTotal>
                                        <ProgressBar style={{width: barwidth}} />
                                    </ProgressBarTotal>
                                    <span style={{fontSize: 12}}>If you want, you may cancel this dialog and go about your work while we share the attestation with the recipients in the background.</span>
                                </ProgressContainer>)
                        }
                    })()
                }
            </MRModalContainer>  
            <AlertBox alertParam={this.state.alert_param} />
            </div>         
        );
    }
}

export default AMShareAttestationModal;