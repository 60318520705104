import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import Store from '../../Common/Store';
import { FaPencilAlt, FaTimes,FaPlusCircle } from "react-icons/fa";
import DistributionConfigModal from './DistributionConfigModal';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;
const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 18px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const InactiveOverlay = styled.div`
	display: block;
	box-sizing: border-box;
	height: 170%;
	width: 100%;
	background-color: #ffffff;
	position: absolute;
	left: 0%;
	top: 0px;
	z-index: 1000;
	opacity: 0.8;
`;
class DistributionLists extends React.Component
{

	state = {general: null, ditributionLists: [], show_distribution_modal: false, curid: 0,content: null};

	componentDidMount()
	{
		//console.log("ditribution_lists",this.props.ditribution_lists)
		this.setState({general: this.props.general, ditributionLists: this.props.ditribution_lists});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
		    this.setState({general: this.props.general, ditributionLists: this.props.ditribution_lists});
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneral(general);
	}
	
	tableData = () => {
		console.log('ditributionLists', this.state.ditributionLists)
        let ret = {data: [], columns: []};
        let gc_company = Store.getStoreData('gc_company')
        let gc_assoc = {}
        for(let gc of gc_company) {
            if(gc.is_gc)gc_assoc[gc.id] = 1
        }
        ret.columns =[
                        {Header: 'Name of Distribution', accessor: 'distribution_name', minWidth: 180, headerStyle: {textAlign: 'left'}}, 
						
						{Header: 'Contacts', accessor: 'no_of_contacts', minWidth: 50, headerStyle: {textAlign: 'center'}, style: {textAlign: 'center'}},
						{Header: 'Distribution Item', accessor: 'distribution_item', minWidth: 80, headerStyle: {textAlign: 'left'}},
						{'Header' : 'Action', Cell: row => (
							<div>
								<FaPencilAlt  onClick={this.openModal(row.original.id)} style={{cursor: 'pointer',fontSize: '17px',color: '#b4b5b4',display :'inline-block',marginRight: "5px"}} />
								<FaTimes onClick={this.deleteReason(row.original.id)} style={{cursor: 'pointer',fontSize: '18px',color: '#b4b5b4',display :'inline-block'}}/> 
							</div>
						  ), width: 55, headerStyle: {textAlign: 'center'}}, 
						];
		this.state.ditributionLists.forEach((item) => {		
			let distributionItemName = {group: 'Group', team: 'team', role: 'Role',company : 'Company', allcontacts: 'All Contacts'}	
			if(item.distributionItem === 'company' && !(item.curgcid in gc_assoc))item.distributionItem = 'allcontacts'
			let elem = {id: item.distributionID, distribution_name: item.distributionName, no_of_contacts: item.distributionGroupUserID.length, distribution_item: distributionItemName[item.distributionItem]};
			ret.data.push(elem);
		})   
		ret.data.sort(function(a,b) {return (a.distribution_name > b.distribution_name) ? 1 : ((b.distribution_name > a.distribution_name) ? -1 : 0);} );     
        return ret;
	}

	openModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const content = this.state.ditributionLists.find((item) => {
			return item.distributionID === id;
		})
		this.setState({content,curid: id,show_distribution_modal: true});        
	}

	deleteReason = (id) => (event) => {
        if (window.confirm('Are you sure you want to delete this Distribution from the list?')) {
           	console.log("id",id)
           	let {ditributionLists} = this.state;
			let newreason = [];
			for(let i=0;i<this.state.ditributionLists.length;i++)
			{
				if(this.state.ditributionLists[i].distributionID !== id){
					newreason.push(this.state.ditributionLists[i])
				}
			}			 
			console.log("delete===>",newreason);   
			this.props.deleteDistributionValue(newreason);        
		}         		  
    }

	adddistributionList = (event) => {
		event.preventDefault();
		this.setState({show_distribution_modal: true,curid: 0})
	}
	closeModal = () => {		
		this.setState({show_distribution_modal: false})
	}

	saveDistributionDate = (obj) => {
		this.setState({show_distribution_modal: false})
		this.props.saveDistributionValue(obj)
		//console.log("obj", obj)
	}

	modifyDistributionValue = (content) => {
		console.log("content:", content);
		//console.log("this.state",this.state.reason_for_removal)
		let {ditributionLists} = this.state;
		let newdistribution = [];
		ditributionLists.forEach((item) => {
			if (item.distributionID === content.distributionID) {
				item = content;
				console.log("matched", item);
			}
			newdistribution.push(item);
		})
		console.log("resons:", newdistribution);
		this.setState({show_distribution_modal: false})
		this.props.updateDistributionValue(newdistribution);		
	}

	render()
	{
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}
		return (
			<div>
				{
					(() => {
						if (this.state.show_distribution_modal) {
							return (<div style={{position:'initial', left:'2%', top:'50px', width:'100%', height:'500px'}}><InactiveOverlay/><DistributionConfigModal content={JSON.parse(JSON.stringify(this.state.content))} permissions={this.props.permissions} closeModal={this.closeModal} curid={this.state.curid}  updateDistributionValue={this.modifyDistributionValue} saveDistributionValue={this.saveDistributionDate}/></div>);
						}
					})()
				}				
				<GeneralContainer>
					<GeneralInnerContainer>
						<GeneralLabel>Enable Distribution Lists</GeneralLabel>
						{
							(() => {
								if (this.state.general.enable_distribution_list) {
									return (
										<RadioBlock>
											<SelectedRadio>Yes</SelectedRadio>
											<UnselectedRadio onClick={this.switchRadio('enable_distribution_list')}>No</UnselectedRadio>
										</RadioBlock>
									);
								} else {
									return (
										<RadioBlock>
											<UnselectedRadio onClick={this.switchRadio('enable_distribution_list')}>Yes</UnselectedRadio>
											<SelectedRadio>No</SelectedRadio>
										</RadioBlock>
									);
								}
							})()
						}
						</GeneralInnerContainer>
						{
							(() => {
								if (this.state.general.enable_distribution_list) {
									return (<div>
										<EmailContainer>
										<EmailInnerContainer>
											<div style={{display: "flow-root"}}>
												<div style={{float: "left"}}>
													<EmailBoldLabel style={{display: "inline-block"}}>Configure Options</EmailBoldLabel>
												</div>
												<div onClick={this.adddistributionList} style={{float: "right", cursor: "pointer",fontSize: "17px",color: "#04ADA8"}}>
													<FaPlusCircle />
												</div>	
											</div>									
											<CSLTable add={false} processData={this.tableData} headerText={''} tableRows={8} refreshCallback={this.refreshState} />
										</EmailInnerContainer>
									</EmailContainer>
									</div>)
								}
							})()
						}					
					
				</GeneralContainer>
			</div>
		);
	}
}

export default DistributionLists;
