import React from "react";
import styled from "styled-components";
import Store from "../../Common/Store";
import CSLTable from "../Common/CSLTable";
import APICall from "../../Common/APICall";
import AMAskAttestationModal from "./AMAskAttestationModal";
import AMCompleteAttestationModal from "./AMCompleteAttestationModal";
import AMPendingTaskTable from "./AMPendingTaskTable";
import AMCompleteTaskTable from "./AMCompleteTaskTable";
import FollowupTaskTable from "./FollowupTaskTable";
import CSLLoader from "../Common/CSLLoader";
import AMFollowupListsModal from "./AMFollowupListsModal";
import IndexLoader from "./../../Common/IndexLoader";

// @TODO find out where RDR/ReportDataNew.js has gone
import ReportDataNew from "../RDR/ReportDataNew.js";

import CSLRegisterTable from "../RDR/CSLRegisterTable";
import AlertBox from '../Common/AlertBox';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import moment from "moment";
import XcelLoader from "./../Common/XcelLoader";
import { data } from "jquery";

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 84px;
  font-size: 13px;
  height: 25px;
  border-radius: 4px;
`;
const OverviewInfoHeader = styled.div`
  padding: 12px 15px;
  background-color: #ffffff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  width: 96%;
  margin: 0 auto;
  margin-top: 30px;
  border: 1px solid #d7dddf;
`;
const OverviewInfoBody = styled.div`
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #d7dddf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 96%;
  margin: 0 auto;
  margin-bottom: 30px;
`;
const BoxStyle = styled.div`
  float: left;
  width: 15px;
  height: 15px;
  margin: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-right: 10px;
`;
const ConflictInput = styled.input`
  height: 30px;
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: black;
`;
const ConflictSelect = styled.select`
  display: block;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  font-family: Montserrat, sans-serif;
  color: #414141;
`;
const CSLDateCover = styled.div`
  height: 20px;
  width: 20%;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: #979797;
`;
const RegModalIssueDateLabel = styled.label`
  font-weight: 600;
  color: #282828;
  margin-bottom: 10px;
`;
const RegCancelButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #dadada;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  font-size: 11px;
  cursor: pointer;
`;
const RegSubmitButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #37ada7;
  margin-left: 5px;
  font-size: 11px;
  cursor: pointer;
`;
const ExportButton = styled.button`
  box-sizing: border-box;
  background-color: #213e61;
  color: #f2f2f2;
  border: 1px solid #213e61;
  width: 125px;
  font-size: 14px;
  height: 32px;
  border-radius: 5px;
  margin-right: 3px;
  margin-top: 19px;
  cursor: pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;
const ExportButtonDisable = styled.button`
  box-sizing: border-box;
  background-color: #213e61a1;
  color: #f2f2f2;
  border: 1px solid #213e61a1;
  width: 125px;
  font-size: 14px;
  height: 32px;
  border-radius: 5px;
  margin-right: 3px;
  margin-top: 19px;
`;
class AMAttestationList extends React.Component {
  state = {
    ready: false,
    attestationTaskList: [],
    linkNumberName: "",
    isloadericon: false,
    isClickLink: false,
    attestStatus: "",
    followuptasks: [],
    cur_followuptasks: [],
    alert_param: null,
    at_type: "Document"
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let myattestationTask = this.props.attestationTaskList;
    //console.log("task id", myattestationTask)
    let task_id = this.props.taskId;
    let userrole = Store.getStoreData("role");
    let followuptasks = Store.getStoreData("followuptask");
    this.setState({
      attestationTaskList: myattestationTask,
      userRole: userrole,
      followuptasks,
    });
    if (task_id > 0) {
      console.log("task id", task_id);
      this.showAttestationModal(task_id);
    } else {
      this.setState({ ready: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let myattestationTask = this.props.attestationTaskList;
      let followuptasks = Store.getStoreData("followuptask");
      this.setState({
        attestationTaskList: myattestationTask,
        ready: true,
        followuptasks,
      });
    }
  }

  processMyattestationValue = () => {
    var mytaskdata = this.state.attestationTaskList;
    //console.log("mytaskdata",mytaskdata)
    let lp = Store.getStoreData('language_pack')
    let ret = { data: [], columns: [] };
    let i = 0;
    ret.columns = [
      {
        Header: lp['managers_attestations_table_status'] === undefined ? 'Status' : lp['managers_attestations_table_status'],
        accessor: "status_text",
        Cell: (row) => (
          <div>
            {(() => {
              if (row.original.status === "Complete") {
                return (
                  <div style={{ borderLeft: "9px solid #04ADA8", height: "24px" }}>
                    <div style={{ padding: "4px", marginLeft: "10px" }}>{row.original.status_text}</div>
                  </div>
                );
              } else if (row.original.status === "Saved") {
                return (
                  <div style={{ borderLeft: "9px solid #143151", height: "24px" }}>
                    <div style={{ padding: "4px", marginLeft: "10px" }}>{row.original.status_text}</div>
                  </div>
                );
              } else if (row.original.status === "Overdue") {
                return (
                  <div style={{ borderLeft: "9px solid #DD4054", height: "24px" }}>
                    <div style={{ padding: "4px", marginLeft: "10px" }}>{row.original.status_text}</div>
                  </div>
                );
              } else {
                return (
                  <div style={{ borderLeft: "9px solid #143151", height: "24px" }}>
                    <div style={{ padding: "4px", marginLeft: "10px" }}>{row.original.status_text}</div>
                  </div>
                );
              }
            })()}
          </div>
        ),
        width: 120,
        headerStyle: { textAlign: "left", fontWeight: "600" },
      },
      {
        Header: lp['managers_attestations_table_attestation_name'] === undefined ? 'Attestation Name' : lp['managers_attestations_table_attestation_name'],
        accessor: "document_name",
        minWidth: 280,
        headerStyle: { textAlign: "left", fontWeight: "600" },
      },
      {
        Header: lp['upload_date'] === undefined ? 'Upload Date' : lp['upload_date'],
        accessor: "upload_date",
        minWidth: 120,
        headerStyle: { textAlign: "left", fontWeight: "600" },
        style: { textAlign: "left" },
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          let adue = a.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          let bbdue = b.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse > bReverse ? 1 : -1;
        },
      },
      {
        Header: lp['tasks'] === undefined ? 'Tasks' : lp['tasks'],
        accessor: "followuptask",
        Cell: (row) =>
          (() => {
            if (row.original.flagged === "00") {
              return <div style={{ textDecoration: "underline", color: "#00B5B2" }}>{row.original.followuptask}</div>;
            } else {
              return (
                <div style={{ textDecoration: "underline", cursor: "pointer", color: "#00B5B2" }} onClick={this.goToLinkNumberFollowupUrl(row.original.id)}>
                  {row.original.followuptask}
                </div>
              );
            }
          })(),
        minWidth: 80,
        headerStyle: { textAlign: "center", fontWeight: "600" },
        style: { textAlign: "center" },
      },
      {
        Header: lp['flagged'] === undefined ? 'Flagged' : lp['flagged'],
        accessor: "flagged",
        Cell: (row) =>
          (() => {
            if (row.original.flagged === "00") {
              return <div style={{ textDecoration: "underline", color: "#00B5B2" }}>{row.original.flagged}</div>;
            } else {
              return (
                <div style={{ textDecoration: "underline", cursor: "pointer", color: "#00B5B2" }} onClick={this.goToLinkNumberUrl(row, "flagged")}>
                  {row.original.flagged}
                </div>
              );
            }
          })(),
        minWidth: 80,
        headerStyle: { textAlign: "center", fontWeight: "600" },
        style: { textAlign: "center" },
      },
      {
        Header: lp['managers_attestations_table_information'] === undefined ? 'Info' : lp['managers_attestations_table_information'],
        accessor: "info",
        Cell: (row) =>
          (() => {
            if (row.original.info === "00") {
              return <div style={{ textDecoration: "underline", color: "#00B5B2" }}>{row.original.info}</div>;
            } else {
              return (
                <div style={{ textDecoration: "underline", cursor: "pointer", color: "#00B5B2" }} onClick={this.goToLinkNumberUrl(row, "info")}>
                  {row.original.info}
                </div>
              );
            }
          })(),
        minWidth: 80,
        headerStyle: { textAlign: "center", fontWeight: "600" },
        style: { textAlign: "center" },
      },
      {
        Header: lp['assigned'] === undefined ? 'Assigned' : lp['assigned'],
        accessor: "acknowledge",
        Cell: (row) =>
          (() => {
            if (row.original.acknowledge === "00") {
              return <div style={{ textDecoration: "underline", color: "#00B5B2" }}>{row.original.acknowledge}</div>;
            } else {
              return (
                <div style={{ textDecoration: "underline", cursor: "pointer", color: "#00B5B2" }} onClick={this.goToLinkNumberUrl(row, "acknowledge")}>
                  {row.original.acknowledge}
                </div>
              );
            }
          })(),
        minWidth: 80,
        headerStyle: { textAlign: "center", fontWeight: "600" },
        style: { textAlign: "center" },
      },
      {
        Header: lp['managers_attestations_table_overdue'] === undefined ? 'Overdue' : lp['managers_attestations_table_overdue'],
        accessor: "outstanding_acknowledge",
        Cell: (row) =>
          (() => {
            if (row.original.outstanding_acknowledge === "00") {
              return <div style={{ textDecoration: "underline", color: "#00B5B2" }}>{row.original.outstanding_acknowledge}</div>;
            } else {
              return (
                <div style={{ textDecoration: "underline", cursor: "pointer", color: "#00B5B2" }} onClick={this.goToLinkNumberUrl(row, "outstanding")}>
                  {row.original.outstanding_acknowledge}
                </div>
              );
            }
          })(),
        minWidth: 80,
        headerStyle: { textAlign: "center", fontWeight: "600" },
        style: { textAlign: "center" },
      },
      {
        Header: lp['action'] === undefined ? 'Action' : lp['action'],
        Cell: (row) => (
          <div style={{ display: "inline-flex" }}>
            <MRButton style={{ cursor: "pointer" }} onClick={this.goToTaskUrl(row)}>
            {lp['view'] === undefined ? 'View' : lp['view']}
            </MRButton>
            {(() => {
              if (this.state.userRole === "manager" || this.state.userRole === "admin_manager") {
                return (
                  <div style={{ fontSize: "20px", marginLeft: "15px", cursor: "pointer", color: "#7d7d7d", fontWeight: "bold" }} onClick={this.customdeleteAttestTaskAlert(row.original.id)}>
                    x
                  </div>
                );
              } else if (this.state.userRole === "am" && row.original.status === "Saved") {
                return (
                  <div style={{ fontSize: "20px", marginLeft: "15px", cursor: "pointer", color: "#7d7d7d", fontWeight: "bold" }} onClick={this.customdeleteAttestTaskAlert(row.original.id)}>
                    x
                  </div>
                );
              }
            })()}
          </div>
        ),
        width: 140,
        headerStyle: { textAlign: "center", fontWeight: "600" },
      },
    ];
    for (let d of mytaskdata) {
      i++;
      let status = "";
      let status_text = "";
      if (d[2] === "COMP_ATTESTATION_USERSAVE") {
        status = "Saved";
        status_text = lp['saved'] === undefined ? 'Saved' : lp['saved']
      } else if (d[2] === "COMP_ATTESTATION_ASSIGNMENT") {
        status = "Pending";
        status_text = lp['pending'] === undefined ? 'Pending' : lp['pending']
      }

      let documentName = d[1];

      let infoCount = 0;
      let acknowledgeCount = 0;
      let flaggedCount = 0;
      let followupCount = 0;
      let totaOutstanding = 0;
      let totalPending = 0;
      let completeCount = 0;
      let totalShareTask = 0;

      if (d[5] !== null) {
        let flaggedvalue = Store.getStoreData("flagged_count_object");
        let followupvalue = Store.getStoreData("followupcount");
        let shareTaskvalue = Store.getStoreData("child_tasks_all");
        let child_tasks = JSON.parse(d[5]);
        for (var k = 0; k < child_tasks.length; k++) {
          if (child_tasks[k] in flaggedvalue) {
            if (flaggedvalue[child_tasks[k]] === true) {
              flaggedCount = flaggedCount + 1;
            }
            followupCount += followupvalue[child_tasks[k]];
          }
          if (child_tasks[k] in shareTaskvalue) {
            if (shareTaskvalue[child_tasks[k]].Type === "information") {
              infoCount = infoCount + 1;
            }
            if (shareTaskvalue[child_tasks[k]].Type === "acknowledge") {
              acknowledgeCount = acknowledgeCount + 1;
            }
            if (shareTaskvalue[child_tasks[k]].cur_lane === "COMP_ATTESTATION_ASSIGNMENT_SHARE") {
              let due_date_str = shareTaskvalue[child_tasks[k]].dueDate;
              let today = moment().unix();
              let checkOverdueStatus = due_date_str + 86400 > today ? "Pending" : "Overdue";
              if (checkOverdueStatus === "Overdue") {
                totaOutstanding = totaOutstanding + 1;
              } else {
                totalPending = totalPending + 1;
              }
            }
            if (shareTaskvalue[child_tasks[k]].cur_lane === "COMP_ATTESTATION_SHARE_COMPLETED") {
              completeCount = completeCount + 1;
            }
          }
        }
      }


      if (totaOutstanding !== 0) {
        status = "Overdue";
        status_text = lp['overdue'] === undefined ? 'Overdue' : lp['overdue']
      } else if (totalPending !== 0) {
        status = "Pending";
        status_text = lp['pending'] === undefined ? 'Pending' : lp['pending']
      } else if (completeCount !== 0) {
        status = "Complete";
        status_text = lp['complete'] === undefined ? 'Complete' : lp['complete']
      }

      let totalInfoCount = infoCount.toString().length < 2 ? "0" + infoCount : infoCount;
      let totalAcknowledgeCount = acknowledgeCount.toString().length < 2 ? "0" + acknowledgeCount : acknowledgeCount;
      let totalFollowupCount = followupCount.toString().length < 2 ? "0" + followupCount : followupCount;
      let totalFlaggedCount = flaggedCount.toString().length < 2 ? "0" + flaggedCount : flaggedCount;
      let totalOutstandingCount = totaOutstanding.toString().length < 2 ? "0" + totaOutstanding : totaOutstanding;
      let elem = {
        id: d[0],
        status: status,
        status_text: status_text,
        document_name: documentName,
        followuptask: totalFollowupCount,
        flagged: totalFlaggedCount,
        info: totalInfoCount,
        acknowledge: totalAcknowledgeCount,
        outstanding_acknowledge: totalOutstandingCount,
        at_type: d[6] !== "" ? d[6] : "Document",
        upload_date: moment.unix(d[7]).format("DD/MM/YYYY"),
      };
      ret.data.push(elem);
      let sarr = [];
      ret.data.forEach((item) => {
        if (item.status === "Saved") {
          sarr.push(item);
        }
      });
      ret.data.forEach((item) => {
        if (item.status === "Pending") {
          sarr.push(item);
        }
      });
      ret.data.forEach((item) => {
        if (item.status === "Overdue") {
          sarr.push(item);
        }
      });
      ret.data.forEach((item) => {
        if (item.status === "Complete") {
          sarr.push(item);
        }
      });
      ret.data = sarr;
    }
    Store.updateStore("table_cur_data", ret.data);
    return ret;
  };

  customdeleteAttestTaskAlert = (taskid) => (event) => {
    event.preventDefault();
    let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this record?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                       alertHandler: this.deleteAttestationTask, stack: {taskid: taskid}}
    this.setState({alert_param: alert_param})
  }

  deleteAttestationTask = (result, stack) => {
    if(!result) {
      this.setState({alert_param: null})
      return
    }
    this.setState({ alert_param: null, isloadericon: true });
    console.log("ROWS", stack.taskid);
    let attestationTaskListAll = this.state.attestationTaskList;
    console.log("attestationTaskListAll===>", attestationTaskListAll);
    let updateAttestationTaskList = [];
    let childTasks = null;
    for (let d of attestationTaskListAll) {
      if (parseInt(stack.taskid) !== parseInt(d[0])) {
        updateAttestationTaskList.push(d);
      }
      if (parseInt(stack.taskid) === parseInt(d[0])) {
        childTasks = d[5];
      }
    }
    let childObject = {};
    if (childTasks !== null) {
      let child_tasks = JSON.parse(childTasks);
      for (let k of child_tasks) {
        childObject[k.toString()] = 1;
      }
    }
    let pending_tasks = Store.getStoreData("mypendingtask");
    let completed_tasks = Store.getStoreData("mycompletetask");
    let update_pending_tasks = [];
    let update_completed_tasks = [];
    if (Object.keys(childObject).length !== 0) {
      for (let k of pending_tasks) {
        if (!(k[0] in childObject)) {
          update_pending_tasks.push(k);
        }
      }
      for (let d of completed_tasks) {
        if (!(d[0] in childObject)) {
          update_completed_tasks.push(d);
        }
      }
    }
    let attest_index = Store.getStoreData("attest-index");
    if (attest_index !== null && "result" in attest_index && "my_tasks" in attest_index.result) {
      attest_index.result.my_tasks = updateAttestationTaskList;
    }
    if (attest_index !== null && "result" in attest_index && "pending_task" in attest_index.result) {
      attest_index.result.pending_task = update_pending_tasks;
    }
    if (attest_index !== null && "result" in attest_index && "completed_task" in attest_index.result) {
      attest_index.result.completed_task = update_completed_tasks;
    }
    Store.updateStore("attest-index", attest_index);
    Store.updateStore("mytasks", updateAttestationTaskList);
    Store.updateStore("mypendingtask", update_pending_tasks);
    Store.updateStore("mycompletetask", update_completed_tasks);

    this.setState({ attestationTaskList: updateAttestationTaskList});
    //alert("Policy Attestation deleted successfully");

    let postData = { command: "remove_task", task_id: stack.taskid };
    console.log("postData", postData);
    let api = new APICall();
    api.command(postData, this.processRemoveAttestationData);
    //}
  }

  processRemoveAttestationData = (result) => {
    console.log("result", result);
    // alert('Policy Attestation deleted successfully');
    // window.location.reload();
    let lp = Store.getStoreData('language_pack')
    let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'Policy Attestation deleted successfully. ', ok_text: 'Ok', confirm: false,
                      alertHandler: this.customupdateprocessRemoveAttestData, stack: {}}
    this.setState({alert_param: alert_param, isloadericon: false})
  };

  customupdateprocessRemoveAttestData = (result, stack) => {
    console.log(result,stack)
    this.setState({alert_param: null})
  }

  // goToTaskUrl = (row) => (event) => {
  //   event.preventDefault();
  //   this.goToMainTaskUrl(row);
  // };
  goToTaskUrl = (row) => (event) => {
    event.preventDefault();
    this.showAttestationModal(row);
  };

  goToMainTaskUrl = (row) => {
    let id = row.original.id;
    console.log("CLICKED TASK ID +++++++ ", id);

    let postData = { command: "get_task", task_id: id };
    let api = new APICall();
    api.command(postData, this.openCompleteMainTaskModal(row));

    this.setState({ isloadericon: true });
  };

  openCompleteMainTaskModal = (row) => (result) => {
    console.log("TASKS JSON RESULT ++++++++ ", result);

    const taskJsonList = result.result.childtask.result.map((data) => ({ ...JSON.parse(data.task_json), id: data.id }));

    console.log("TASKS JSON LIST ++++++++ ", taskJsonList);
    Store.updateStore("cur_q_modal_export", taskJsonList);

    this.showAttestationModal(row);
  };

  goToLinkNumberFollowupUrl = (taskid) => (event) => {
    event.preventDefault();
    Store.updateStore("masterFollowupID", taskid);
    let postData = { command: "get_all_followuptask", task_id: taskid };
    let api = new APICall();
    this.setState({ isloadericon: true });
    api.command(postData, this.processLinkNumberFollowupUrl);
  };

  processLinkNumberFollowupUrl = (result) => {
    console.log("task result", result);
    Store.updateStore("listFollowupTask", result.followupTasks);
    this.setState({
      isloadericon: false,
      showModal: "all_followup_list",
      ready: true,
    });
  };

  goToLinkNumberUrl = (row, linkNumberName) => (event) => {
    event.preventDefault();
    let postData = { command: "get_task_attest_table", task_id: row.original.id };
    let api = new APICall();
    this.setState({ isloadericon: true });
    api.command(postData, this.processAttestationTaskDataPending(row.original.status, row.original.id, row.original.at_type, linkNumberName));
  };
  processLinkNumberUrl = (linkNumberName, at_type) => (result) => {
    console.log("task result", result);
    console.log("linkNumberName", linkNumberName);
    Store.updateStore("cur_q_modal_data", result.result.parenttask);
    Store.updateStore("updated_shareTask", result.result.childtask.result);
    this.setState({showModal: "pencomAttestationModal", linkNumberName: linkNumberName, attestStatus: "", isClickLink: true, at_type: at_type, ready: true,isloadericon: false});
  };

  showAttestationModal = (row) => {
    //console.log('Rows', row);
    if (this.props.taskId > 0) {
      Store.updateStore("q_index", 1);
      let postData = { command: "getTaskCallbyTaskID", task_id: row };
      postData["gcs"] = Store.getStoreData("gc_filters");
      let api = new APICall();
      api.command(postData, this.processOtherAttestationTaskData);
    } else {
      Store.updateStore("q_index", row.index);
      let postData = { command: "get_task", task_id: row.original.id };
      let api = new APICall();
      if (row.original.status === "Saved") {
        api.command(postData, this.processAttestationTaskData);
      } else {
        postData = { command: "get_task_attest_table", task_id: row.original.id };
        api.command(postData, this.processAttestationTaskDataPending(row.original.status, row.original.id, row.original.at_type, ''));
      }
      this.setState({ isloadericon: true });
    }
  };

  processAttestationTaskDataPending = (status, parentTaskID, at_type, linkNumberName) => (result) => {
    console.log("task result", result, "status", status, "at_type==>", at_type);
    Store.updateStore("cur_q_modal_data", result.result.parenttask);
    IndexLoader.divideAllShareTasks(parentTaskID);
    //Store.updateStore('updated_shareTask', result.result.childtask.result);
    if (at_type === "QuestionSet") {
      let shareUserQuesAnsw = result.result.shareUserQuesAnsw;
      let shareUserQuesAnswObject = {};
      for (let d of shareUserQuesAnsw) {
        shareUserQuesAnswObject[d.task_id.toString()] = { sections: d.task_json.object_data.qset_answer.sections, overall_comment: d.task_json.object_data.qset_answer.overall_comment, flagged: d.task_json.object_data.qset_answer.flagged };
      }
      console.log("shareUserQuesAnswObject===>", shareUserQuesAnswObject);
      Store.updateStore("shareUserQuesAnsw", shareUserQuesAnswObject);
    }
    this.setState({showModal: "pencomAttestationModal", attestStatus: status, linkNumberName: linkNumberName, isClickLink: false, at_type: at_type,ready: true});
  };

  processOtherAttestationTaskData = (result) => {
    let lp = Store.getStoreData('language_pack')
    if (result.error_code > 0) {
      let msg = result.error_msg;
      let alert_param = {title: lp['alert_popup_title'] === undefined ? 'Alert' : lp['alert_popup_title'], message: msg, ok_text: 'Ok', confirm: false, alertHandler: this.checkalertparam, stack: {id: 0}}
            this.setState({alert_param: alert_param, ready: true})
      //alert(msg);
      return false;
    }
    if (result.error_code === 0) {
      console.log("task result", result);
      let curlane = result.result.parenttask.cur_lane;
      if (curlane === "COMP_ATTESTATION_USERSAVE") {
        Store.updateStore("cur_q_modal_data", result.result.parenttask);
        this.setState({ showModal: "saveAttestationModal", ready: true });
      } else if (curlane === "COMP_ATTESTATION_ASSIGNMENT") {
        Store.updateStore("cur_q_modal_data", result.result.parenttask);
        Store.updateStore("updated_shareTask", result.result.childtask.result);
        this.setState({
          showModal: "pencomAttestationModal",
          linkNumberName: "",
          ready: true,
        });
      } else if (curlane === "COMP_ATTESTATION_ASSIGNMENT_SHARE") {
        //check associated company
        let gc_users = Store.getStoreData("gc_users");
        let has_gc = Store.getStoreData("has_gc");
        let role = Store.getStoreData("role");
        let isIN = false;
        console.log("role==>", role, "has_gc==>", has_gc);
        if (role === "am" && has_gc === true) {
          let cur_assigID = result.result.parenttask.cur_assigned_to;
          if (cur_assigID in gc_users) {
            isIN = true;
          }
        }
        if (role === "admin_manager" || role === "manager") {
          isIN = true;
        }
        if (!isIN) {
          //alert("ERROR: Sorry. You do not have permissions to access this task. Please contact your manager if you think this error is being shown in error.");
          let alert_param = {title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'], message: 'Sorry. You do not have permissions to access this task. Please contact your manager if you think this error is being shown in error. ', ok_text: 'Ok', confirm: false,
                            alertHandler: this.checkalertparam, stack: {}}
          this.setState({alert_param: alert_param})
          return false;
        }
        //check associated company
        this.setState({ showModal: "comattestationOpenModal", ready: true });
      } else if (curlane === "COMP_ATTESTATION_SHARE_COMPLETED") {
        //check associated company
        let gc_users = Store.getStoreData("gc_users");
        let has_gc = Store.getStoreData("has_gc");
        let role = Store.getStoreData("role");
        let isIN = false;
        if (role === "am" && has_gc === true) {
          let cur_assigID = result.result.parenttask.cur_assigned_to;
          if (cur_assigID in gc_users) {
            isIN = true;
          }
        }
        if (role === "admin_manager" || role === "manager") {
          isIN = true;
        }
        if (!isIN) {
          //alert("ERROR: Sorry. You do not have permissions to access this task. Please contact your manager if you think this error is being shown in error.");
          let alert_param = {title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'], message: 'Sorry. You do not have permissions to access this task. Please contact your manager if you think this error is being shown in error. ', ok_text: 'Ok', confirm: false,
                            alertHandler: this.checkalertparam, stack: {}}
          this.setState({alert_param: alert_param})
          return false;
        }
        //check associated company
        this.setState({
          showModal: "comattestationCompleteModal",
          ready: true,
        });
      } else if (curlane === "COMP_ATTESTATION_FOLLOWUP_ASSIGNMENT" || curlane === "COMP_ATTESTATION_FOLLOWUP_COMPLETE") {
        let postData = {
          command: "GetSubTaskCallbyTaskID",
          task_id: this.props.taskId,
        };
        postData["gcs"] = Store.getStoreData("gc_filters");
        let api = new APICall();
        api.command(postData, this.openTaskModal);
      }
    }
  };
  checkalertparam = (result, stack) => {
    console.log(result,stack)
    this.setState({alert_param: null})
  }

  openTaskModal = (result) => {
    if (result.error_code > 0) {
      let msg = result.error_msg;
      //alert(msg);
      let lp = Store.getStoreData('language_pack')
      let alert_param = {title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'], message: msg, ok_text: 'Ok', confirm: false,
                            alertHandler: this.checkalertparam, stack: {}}
          this.setState({alert_param: alert_param})
      return false;
    }
    Store.updateStore("followup_task", result.result.task);
    this.setState({ showModal: "view_followup_modal", ready: true });
  };

  processAttestationTaskData = (result) => {
    //console.log('task result', result);
    Store.updateStore("cur_q_modal_data", result.result.parenttask);
    this.setState({ showModal: "saveAttestationModal", ready: true });
  };

  closeModal = (event) => {
    //event.preventDefault();
    this.setState({
      showModal: null,
    });
  };

  closeModalAndRefresh = (event) => {
    //event.preventDefault();
    let postData = { command: "refreshTasknew" };
    let api = new APICall();
    api.command(postData, this.processTaskrefresh);
  };

  processTaskrefresh = (result) => {
    console.log("result==>", result);
    let contacts_all = Store.getStoreData('contacts');
    let ChildTasksObject = {};
    // let childTasksValue = result.result["child_share_task"];
    // for (let j = 0; j < childTasksValue.length; j++) {
    //   let task_jsonnew = JSON.parse(childTasksValue[j].task_json);
    //   let data = {
    //     contact_id: childTasksValue[j].contact_id,
    //     cur_lane: childTasksValue[j].cur_lane,
    //     acknowledgementDueDate: task_jsonnew.object_data.headerinfo.acknowledgementDueDate,
    //     dueDate: childTasksValue[j].due_date,
    //     Type: task_jsonnew.object_data.headerinfo.Type,
    //     parent_task_id: childTasksValue[j].parent_task_id,
    //   };
    //   ChildTasksObject[childTasksValue[j].id] = data;
    // }
    let childTasksValue = result.result["child_share_task"];
    Store.updateStore("child_share_task_all", childTasksValue);
    for (let d of childTasksValue) {
      if(d[9] in contacts_all){
        let data = {
          contact_id: d[2],
          cur_lane: d[3],
          acknowledgementDueDate: d[4],
          dueDate: d[5],
          Type: d[6],
          parent_task_id: d[7],
        };
        ChildTasksObject[d[0]] = data;
      }
    }

    let flagedObject = {};
    let flagedValue = result.result["flaged_task"];
    for (let d of flagedValue) {
      if (d[1] === true) {
        flagedObject[d[0]] = true;
        if (d[2] === true) {
          flagedObject[d[0]] = false;
        }
      }
    }
    console.log("flagedObject==>", flagedObject);
    Store.updateStore("flagged_count_object", flagedObject);
    Store.updateStore("child_tasks_all", ChildTasksObject);
    this.setState({showModal: null});
  };

  closeAttestationModal = (event) => {
    this.setState({
      showModal: null,
    });
  };

  exportToExcel = () => {
    let wb = { SheetNames: [], Sheets: {} };
    let attestations_tasks = Store.getStoreData("table_cur_data") === null ? [] : Store.getStoreData("table_cur_data");
    wb.SheetNames.push("Attestations");
    wb.Sheets["Attestations"] = XLSX.utils.json_to_sheet(XcelLoader.attestation_export_tasks(attestations_tasks));
    const format = "DD-MM-YYYY HH:mm:ss";
    let tm = moment().format(format);
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Attestations" + "-" + tm + fileExtension);
  };

  closeTaskModal = (event) => {
    this.props.closeUserDialog();
  };

  closeModalUpdate = (event) => {
    this.setState({
      showModal: null,
    });
    this.props.closeModalUpdate();
  };

  render() {
    let lp = Store.getStoreData('language_pack')
    let task_id = this.props.taskId;
    if (!this.state.ready) {
      return <div>Loading...</div>;
    }
    if (task_id > 0) {
      return (
        <div>
          {(() => {
            if (this.state.showModal !== null) {
              switch (this.state.showModal) {
                case "saveAttestationModal":
                  return (
                    <div style={{ position: "absolute", left: "2%", top: "50px", width: "100%", height: "600px" }}>
                      <InactiveOverlay />
                      <AMAskAttestationModal closeModal={this.closeAttestationModal} closeTaskModal={this.closeTaskModal} />
                    </div>
                  );
                  break;
                case "pencomAttestationModal":
                  return (
                    <div
                      style={{
                        position: "absolute",
                        left: "2%",
                        top: "50px",
                        width: "100%",
                        height: "600px",
                      }}
                    >
                      <InactiveOverlay />
                      <AMCompleteAttestationModal linkNumberName={this.state.linkNumberName} closeModal={this.closeModal} closeTaskModal={this.closeTaskModal} />
                    </div>
                  );
                  break;
                case "comattestationOpenModal":
                  return <AMPendingTaskTable taskId={task_id} closeTaskModal={this.closeTaskModal} />;
                  break;
                case "comattestationCompleteModal":
                  return <AMCompleteTaskTable taskId={task_id} closeTaskModal={this.closeTaskModal} />;
                  break;
                case "view_followup_modal":
                  return <FollowupTaskTable taskId={task_id} closeTaskModal={this.closeTaskModal} />;
                  break;
              }
            }
          })()}
        </div>
      );
    }
    return (
      <div>
        {
            (()=> {
                if(this.state.isloadericon){
                    return <div><CSLLoader style={{position: 'absolute'}}/></div>
                }
            })()
        }
        <OverviewInfoHeader>
          <div style={{ float: "left", color: "#323232", fontWeight: "600", fontSize: "16px" }}>{lp['attestations'] === undefined ? 'Attestations' : lp['attestations']}</div>
          <div style={{ clear: "both" }}></div>
        </OverviewInfoHeader>
        <OverviewInfoBody>
          {/*<CSLTable
                        add={false}
                        processData={this.processMyattestationValue}
                        headerText={''}
                        tableRows={10}
                        refreshCallback={this.refreshCallback}
                    />*/}
          <CSLRegisterTable
            data={ReportDataNew.reportData()}
            processData={this.processMyattestationValue}
            export={true}
            exportxls={this.exportToExcel}
            task_length={this.state.attestationTaskList.length}
            reportButton={true}
            tableRows={10}
            // exportToCSV={this.exportToExcel}
          />

          {/*<div style={{float: "left"}}>
                        {
                            (() => {
                                if(this.state.attestationTaskList.length !== 0){
                                    return(<ExportButton onClick={() => this.exportToExcel()}>Export</ExportButton>)
                                }else{
                                    return(<ExportButtonDisable>Export</ExportButtonDisable>)
                                }
                            })()
                        }

                    </div>*/}
          <div style={{ clear: "both" }}></div>
        </OverviewInfoBody>

        <AMPendingTaskTable taskId={task_id} />
        <AMCompleteTaskTable taskId={task_id} />

        <FollowupTaskTable />
        {(() => {
          if (this.state.showModal !== null) {
            switch (this.state.showModal) {
              case "saveAttestationModal":
                return (
                  <div style={{ position: "absolute", left: "2%", top: "50px", width: "100%", height: "600px" }}>
                    <InactiveOverlay style={{ height: "400%" }} />
                    <AMAskAttestationModal closeModal={this.closeAttestationModal} closeModalUpdate={this.closeModalUpdate}/>
                  </div>
                );
                break;
              case "pencomAttestationModal":
                return (
                  <div style={{ position: "absolute", left: "2%", top: "50px", width: "100%", height: "600px" }}>
                    <InactiveOverlay style={{ height: "400%" }} />
                    <AMCompleteAttestationModal linkNumberName={this.state.linkNumberName} attestStatus={this.state.attestStatus} closeModal={this.closeModal} closeModalAndRefresh={this.closeModalAndRefresh} isClickLink={this.state.isClickLink} at_type_val={this.state.at_type} />
                  </div>
                );
                break;
              case "all_followup_list":
                return (
                  <div style={{ position: "absolute", left: "2%", top: "50px", width: "100%", height: "600px" }}>
                    <InactiveOverlay style={{ height: "300%" }} />
                    <AMFollowupListsModal closeModal={this.closeModal} />
                  </div>
                );
                break;
            }
          }
        })()
        }
        <AlertBox alertParam = {this.state.alert_param}/>
      </div>
    );
  }
}
export default AMAttestationList;
