import React from 'react';
import Store from '../../Common/Store'
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import {IoIosArrowForward, IoIosArrowDown, IoIosArrowUp} from 'react-icons/io';
import General from './General';
import QuestionSet from './QuestionSet';
import QuestionSet_update from './QuestionSet_update';
import DistributionLists from './DistributionLists';
import APICall from '../../Common/APICall.js';
import Email from './Email';
import LPemail from './LP/LPemail';
import Permissions from './Permissions';
import Reminders from './Reminders';
import AlertBox from '../Common/AlertBox';
import CSLLoader from './../Common/CSLLoader';
import Reload from '../../Common/Reload.js';

const ReviewBodyContainer = styled.div`
    padding-top: 25px;
    width: 100%;
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 20px;
    width: 20%;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding-left: 20px;
    width: 80%;
    box-sizing: border-box;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const ActiveQSetTab = styled.div`
    background-color: #04ADA8;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const QSetTab = styled.div`
    background-color: #FFFFFF;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const SpacerTab = styled.div`
    background-color: #FFFFFF;
    min-height: 10px;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 5px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 90%;
`;
const CancelBtn = styled.button`
    padding: 10px 20px;
    background-color: #ffffff;
    border: 1px solid #37ADA7;
    border-radius: 2px;
    color: #333333;
    cursor: pointer;
    margin-right: 5px;
`;
const SaveBtn = styled.button`
    padding: 10px 30px;
    background-color: #37ADA7;
    border: 1px solid #37ADA7;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    margin-left: 15px;
`;
const CancelButton = styled.button`
    padding: 10px 20px;
    background-color: #ffffff;
    border: 1px solid #37ADA7;
    border-radius: 5px;
    color: #333333;
    cursor: pointer;
    margin-right: 5px;
`;
const SaveButton = styled.button`
    padding: 10px 30px;
    background-color: #37ADA7;
    border: 1px solid #37ADA7;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    margin-right: 25px;
`;
const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;
const RadioBlock = styled.div`
    margin-top: 10px;
`;

const SelectedRadio = styled.div`
    background-color: #37ADA7;
    border: 1px solid #1f615e;
    padding: 5px 10px;
    color: #ffffff;
    display: inline-block;
    cursor: pointer;
`;
const UnselectedRadio = styled.div`
    background-color: #F4F4F4;
    border: 1px solid #8c8c8c;
    padding: 5px 10px;
    color: #262626;
    display: inline-block;
    cursor: pointer;
`;
const ModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 5px;
    display: inline-block;
`;

const ModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 12px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  width: calc(100vw - 300px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 60px;
  z-index: 1000;
  opacity: 0.8;
`;

const ModalContainer = styled.div`
    position: fixed;
    background-color: #ffffff;
    top: 100px;
    left: calc(50vw - 250px);
    width: 650px;
    box-sizing: border-box;
    height: 400px;
    min-height: 300px;
    box-sizing: border-box;
    background-color: #F3F7FB;
    border: 1px solid #F3F7FB;
    border-radius: 5px;
    z-index: 1010;
    opacity: 1.0;
`
const ModalHeader = styled.div`
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 100px;
    min-height: 100px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    border-left: 7px solid #04ADA8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 1020;
    opacity: 1.0;
`
const ModalBody = styled.div`
    width: 100%;
    padding-left: 15px;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 200px;
    min-height: 200px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    z-index: 1020;
    opacity: 1.0;
`
const ModalFooter = styled.div`
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 100px;
    min-height: 100px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1020;
    opacity: 1.0;
`

const ModalHeaderTextLarge = styled.div`
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 2px;
    padding-right: 0px;
    width: calc(100% - 60px);
    color: #5D748E;
    font-size: 16px;
    font-weight: 700;
    float: left;
`
const ModalHeaderClose = styled.div`
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 2px;
    padding-left: 0px;
    width: 30px;
    color: #5D748E;
    font-size: 20px;
    font-weight: 700;
    float: right;
    cursor: pointer;
`
const ModalHeaderTextSmall = styled.div`
    padding-left: 10px;
    padding-top: 3px;
    width: calc(100% - 10px);
    color: #5D748E;
    font-size: 12px;
    font-weight: 700;
`

const ClearFloat = styled.div`
    clear: both;
`

class AddQSetModal extends React.Component {
    state = {nickname: '', is_active: 1, qset_id: '0'}
    switchRadio = (value) => {
        this.setState({is_active: value})
    }

    changeNickName = (event) => {
        let nickname = event.target.value
        this.setState({nickname})
    }

    saveQSet = () => {
        if(this.state.nickname.trim() === '') {
            alert('You must specify a name for the Question Set.')
            return
        }
        this.props.saveQSet(this.state)
    }
    render() {
        console.log('AddQSetModal state', this.state)
        return (<div>
            <InactiveOverlay />
            <ModalContainer>
                <ModalHeader>
                    <ModalHeaderTextLarge>Add Question Set</ModalHeaderTextLarge><ModalHeaderClose onClick={this.props.closeAddQSetModal}><FaTimes /></ModalHeaderClose>
                    <ClearFloat />
                    <ModalHeaderTextSmall>Complete the required fields to create a Question Set</ModalHeaderTextSmall>
                </ModalHeader>
                <ModalBody>
                <ModalLabel>Question Set Name</ModalLabel>
                <ModalInput style={{width: "95%"}} onChange={this.changeNickName} value={this.state.nickname} />
                <ModalLabel>Active?</ModalLabel>
                {
                    (() => {
                        if(this.state.is_active === 1) {
                            return (
                                <RadioBlock>
                                    <SelectedRadio>Yes</SelectedRadio>
                                    <UnselectedRadio onClick={() => this.switchRadio(0)}>No</UnselectedRadio>
                                </RadioBlock>
                                )
                        }
                        return (
                                <RadioBlock>
                                    <UnselectedRadio onClick={() => this.switchRadio(1)}>Yes</UnselectedRadio>
                                    <SelectedRadio>No</SelectedRadio>
                                </RadioBlock>
                                )
                    })()
                }
                </ModalBody>
                <ModalFooter>
                    <SaveButton onClick={this.saveQSet} style={{float: 'right', marginTop: 15, backgroundColor: '#143151'}}>Submit</SaveButton>
                    <CancelButton onClick={this.props.closeAddQSetModal} style={{float: 'right', marginTop: 15, backgroundColor: '#ffffff'}}>Cancel</CancelButton>
                </ModalFooter>
            </ModalContainer>
        </div>)
    }
}

class QuestionsManagerHOC extends React.Component
{
	state = {
		active_section_id: "questionsets",
        active_qset_id: '0',
        show_inactive_qsets: false,
        qsets: null,
        qsets_downloaded: false,
		dirty: false,
        permissions: {},
		module_config: null,
        all_company_users: null,
        changed_user_roles: {},
        requester: "",
        ready: false,
        show_addqset_modal: false,
        lp_subscribe: false,
        alert_param: null,
        updateqset: null
	};
	constructor(props) {
        super(props);
        // console.log("module_config===>", Store.getStoreData('module_config'))
        let module_config = Store.getStoreData('module_config')
        if (Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'manager') {
            let qsets = Store.getStoreData('qsets')
            if(qsets === null) {
                const postData = { command: "qsets", action: 'list_qsets' };
                const api = new APICall();
                api.command(postData, this.processQsets);
                this.setState({active_section_id: 'questionsets'});
            } else {
                this.setState({active_section_id: 'questionsets', qsets, ready: true});
            }
        }else if(Store.getStoreData('role') === 'am' && 'general' in module_config && module_config.general.allow_am_stp_edit_ques_sets === true){
            let qsets = Store.getStoreData('qsets')
            if(qsets === null) {
                const postData = { command: "qsets", action: 'list_qsets' };
                const api = new APICall();
                api.command(postData, this.processQsets);
                this.setState({active_section_id: 'questionsets'});
            } else {
                this.setState({active_section_id: 'questionsets', qsets, ready: true});
            }
        }
    }

    getInitialPermission = (result) => {
        let requester = this.props.match ? this.props.match.params.requester : "";
        //console.log("requester:", requester);

        let contactlist = JSON.parse(localStorage.getItem('contactlist'));
        let user_roles = contactlist.result.user_roles['attest'];
        let cls = contactlist.result.contactlist;

        let company_users = [];
        for(let d of result.activeUsers){
            let cu = {};
            cu['email'] = d.EmailAddress;
            cu['user_id'] = d.ID;
            cu['name'] = d.ContactName;
            cu['role'] = d.ID in user_roles ? user_roles[d.ID] : 'no_access';
            company_users.push(cu);
        }
        console.log("company_users===>", company_users);
        console.log("result ===>", result);

        const permissions = {
            user_roles: user_roles,
            roles: result.roles,
            company_users: company_users
        };
        const all_company_users = JSON.parse(JSON.stringify(company_users));

        // const permissions = {
        //     user_roles: result.user_roles,
        //     roles: result.roles,
        //     company_users: result.company_users
        // };
        //const all_company_users = JSON.parse(JSON.stringify(result.company_users));
        if('general' in result.moduleconfig && !('at_type' in result.moduleconfig.general))result.moduleconfig.general.at_type = 'All'
        let langs = result.languages['result'];
        Store.updateStore('languages', langs)
        this.setState({all_company_users, module_config: result.moduleconfig, requester,lp_subscribe: result.lp_subscribe, ready: true});
    }

	returnSection = (section) => (event) => {
        event.preventDefault();
        console.log("section==>",section)
        if(section !== 'reminders'){
            if (Store.getStoreData('role') === 'admin_manager') {
                const postData = { command: "list_company_and_users" };
                const api = new APICall();
                api.command(postData, this.getInitialPermission);
            }
        }
        this.setState({active_section_id: section});
    }

    deleteDistributionValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.distribution_list = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeletedistributionValue);
    }

    afterDeletedistributionValue = (result) => {
        console.log(result);
        //alert("Distribution Group deleted successfully")
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'Distribution Group deleted successfully.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
        //this.setState({module_config: result.result});
    }
    updateafterallProcesscomplete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
    }

    //all function start

    updateGeneral = (obj) => {
        let {module_config} = this.state;
        module_config.general = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateGenaralItem);
    }

    afterUpdateGenaralItem = (result) => {
        console.log(result);
       this.setState({module_config: result.result});
       let attest_index = Store.getStoreData('attest-index')
       if(attest_index !== null && 'result' in attest_index && 'module_config' in attest_index.result){
            attest_index.result.module_config = result.result;
       }
       console.log('attest_index==>', attest_index);
       Store.updateStore('attest-index', attest_index)
    }

    updateAdditionalText = (value) => {
        console.log("moduleemail",value)
        let {module_config} = this.state;
        module_config.general.configure_attestation_wording_details.additional_text = value
        this.setState({module_config});
    }
    updateConfirmationText = (value) => {
        console.log("moduleemail",value)
        let {module_config} = this.state;
        module_config.general.configure_attestation_wording_details.confirmation_text = value
        this.setState({module_config});
    }

    updateModuleconfigGeneralAddText = (event) => {
        event.preventDefault();
        let {module_config} = this.state;
        console.log("modulecong",module_config)
        let lp = Store.getStoreData('language_pack');
        if(module_config.general.configure_attestation_wording_details.confirmation_text === ""){
            //alert("Please insert the Confirmation Text.")
            let alert_param = {title: lp['alert_popup_title'] === undefined ? 'Alert' : lp['alert_popup_title'], message: 'Please insert the Confirmation Text.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		    this.setState({alert_param: alert_param});
            return;
        }
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateGeneralAddText);
    }

    afterUpdateGeneralAddText = (result) => {
        console.log(result);
        //alert("Your changes have been saved.")
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'Your changes have been saved.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
       //this.setState({module_config: result.result});
       let attest_index = Store.getStoreData('attest-index')
       if(attest_index !== null && 'result' in attest_index && 'module_config' in attest_index.result){
            attest_index.result.module_config = result.result;
       }
       console.log('attest_index==>', attest_index);
       Store.updateStore('attest-index', attest_index)
   }

   saveDistributionValue = (obj) => {
        console.log("object",obj);
        let {module_config} = this.state;
        module_config.distribution_list.push(obj)
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afteraddDistributionValue);
    }

    afteraddDistributionValue = (result) => {
        console.log(result);
        //alert("Distribution Group added successfully")
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'Distribution Group added successfully.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
        //this.setState({module_config: result.result});
    }

    updateDistributionValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.distribution_list = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateDistributionValue);
    }
    afterUpdateDistributionValue = (result) => {
        console.log(result);
        //alert("Distribution Group updated successfully")
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'Distribution Group updated successfully.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
        //this.setState({module_config: result.result});
    }

    updateEmail = (obj) => {
        let {module_config} = this.state;
        module_config.email = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterEmailUpdate);
    }
    afterEmailUpdate = (result) => {
        // console.log(result);
        alert(result.error_msg);
        this.setState({module_config: result.result});
    }
    searchUsers = (search_string) => {
        if (search_string === "") {
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = this.state.all_company_users;
            this.setState({permissions});
        } else {
            let all_company_users = JSON.parse(JSON.stringify(this.state.all_company_users));
            let filtered_company_users = [];
            all_company_users.forEach((user) => {
                if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                    filtered_company_users.push(user);
                }
            })
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = filtered_company_users;
            // console.log("in search:", permissions.company_users);
            this.setState({permissions});
        }
    }

    changeAllRoles = (role) => {
        let permissions = JSON.parse(JSON.stringify(this.state.permissions));
        if (role !== "0") {
            permissions.company_users.forEach((user) => {
                user.role = role;
            })
        }else{
            permissions.company_users = this.state.all_company_users;
        }
        let changed_user_roles = {};
        permissions.company_users.forEach((user) => {
            changed_user_roles[user.user_id] = user.role;
        })
        // console.log("change all roles:", permissions.company_users);
        this.setState({permissions, changed_user_roles});
    }

    updatePermissions = (obj, single_obj) => {
        // console.log("single_obj:", single_obj);
        let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
        changed_user_roles[single_obj.user_id] = single_obj.role;
        // console.log("changed_user_roles:", changed_user_roles);
        this.setState({permissions: obj, changed_user_roles});
        // console.log("Updated Permission:", obj);
    }

    updateReminder = (obj) => {
        let {module_config} = this.state;
        module_config.reminders = obj;
        const new_config = {...module_config};
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdatereminder);
    }

    afterUpdatereminder = (result) => {
        console.log(result);
       this.setState({module_config: result.result});
    }

    updateReminderSlider = (obj) => {
        let {module_config} = this.state;
        module_config.reminders = obj;
        this.setState({module_config: module_config});
    }

    //all function end


    updateModuleconfig = (event) => {
        event.preventDefault();
        let {module_config, changed_user_roles} = this.state;
        console.log("modulecong",module_config)
        const api = new APICall();
        let postData = {command: "save_module_config", module_config: module_config };
        if (this.state.active_section_id === "permissions") {
            postData = {command: "save_user_role", changed_user_roles: changed_user_roles};
            api.command(postData, this.afterRoleUpdate);
            Reload.RestoreStorage(changed_user_roles);
        } else {
            api.command(postData, this.afterUpdate);
        }
    }

    afterRoleUpdate = (result) => {
        alert(result.error_msg);
        window.location.reload()
    }
    afterUpdate = (result) => {
        console.log(result);
       alert(result.error_msg);
       this.setState({module_config: result.result});
       // window.location.reload();
   }

//    closePage = (event) => {
//         event.preventDefault();
//         window.close();
//     }

    closePage = (event) => {
        event.preventDefault();
        let url = "/";
        if (this.state.requester !== "") {
            const reqarr = this.state.requester.split("_");
            url = `/${reqarr[0]}/${reqarr[1]}`;
        }
        window.location.replace(url);
    }
    exportToCSV  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('table_cur_data');
        console.log("cur_table_data",cur_table_data)
        var cur_table_arr = [];
        let headerCSV = {type: "Conflict Type", status: "Status", id: "1234"}
        cur_table_data.unshift(headerCSV);
        for(let i = 0; i<  cur_table_data.length; i++){
          var container = [];
          container = Object.values(cur_table_data[i])
          if(container.length > 0);
        //   container.shift();
            container.pop()

          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8,"
            + cur_table_arr.map(e => e.join(", ")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Conflict_Types.csv");
        document.body.appendChild(link);
        link.click();
    }

    processQsets = (result) => {
        console.log('resut qsets', result)
        let qsets = result.result.qsets
        Store.updateStore('qsets', qsets)
        this.setState({active_section_id: 'questionsets', qsets, ready: true});
    }

    setQuestionsetSection = () => {

        let qsets = Store.getStoreData('qsets')
        if(qsets === null) {
            const postData = { command: "qsets", action: 'list_qsets' };
            const api = new APICall();
            api.command(postData, this.processQsets);
            this.setState({active_section_id: 'questionsets'});
        } else {
            this.setState({active_section_id: 'questionsets', qsets});
        }
    }

    showInactiveQsets = () => {
        let show_inactive_qsets = !this.state.show_inactive_qsets
        this.setState({show_inactive_qsets})
    }

    showAddQSetModal = () => {
        console.log('showAddQSetModal')
        this.setState({show_addqset_modal: true})
    }

    closeAddQSetModal = () => {
        this.setState({show_addqset_modal: false})
    }

    processSaveQset = (result) => {
        console.log('processSaveQset result', result)
        if(result.error_code === 0) {
            let qsets = result.result.qsets
            this.setState({qsets, show_addqset_modal: false, active_qset_id: result.result.active_qset_id})
        }
    }

    processSaveQset_update = (result) => {
        console.log('processSaveQset result', result)
        if(result.error_code === 0) {
            let qsets = result.result.qsets
            this.setState({qsets, show_addqset_modal: false, active_qset_id: result.result.active_qset_id, updateqset: {sections: {}}})
        }
    }

    saveQSet = (qset) => {
        let api = new APICall()
        let postData = {command: 'qsets', action: 'update_qset', qset_id: 0, nickname: qset.nickname, is_active: qset.is_active, is_flag_review: 1, qset_json: {sections: {}}}
        api.command(postData, this.processSaveQset_update);
    }

    updateQSet = (params) => {
        let api = new APICall()
        let postData = {command: 'qsets', action: 'update_qset', qset_id: params.qset_id, nickname: params.qset_header.nick_name, is_active: params.qset_header.is_active, is_flag_review: params.qset_header.is_flag_review, qset_json: params.qset}
        api.command(postData, this.processSaveQset);
    }

    setActiveQSet = (active_qset_id) => {
        // this.setState({active_qset_id})
        if(active_qset_id !== '0') {
            this.setState({active_qset_id, updateqset: null})
            const postData = {command: "qsets", action: 'get_qset', qset_id: active_qset_id};
            const api = new APICall();
            api.command(postData, this.processGetQset(active_qset_id));
            // this.setState({active_qset_id: null})
        } else {
            this.setState({active_qset_id: '0'})
        }
    }

    processGetQset =  (active_qset_id) => (result) => {
        this.setState({active_qset_id: active_qset_id,
            updateqset: result.result.qset
        })
    }

    updateQSetGeneral = (params) => {
        let api = new APICall()
        let postData = {
            command: 'qsets',
            action: 'update_qset',
            qset_id: params.qset_id,
            nickname: params.qset_header.nick_name,
            is_active: params.qset_header.is_active,
            is_flag_review: params.qset_header.is_flag_review,
            qset_json: params.qset
        }
        api.command(postData, this.processUpdateQSetGeneral);
    }

    processUpdateQSetGeneral = (result) => {
        console.log(result);
        if(result.error_code === 0) {
            let qsets = result.result.qsets
            this.setState({qsets, show_addqset_modal: false, active_qset_id: result.result.active_qset_id})
        }
    }

    render()
	{
        let module_config = Store.getStoreData('module_config')
        if (Store.getStoreData('role') === 'team') {
            return (
                <ErrorBar>
                    <div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
                    <div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>
                    <div style={{clear: "both"}}></div>
                </ErrorBar>
            );
	    }else if(Store.getStoreData('role') === 'am' && 'general' in module_config && module_config.general.allow_am_stp_edit_ques_sets === false){
            return (
                <ErrorBar>
                    <div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
                    <div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>
                    <div style={{clear: "both"}}></div>
                </ErrorBar>
            );
        }
        if (!this.state.ready) {
            return (<div><CSLLoader /></div>);
        }
        let qsets = this.state.qsets === null ? [] : this.state.qsets
        let active_qsets_num = 0
        for(let q of qsets) {
            if(q.is_active === 1)active_qsets_num++
        }
        let inactive_qsets_num = qsets.length - active_qsets_num
        let qset_header = null

        for(let qh of qsets) {
            if(this.state.active_qset_id.toString() !== '0' && qh.id.toString() === this.state.active_qset_id.toString()) {
                qset_header = qh
                break
            }
        }


    //console.log('AddQSetModal this.state', this.state)
	return (
		<div style={{padding: "10px 10px 20px 10px"}}>
			<CloseBtn onClick={this.closePage}><FaTimes /></CloseBtn>
			<div style={{clear: "both"}}></div>
			<ReviewBodyContainer>
				<ReviewLeftContainer style={{position: "sticky", top: "2px"}}>
                    {
                        (() => {
                            // if(this.state.module_config.general.at_type === 'All' || this.state.module_config.general.at_type === 'QuestionSet') {
                                return (
                                    <div>
                                        <ReviewTab onClick={this.setQuestionsetSection} style={{marginTop: "2px"}}>
                                            {
                                                (() => {
                                                    if (this.state.active_section_id === "questionsets") {
                                                        return (<div style={{float: "left", fontWeight: "600"}}>Questions Manager</div>);
                                                    } else {
                                                        return (<div style={{float: "left"}}>Questions Manager</div>);
                                                    }
                                                })()
                                            }
                                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                            <div style={{clear: "both"}}></div>
                                        </ReviewTab>
                                        <div>
                                        {
                                            (() => {
                                                if(this.state.active_section_id === 'questionsets') {
                                                    if(this.state.qsets === null) {
                                                        return <QSetTab>Retrieving Question Sets from the server...</QSetTab>
                                                    } else {
                                                        return (<div>
                                                            {
                                                                (() => {
                                                                    if(active_qsets_num === 0) {
                                                                        return <QSetTab style={{cursor: 'default'}}>No Active Question Sets found...</QSetTab>
                                                                    } else {
                                                                        return (
                                                                            <div>
                                                                            {
                                                                                qsets.map((q, i) => {
                                                                                    if(q.is_active === 1){
                                                                                        if(q.id.toString() === this.state.active_qset_id.toString()) {
                                                                                            return <ActiveQSetTab style={{paddingLeft: 25}} key={i}>{q.nick_name}</ActiveQSetTab>
                                                                                        } else {
                                                                                            return <QSetTab onClick={() => this.setActiveQSet(q.id.toString())} style={{paddingLeft: 25}} key={i}>{q.nick_name}</QSetTab>
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                            </div>
                                                                            )
                                                                    }

                                                                })()
                                                            }
                                                            <SpacerTab />
                                                            <QSetTab onClick={() => this.showAddQSetModal()} style={{fontWeight: 600, color: '#8BD8D6'}}>Add new</QSetTab>
                                                            <SpacerTab />
                                                            <ReviewTab onClick={this.showInactiveQsets} style={{marginTop: "2px"}}>
                                                            {
                                                                (() => {
                                                                    if (this.state.show_inactive_qsets) {
                                                                        return (<div style={{float: "left", fontWeight: "600"}}>Inactive</div>);
                                                                    } else {
                                                                        return (<div style={{float: "left"}}>Inactive</div>);
                                                                    }
                                                                })()
                                                            }
                                                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px"}}>
                                                            {
                                                                (() => {
                                                                    if (this.state.show_inactive_qsets) {
                                                                        return (<IoIosArrowUp />);
                                                                    } else {
                                                                        return (<IoIosArrowDown />);
                                                                    }
                                                                })()
                                                            }
                                                            </div>
                                                            <div style={{clear: "both"}}></div>
                                                            <SpacerTab />
                                                            </ReviewTab>
                                                            {
                                                                (() => {
                                                                    if(this.state.show_inactive_qsets) {
                                                                        return (<div>
                                                                                {
                                                                                    (() => {
                                                                                        if(inactive_qsets_num === 0) {
                                                                                            return <QSetTab style={{cursor: 'default'}}>No Inactive Question Sets found...</QSetTab>
                                                                                        } else {
                                                                                            return (
                                                                                                <div>
                                                                                                {
                                                                                                    qsets.map((q, i) => {
                                                                                                        if(q.is_active === 0){
                                                                                                            if(q.id.toString() === this.state.active_qset_id.toString()) {
                                                                                                                return <ActiveQSetTab style={{paddingLeft: 25}} key={i}>{q.nick_name}</ActiveQSetTab>
                                                                                                            } else {
                                                                                                                return <QSetTab onClick={() => this.setActiveQSet(q.id.toString())} style={{paddingLeft: 25}} key={i}>{q.nick_name}</QSetTab>
                                                                                                            }
                                                                                                        }

                                                                                                    })
                                                                                                }
                                                                                                </div>
                                                                                                )
                                                                                        }

                                                                                    })()
                                                                                }
                                                                            </div>)
                                                                    }
                                                                })()
                                                            }
                                                        </div>)
                                                    }
                                                }
                                            })()
                                        }
                                        </div>
                                    </div>
                                    )
                            // }
                        })()
                    }

				</ReviewLeftContainer>
				<ReviewRightContainer>
					{
						(() => {
                            if (this.state.active_section_id === "questionsets") {
                                return (<QuestionSet_update
                                            qset_id={this.state.active_qset_id}
                                            qset_header={qset_header}
                                            qset={this.state.updateqset}
                                            updateQSet={this.updateQSet}
                                            updateQSetGeneral={this.updateQSetGeneral}
                                            is_active={qset_header !== null && 'is_active' in qset_header ? qset_header.is_active : 1}
                                        />);
                            }
						})()
					}


				</ReviewRightContainer>
				<div style={{clear: "both"}}></div>
			</ReviewBodyContainer>
            {
                (() => {
                    if(this.state.show_addqset_modal) {
                        console.log('showAddQSetModal this.state.show_addqset_modal', this.state.show_addqset_modal)
                        return <AddQSetModal closeAddQSetModal={this.closeAddQSetModal} saveQSet={this.saveQSet} />
                    }
                })()
            }
        <AlertBox alertParam = {this.state.alert_param}/>
		</div>
	);
}
}

export default QuestionsManagerHOC;
