import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store.js';
import Dropzone from '../Common/Dropzone';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import CSLTable from '../Common/CSLTable';
import DatePicker from 'react-date-picker';
import AMCompleteTaskModal from './AMCompleteTaskModal';
import AMQuestionSetCompleteTaskModal from './AMQuestionSetCompleteTaskModal';
import AMUpdateShareAttestation from './AMUpdateShareAttestation';
import AMResendShareAttestationModal from './AMResendShareAttestationModal';
import AMExtendShareAttestationModal from './AMExtendShareAttestationModal';
import Utils from '../../Common/Utils.js';
import AlertBox from '../Common/AlertBox';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import XcelLoader from './../Common/XcelLoader';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';


const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151;  
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #364E69;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalLabelSub = styled.div`
    color: #000000;    
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #5F6368;    
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:20px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`   
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box; 
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};   
`;
const UserHr = styled.div`
border-top: 2px solid #E9EBED;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const OverviewInfoHeader = styled.div`
    padding: 16px 15px;
    background-color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; 
    margin: 0 auto;
    margin-top: 30px; 
    border: 1px solid #d7dddf;       
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;    
    margin: 0 auto;
    margin-bottom: 30px;
`;
const SearchInput = styled.input`
    height: 25px;
    width: 95%;
    margin-right: 180px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 151%;
  width: 82%;
  background-color: #ffffff;
  position: absolute;
  left: 7%;
  top: 30px;
  z-index: 1002;
  opacity: 0.8;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
    cursor: pointer;
`;

const AlwaysScrollToView = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

class AMCompleteAttestationModal extends React.Component
{
    state = {
        ready: false,
        binFiles: [],
        cur_files_json: null,
        documentVersion: "",
        uploadedDate: new Date(),
        editedDate: new Date(),
        distribution_group: 0,
        distribution_group_list: [],
        distribution_group_user_list: [],
        showModal: null,
        isSharePlusButton: false,
        showUpdateShareAttestationModal: false,
        Parent_taskid: 0,
        showResendShareAttestationModal: false,
        atType: "",
        isShareUserRemove: false,
        alert_param: null,
        is_flag_review: 1
    };

    componentDidMount()
	{
        console.log("isClickLink", this.props.linkNumberName)
        let form_data = {};
        form_data = Store.getStoreData('cur_q_modal_data');
        let task_json = JSON.parse(form_data.task_json);
        console.log("task_json", task_json)

        let atType = "Document";
        if('at_type' in task_json.object_data){
            atType = task_json.object_data.at_type;
        }

        let is_flag_review = 1
        if(atType === 'QuestionSet' && 'is_flag_review' in task_json.object_data.qset_header){
            is_flag_review = task_json.object_data.qset_header.is_flag_review;
        }

        let duedate = new Date(form_data.due_date);
        let uploadmonth = parseInt(duedate.getMonth());
        let uploadedmonth = uploadmonth + 1;
        var uploadedDateStr = duedate.getDate() < 10 ? '0' + duedate.getDate()+'/' : duedate.getDate()+'/';
        uploadedDateStr += uploadedmonth >= 10 ? uploadedmonth+'/'+duedate.getFullYear() : '0'+uploadedmonth+'/'+duedate.getFullYear();

        let actiondate = new Date(task_json.task_data.actiondate);
        let editmonth = parseInt(actiondate.getMonth());
        let editedmonth = editmonth + 1;
        var editedDateStr = actiondate.getDate() < 10 ? '0' + actiondate.getDate()+'/' : actiondate.getDate()+'/';
        editedDateStr += editedmonth >= 10 ? editedmonth+'/'+actiondate.getFullYear() : '0'+editedmonth+'/'+actiondate.getFullYear();

        let distribution_group_list = [];
        let moduleconfig = Store.getStoreData('module_config');
        if('distribution_list' in moduleconfig && moduleconfig.distribution_list.length !== 0){
            distribution_group_list = moduleconfig.distribution_list
        }

        let dtext = task_json.object_data.headerinfo.description;
        if(Utils.isBase64(dtext)){
            dtext = window.decodeURIComponent(atob(dtext))
        }

        this.setState({ cur_files_json: task_json.object_data.bin_files,
            documentVersion: task_json.object_data.headerinfo.document_version,
            uploadedDate: uploadedDateStr,
            editedDate: editedDateStr,
            description: dtext,
            distribution_group_list: distribution_group_list,
            distribution_group: task_json.object_data.headerinfo.distribution_group_id,
            atType: atType,
            Parent_taskid: form_data.id,
            is_flag_review
        });

        //old start
        // let command_data = { "command": "listShareTask", parent_id: form_data.id };
        // let api = new APICall();
        // api.command(command_data, this.showShareTaskTask_old(this.props.linkNumberName));
        //old end
        //new start
        this.showShareTaskTask(this.props.linkNumberName)
        //new end
    }

    showShareTaskTask = (linkNumberName) => {
        console.log('linkNumberName', linkNumberName)
        let shareTaskList = [];
        let sharetasks = Store.getStoreData('updated_shareTask');
        sharetasks.forEach((user) => {
            let objectdata = JSON.parse(user.task_json).object_data;
            if(user.is_active === 1){
                if(linkNumberName === 'flagged'){
                    if('qset_answer' in objectdata && 'flagged' in objectdata.qset_answer && objectdata.qset_answer.flagged === true){
                        if('isReviewed' in objectdata.qset_answer){
                            if(!(objectdata.qset_answer.isReviewed)){
                                shareTaskList.push(user)
                            }
                        }else{
                            shareTaskList.push(user)
                        }

                    }
                }else if(linkNumberName === 'info'){
                    if(objectdata.headerinfo.Type === 'information'){
                        shareTaskList.push(user)
                    }
                }else if(linkNumberName === 'acknowledge'){
                    if(objectdata.headerinfo.Type === 'acknowledge'){
                        shareTaskList.push(user)
                    }
                }
                else{
                    shareTaskList.push(user)
                }
            }
        })
        //console.log('result.result.sharetasks', result.result.sharetasks);
        //console.log('shareTaskList', shareTaskList);
        Store.updateStore('extendDateshareTaskList', shareTaskList)
        this.setState({ distribution_group_user_list: shareTaskList, ready: true });
    }

    closeView = () => {
        if(Store.getStoreData('isreferenceUrl') === true){
            if(Store.getStoreData('isReferenceEmail') === true){
                window.location = '/';
              }else{
                //window.location = '/select/microservice/mstop/tmview';
                this.props.closeTaskModal();
              }
        } else {
            if(this.state.isShareUserRemove){
                this.props.closeModalAndRefresh();
            }else{
                this.props.closeModal();
            }
        }
    }

    updateShareAttestation = () => {
        this.setState({isSharePlusButton: true})
    }

    updateShareAttestationModal = () => {
        let form_data = {};
        form_data = Store.getStoreData('cur_q_modal_data');
        let extendDateshareTaskList = Store.getStoreData('extendDateshareTaskList');
        //console.log("extendDateshareTaskList==>", extendDateshareTaskList)
        let extendenShareObject = {};
        extendDateshareTaskList.forEach((user) => {
            extendenShareObject[user.cur_assigned_to.toString()]=1
        })
        //console.log('extendenShareObject==>', extendenShareObject)
        Store.updateStore('extendenShareObject', extendenShareObject)
        this.setState({showUpdateShareAttestationModal: true, Parent_taskid: form_data.id, ready:true})
    }

    extendDueDateShareAttestationModal = () => {
        let form_data = {};
        form_data = Store.getStoreData('cur_q_modal_data');
        this.setState({showExtendDateShareAttestationModal: true, Parent_taskid: form_data.id, ready:true})
    }

    resendShareAttestation = () => {
        let form_data = {};
        form_data = Store.getStoreData('cur_q_modal_data');
        this.setState({showResendShareAttestationModal: true, Parent_taskid: form_data.id, ready:true})
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    processMyshareGroup = () => {
        var sharedata = this.state.distribution_group_user_list;
        let lp = Store.getStoreData('language_pack')
        let at_type = 'at_type_val' in this.props ? this.props.at_type_val : 'Document';

        let isShowFlagged = false;
        if(at_type === 'QuestionSet'){
            isShowFlagged = true;
            if(this.state.is_flag_review === 0){
                isShowFlagged = false;
            }
        }

        let gc_users = Store.getStoreData('gc_users');
        let has_gc = Store.getStoreData('has_gc');
        let role = Store.getStoreData('role');
        let updateSharedata = [];
        if(role === 'am' && has_gc === true){
            sharedata.forEach((item) => {
                let curassignto = item.cur_assigned_to.toString();
                if(curassignto in gc_users){
                    updateSharedata.push(item);
                }
            })
        }else{
            updateSharedata = sharedata;
        }

        //console.log("sharedata",updateSharedata);
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[
        {Header: lp['user_shared_with_column_title'] === undefined ? 'Name' : lp['user_shared_with_column_title'], accessor: 'name_value', minWidth: 100, headerStyle: {textAlign: 'left'}},
        {Header: lp['due_date_column_title'] === undefined ? 'Due Date' : lp['due_date_column_title'], accessor: 'due_date', minWidth: 80, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
            if (a === b) {
                return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          }},
        {Header: lp['acknowledge_type_column_title'] === undefined ? 'Type' : lp['acknowledge_type_column_title'], accessor: 'type', minWidth: 80, headerStyle: {textAlign: 'left'}},
        {Header: lp['status_column_title'] === undefined ? 'Status' : lp['status_column_title'], accessor: 'status', minWidth: 80, headerStyle: {textAlign: 'left'}},
        {Header: lp['completed_date_column_title'] === undefined ? 'Completed Date' : lp['completed_date_column_title'], accessor: 'completed_date', minWidth: 80, headerStyle: {textAlign: 'left'}},
         {'Header' : lp['flagged_column_title'] === undefined ? 'Flagged' : lp['flagged_column_title'],accessor: 'flaged', Cell: row => (
            <div>
                    {
                        (()=> {
                            if (row.original.is_flagged) {
                                return(<div style={{fontSize:"21px", float: "right"}} ><IoIosCheckboxOutline /></div>);
                            } else {
                                return(<div  style={{fontSize:"21px", float: "right"}} ><IoIosSquareOutline /></div>);
                            }
                        })()
                    }
            </div>
         ), width: 120, headerStyle: {textAlign: 'left'}, show: isShowFlagged},
         {'Header' : lp['action_column_title'] === undefined ? 'Action' : lp['action_column_title'], Cell: row => (
            <div style={{display: "inline-flex"}}>
                {
                    (()=> {
                        if(row.original.status === "Acknowledged" || row.original.status === "Completed"){
                            if(row.original.at_type === 'Document' || row.original.at_type === 'QuestionSet'){
                                return(<MRButton style={{cursor: 'pointer'}} onClick={() => this.detailsCompletedAttestation(row.original.id, row.original.at_type)}>{lp['view_button'] === undefined ? 'View' : lp['view_button']}</MRButton> )
                            }
                        }else{
                            return(<MRButton style={{cursor: 'pointer'}} onClick={() => this.resendEmailtoUser(row.original.id,row.original.userid)}>{lp['resend_button_view_attest'] === undefined ? 'Resend' : lp['resend_button_view_attest']}</MRButton> )
                        }
                    })()
                }
                {
                (() => {
                    if(Store.getStoreData('role') === 'manager' || Store.getStoreData('role') === 'admin_manager'){
                        if(row.original.status === 'Pending' || row.original.status === 'Overdue'){
                            return(<div style={{fontSize: "20px",marginLeft: "15px",cursor: 'pointer', color: "#7d7d7d", fontWeight: "bold"}} onClick={this.customAlertDeleteSharedTask(row.original.id, row.original.userid)}>x</div>)
                        }
                    }
                })()
                }

            </div>
          ), width: 140, headerStyle: {textAlign: 'center'}}];

         for(let d of updateSharedata) {
            i++;
            //console.log("d==>",d)
            //let actiondata = JSON.parse(d.task_json).action_data;
            let taskdata = JSON.parse(d.task_json).task_data;
            let objectdata = JSON.parse(d.task_json).object_data;
            let assigntoName = Store.getStoreData('contacts')[d.cur_assigned_to]

            let actiondate = new Date(d.created_at);
            let editmonth = parseInt(actiondate.getMonth());
            let editedmonth = editmonth + 1;
            var editedDateStr = actiondate.getDate() < 10 ? '0' + actiondate.getDate()+'/' : actiondate.getDate()+'/';
            editedDateStr += editedmonth >= 10 ? editedmonth+'/'+actiondate.getFullYear() : '0'+editedmonth+'/'+actiondate.getFullYear();

            let status = "Pending";
            let completedDate = '';
            if(d.last_action === 'COMP_ATTESTATION_SHARE_COMPLETED'){
                status = "Completed";
                let checkactiondate = moment(taskdata.actiondate).unix()
                completedDate = moment.unix(checkactiondate).format('DD/MM/YYYY');
                if(objectdata.headerinfo.Type === 'acknowledge'){
                    status = "Acknowledged";
                }
            }

            let flaged_value = 'qset_answer' in objectdata ? objectdata.qset_answer.flagged : false;
            if(flaged_value && 'qset_answer' in objectdata && 'isReviewed' in objectdata.qset_answer && objectdata.qset_answer.isReviewed === true){
                flaged_value = false;
            }


            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';
            const c_date = new Date(due_date_str);

            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            //console.log('day, month, year', dd,mm,yyyy)

            let due_date_status = new Date(yyyy, mm-1, dd);
            var due_date_status_final = new Date ( due_date_status );
            due_date_status_final.setHours ( due_date_status.getHours() + 23 );
            due_date_status_final.setMinutes ( due_date_status.getMinutes() + 59 );
            due_date_status_final.setSeconds ( due_date_status.getSeconds() + 59 );

            let today = new Date();
            //console.log('due_date_status', due_date_status_final, 'today',today)
            let text = due_date_status_final > today ? status : 'Overdue';

            if(status === "Pending"){
                text = due_date_status_final > today ? status : 'Overdue';
            }else if(status === "Completed" || status === "Acknowledged"){
                text = status;
            }

            let atType = ""
            if('at_type' in objectdata){
                atType = objectdata.at_type;
            }

            let d_date = new Date(d.due_date);
            let duemonth = parseInt(d_date.getMonth());
            let duedateMonth = duemonth + 1;
            var dueDateStr = d_date.getDate() < 10 ? '0' + d_date.getDate()+'/' : d_date.getDate()+'/';
            dueDateStr += duedateMonth >= 10 ? duedateMonth+'/'+d_date.getFullYear() : '0'+duedateMonth+'/'+d_date.getFullYear();

            let contacts = Store.getStoreData('contacts');
            let elem = {};
            if(d.cur_assigned_to in contacts){
                elem = {'index' : i,
                    'id': d.id,
                    'name_value' : assigntoName,
                    'dateshared' : editedDateStr,
                    'type' : objectdata.headerinfo.Type === "information" ? "Information" : "Acknowledge",
                    'status': text,
                    'is_flagged' : flaged_value,
                    'at_type' : atType,
                    'due_date' : dueDateStr,
                    'userid' : d.cur_assigned_to,
                    'completed_date': completedDate
                   };
                ret.data.push(elem);
            }
        }
        ret.data.sort(function(a,b) {return (a.name_value > b.name_value) ? 1 : ((b.name_value > a.name_value) ? -1 : 0);} );
        ret.data = ret.data.sort((a,b) => {
            if (a.due_date === b.due_date) {
                return 0;
            }
            let adue = a.due_date.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.due_date.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
        });
        Store.updateStore('table_sharedwith_cur_data', ret.data)
        return ret;
    }

    customAlertDeleteSharedTask= (taskid, userid) => (event) => {
        event.preventDefault();
        let alert_param = {title: 'Warning', message: 'Are you sure you want to delete the attestation for this user?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                           alertHandler: this.deleteShareUserTask, stack: {taskid: taskid, user_id: userid }}
        this.setState({alert_param: alert_param})
    }

    deleteShareUserTask = (result, stack) => {
        //event.preventDefault();
        console.log("taskid==>", stack.taskid)
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null, isloadericon: true})
        let form_data = Store.getStoreData('cur_q_modal_data');
        //if (window.confirm('Are you sure you want to delete the attestation for this user?')) {
            let postData = {command: "remove_share_task", task_id: stack.taskid, parent_id: form_data.id, user_id: stack.userid };
            let api = new APICall();
            api.command(postData, this.processRemoveShareAttestationData);
        //}
    }

    processRemoveShareAttestationData = (result) => {
        console.log("result", result)
        //alert("The attestation for this user has been deleted.");
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'The attestation for this user has been deleted.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.customupdateprocessRemoveShareAttestData, stack: {result: result}}
        this.setState({alert_param: alert_param})
    }
    customupdateprocessRemoveShareAttestData = (result, stack) => {
        this.setState({alert_param: null, isLoadedIcon: false})
        let linkNumberName = this.props.linkNumberName;
        let shareTaskList = [];
        stack.result.sharetasks.forEach((user) => {
            let objectdata = JSON.parse(user.task_json).object_data;
            if(linkNumberName === 'flagged'){
                if('qset_answer' in objectdata && 'flagged' in objectdata.qset_answer && objectdata.qset_answer.flagged === true){
                    if('isReviewed' in objectdata.qset_answer){
                        if(!(objectdata.qset_answer.isReviewed)){
                            shareTaskList.push(user)
                        }
                    }else{
                        shareTaskList.push(user)
                    }

                }
            }else if(linkNumberName === 'info'){
                if(objectdata.headerinfo.Type === 'information'){
                    shareTaskList.push(user)
                }
            }else if(linkNumberName === 'acknowledge'){
                if(objectdata.headerinfo.Type === 'acknowledge'){
                    shareTaskList.push(user)
                }
            }
            else{
                shareTaskList.push(user)
            }
        })

        Store.updateStore('extendDateshareTaskList', shareTaskList)
        this.setState({ distribution_group_user_list: shareTaskList, ready: true, isShareUserRemove: true });
    }

    updateShareListReviewed = (result) => {
        console.log("result", result)
        let linkNumberName = this.props.linkNumberName;
        let shareTaskList = [];
        result.sharetasks.forEach((user) => {
            let objectdata = JSON.parse(user.task_json).object_data;
            if(linkNumberName === 'flagged'){
                if('qset_answer' in objectdata && 'flagged' in objectdata.qset_answer && objectdata.qset_answer.flagged === true){
                    if('isReviewed' in objectdata.qset_answer){
                        if(!(objectdata.qset_answer.isReviewed)){
                            shareTaskList.push(user)
                        }
                    }else{
                        shareTaskList.push(user)
                    }

                }
            }else if(linkNumberName === 'info'){
                if(objectdata.headerinfo.Type === 'information'){
                    shareTaskList.push(user)
                }
            }else if(linkNumberName === 'acknowledge'){
                if(objectdata.headerinfo.Type === 'acknowledge'){
                    shareTaskList.push(user)
                }
            }
            else{
                shareTaskList.push(user)
            }
        })
        Store.updateStore('extendDateshareTaskList', shareTaskList)
        this.setState({
            showModal: null,
            distribution_group_user_list: shareTaskList,
            isShareUserRemove: true,
            ready: true
        })
    }

    detailsCompletedAttestation = (taskid, at_type) =>{
        console.log("taskid",taskid)
        let api = new APICall();
        if(at_type === 'Document'){
            let postData = { command: "get_subtask", task_id: taskid};
            api.command(postData, this.openCompleteTaskModal);
        }else{
            let postDatanew = { command: "get_subtask", task_id: taskid};
            api.command(postDatanew, this.openQuestionSetCompleteTaskModal);
        }
    }

    openQuestionSetCompleteTaskModal = (result) => {
        console.log(result);
        Store.updateStore('cur_complete_task', result.result.task);
        // Store.updateStore('cur_parent_pending_task', result.parentdatanew);
        Store.updateStore('cur_parent_binFiles', result.binFiles);
        this.setState({showModal: 'view_details_questionset_completed_modal',ready: true});
    }

    openCompleteTaskModal = (result) => {
        console.log(result);
        Store.updateStore('cur_complete_task', result.result.task);
        // Store.updateStore('cur_parent_pending_task', result.parentdatanew);
        Store.updateStore('cur_parent_binFiles', result.binFiles);
        this.setState({showModal: 'view_details_completed_modal',ready: true});
    }

    resendEmailtoUser = (taskid,userid) =>{
        console.log("taskid",taskid,"userid",userid)
        let postData = {command: "resend_email_to_share_user", task_id: taskid, send_mail: true, contact_id: userid,system_id: 1001};
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterresendCompleted);
    }

    afterresendCompleted = (result) => {
        //alert('The attestation has been re-sent.');
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'The attestation has been re-sent.', ok_text: 'Ok', confirm: false,
                            alertHandler: this.checkalertparam, stack: {}}
          this.setState({alert_param: alert_param})
    }
    checkalertparam = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
    }

    closeModal = (event) => {
        this.setState({
            showModal: null
        })
    }

    closeShareModal = (id) => {
        this.setState({
            showUpdateShareAttestationModal: false,
            showResendShareAttestationModal: false,
            showExtendDateShareAttestationModal: false,
            taskid: id,
            rowindex: 0
        })
    }

    exportToExcel = () => {
      let form_data = Store.getStoreData('cur_q_modal_data');
      let task_json = JSON.parse(form_data.task_json);
      if('at_type' in task_json.object_data && task_json.object_data.at_type === 'QuestionSet'){
        let wb = {SheetNames:[], Sheets:{}};
        let shareWith_tasks = Store.getStoreData('table_sharedwith_cur_data') === null ? [] : Store.getStoreData('table_sharedwith_cur_data');
        wb.SheetNames.push("Shared With Questionset");
        wb.Sheets["Shared With Questionset"] = XLSX.utils.json_to_sheet(XcelLoader.sharewith_export_tasks_questionset(shareWith_tasks));
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'Shared With Questionset' + '-' + tm + fileExtension);
      }else{
        let wb = {SheetNames:[], Sheets:{}};
        let shareWith_tasks = Store.getStoreData('table_sharedwith_cur_data') === null ? [] : Store.getStoreData('table_sharedwith_cur_data');
        wb.SheetNames.push("Shared With Document");
        wb.Sheets["Shared With Document"] = XLSX.utils.json_to_sheet(XcelLoader.sharewith_export_tasks_document(shareWith_tasks));
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'Shared With Document' + '-' + tm + fileExtension);
      }
    }

    render()
    {
        if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
        let lp = Store.getStoreData('language_pack')
        let TableBottomMsg = `Total ${this.processMyshareGroup().data.length} ${lp['assignments_count_title'] === undefined ? 'Assignments' : lp['assignments_count_title']}, ${this.processMyshareGroup().data.filter((d) => d.status !== "Acknowledged").length} ${lp['attestations_outstanding_count_title'] === undefined ? 'attestation outstanding' : lp['attestations_outstanding_count_title']}`;
        return (
            <div>
            {
                (() => {
                    if(this.state.showModal !== null){
                        switch(this.state.showModal) {
                            case 'view_details_completed_modal' : return <div style={{position:'absolute', width:'100%', height:'800px'}}><InactiveOverlay /><AMCompleteTaskModal headerText={'View Attestation'} isResend={true} closeModal={this.closeModal} /></div>; break;
                            case 'view_details_questionset_completed_modal' : return <div style={{position:'absolute', width:'100%', height:'800px'}}><InactiveOverlay /><AMQuestionSetCompleteTaskModal headerText={'View Attestation'} isResend={true} closeModal={this.closeModal} isFollowup={true} updateShareListReviewed={this.updateShareListReviewed}/></div>; break;
                        }
                    }
                })()
            }
            {
                (() => {
                    if(this.state.showUpdateShareAttestationModal){
                        return(<div style={{position:'absolute', width:'100%', height:'800px'}}><InactiveOverlay style={{ height: "290%" }}/>
                            <AMUpdateShareAttestation taskid={this.state.Parent_taskid} closeModal={this.closeShareModal}/></div>)
                    }
                    if(this.state.showResendShareAttestationModal){
                        return(<div style={{position:'absolute', width:'100%', height:'800px'}}><InactiveOverlay />
                            <AMResendShareAttestationModal taskid={this.state.Parent_taskid} closeModal={this.closeShareModal}/></div>)
                    }
                    if(this.state.showExtendDateShareAttestationModal){
                        return(<div style={{position:'absolute', width:'100%', height:'800px'}}><InactiveOverlay />
                        <AMExtendShareAttestationModal taskid={this.state.Parent_taskid} closeModal={this.closeShareModal}/></div>)
                    }
                })()
            }
            <MRModalContainer>
            <AlwaysScrollToView />
                <MRModalHeader>
                    <MRModalHeaderText>{lp['view_attestation_title'] === undefined ? 'View Attestation' : lp['view_attestation_title']}</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                    <MRModalSubHeaderText>{lp['view_submitted_attestation_prompt'] === undefined ? 'View the details of this previously submitted attestation.' : lp['view_submitted_attestation_prompt']}</MRModalSubHeaderText>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <MRModalBody>
                    <div style={{clear: "both"}}></div>
                    <div style={{position: "relative",padding: "35px 35px"}}>
                        <div style={{height: "auto",backgroundColor: "#ffffff",borderRadius: "4px",padding: "25px",marginBottom: "25px"}}>
                            {
                                (() => {
                                    if(this.state.atType === "Document"){
                                        return(<div>
                                            {
                                            (() => {
                                                if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                                    return(<div>
                                                            <MRModalLabel>{lp['document_information_view_attest'] === undefined ? 'Document Information' : lp['document_information_view_attest']}</MRModalLabel>
                                                            <ModalNameInputDiv>
                                                                <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false} downloadThroughApi={true} ParentTaskid={this.state.Parent_taskid}/>
                                                            </ModalNameInputDiv>
                                                            <hr style={{marginTop: "20px", color: "#EBEBEB"}}/>
                                                    </div>)
                                                }
                                            })()
                                            }
                                            <div style={{display: "inline-block",float: "left"}}>
                                                <MRModalLabel>{lp['document_version_view_attest'] === undefined ? 'Document Version' : lp['document_version_view_attest']}</MRModalLabel>
                                                <MRModalLabelSub style={{fontWeight: "600"}}>{this.state.documentVersion}</MRModalLabelSub>
                                            </div>
                                            <div style={{display: "inline-block",float: "right"}}>
                                                <div style={{display: "inline-block",marginRight: "80px"}}>
                                                <MRModalLabel>{lp['uploaded_date_view_attest'] === undefined ? 'Uploaded Date' : lp['uploaded_date_view_attest']}</MRModalLabel>
                                                <MRModalLabelSub style={{fontSize: "12px"}}>{this.state.uploadedDate}</MRModalLabelSub>
                                                </div>
                                                <div style={{display: "inline-block"}}>
                                                <MRModalLabel>{lp['edited_date_view_attest'] === undefined ? 'Edited Date' : lp['edited_date_view_attest']}</MRModalLabel>
                                                <MRModalLabelSub style={{fontSize: "12px"}}>{this.state.editedDate}</MRModalLabelSub>
                                                </div>
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                            <MRModalLabel>{lp['description_title'] === undefined ? 'Description' : lp['description_title']}</MRModalLabel>
                                            {/* <div style={{fontFamily: "'Montserrat', sans-serif",whiteSpace: "pre-line"}}>{this.state.description}</div> */}
                                            <div dangerouslySetInnerHTML={{__html: this.state.description}} style={{textAlign: "justify", whiteSpace: "pre-line", fontFamily: "'Montserrat', sans-serif",fontSize: "14px"}}></div>
                                        </div>)
                                    }else{
                                        return(<div>
                                            <div style={{clear: "both"}}></div>
                                                <MRModalLabel>{lp['description_title'] === undefined ? 'Description' : lp['description_title']}</MRModalLabel>
                                                {/* <div style={{fontFamily: "'Montserrat', sans-serif",whiteSpace: "pre-line"}}>{this.state.description}</div> */}
                                                <div dangerouslySetInnerHTML={{__html: this.state.description}} style={{textAlign: "justify", whiteSpace: "pre-line", fontFamily: "'Montserrat', sans-serif",fontSize: "14px"}}></div>
                                            </div>)
                                    }
                                })()
                            }

                        </div>
                        {
                            (() =>{
                                if(this.state.isSharePlusButton){
                                    return <MRModalSaveCloseBtn style={{width: "200px", height: "30px"}} onClick={this.extendDueDateShareAttestationModal}>{lp['extend_due_date_button'] === undefined ? 'Extend Due Date' : lp['extend_due_date_button']}</MRModalSaveCloseBtn>
                                }
                            })()
                        }
                        <div style={{clear: "both"}}></div>
                        <OverviewInfoHeader style={{marginTop: "5px"}}>
                                <div style={{float: "left", color: "#000000", fontWeight: "600",fontSize: "15px"}}>{lp['shared_with_title'] === undefined ? 'Shared With' : lp['shared_with_title']}</div>
                                <div style={{float: "right", cursor: "pointer",fontSize: "20px",color: "#143151"}} >
                                    {
                                        (() => {
                                            if(this.state.isSharePlusButton){
                                                return (<div><FaPlusCircle onClick={this.updateShareAttestationModal}/></div>)
                                            }
                                        })()
                                    }
                                </div>
                                <div style={{clear: "both"}}></div>
                        </OverviewInfoHeader>
                        <OverviewInfoBody>
                            <CSLTable add={false} processData={this.processMyshareGroup} headerText={""} tableRows={10} refreshCallback={this.refreshState} TableBottomMsg={TableBottomMsg} />
                            <div style={{float: "left"}}>
                                {
                                    (() => {
                                        if(this.state.distribution_group_user_list.length !== 0){
                                            return(<ExportButton onClick={() => this.exportToExcel()}>{lp['export_button_com'] === undefined ? 'Export' : lp['export_button_com']}</ExportButton>)
                                        }
                                    })()
                                }
                            </div>
                            <div style={{clear: "both"}}></div>
                        </OverviewInfoBody>
                    </div>
                </MRModalBody>
                <MRModalFooter>
                    <div style={{position: "relative",padding: "17px"}}>
                    {
                        (() => {
                            if(Store.getStoreData('role') === 'manager' || Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'am'){
                                if('attestStatus' in this.props && (this.props.attestStatus === 'Pending' || this.props.attestStatus === 'Overdue')){
                                    return(<MRModalSaveCloseBtn onClick={this.updateShareAttestation} >{lp['update_button_view_attest'] === undefined ? 'Update' : lp['update_button_view_attest']}</MRModalSaveCloseBtn>)
                                }
                            }
                        })()
                    }
                    {
                        (() => {
                            if('attestStatus' in this.props && this.props.attestStatus === 'Complete'){
                                return(<MRModalSaveCloseBtn onClick={this.resendShareAttestation} >{lp['resend_to_all_button'] === undefined ? 'Resend to All' : lp['resend_to_all_button']}</MRModalSaveCloseBtn>)
                            }
                        })()
                    }

                    <MRModalCancelBtn onClick={this.closeView}>{lp['cancel_button_com'] === undefined ? 'Cancel' : lp['cancel_button_com']}</MRModalCancelBtn>
                    </div>
                </MRModalFooter>
            </MRModalContainer>
            <AlertBox alertParam={this.state.alert_param} />
            </div> );
    }
}

export default AMCompleteAttestationModal;
