import React from 'react';
import styled from 'styled-components';
import CSLTable from '../../Common/CSLTable';
import APICall from '../../../Common/APICall.js';
import EmailConfigModal from './EmailConfigModal';
import LanguageTopHeader from './LanguageTopHeader';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import AlertBox from '../../Common/AlertBox';
import SimpleDropdown from './SimpleDropdown';
import Store from '../../../Common/Store'
import moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const fileExtension = '.xlsx';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EmailContainer = styled.div`
	width: 90%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailLabel = styled.div`
	color: #595959;
	margin-top: 5px;
`;
const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;

const actions = [{ name: "Export", action: "export", show: 1 }];
const action_default = [{ name: "Export", action: "export", show: 1 }];

class LPemail extends React.Component
{
	state = {email: null, variables: null, show_email_config_modal: null, content: null,prefered_columns: {}, active_lang: 'en', alert_param: null};

	componentDidMount()
	{
		let prefered_columns = {};
		prefered_columns = {'subject': 'Subject', 'email_text' : 'Email Text', 'active' : 'Active'}
		this.setState({ general: this.props.general,prefered_columns: prefered_columns});
		this.callLanguage('en')
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			let prefered_columns = {};
			prefered_columns = {'subject': 'Subject', 'email_text' : 'Email Text', 'active' : 'Active'}
			this.setState({general: this.props.general,prefered_columns: prefered_columns});
			this.callLanguage('en')
		}
	}

	callLanguage = (active_lang) => {
	    let postData = { command: "get_select_emails" };
	    postData['module_id'] = 'attest';
	    postData['language_id'] = active_lang;
	    postData['type'] = 'emailtext';
	    let api = new APICall();
	    api.command(postData, this.processEmail);
	    this.setState({active_lang: active_lang})
	}

	processEmail = (result) => {
	    let emails = result.result;
	     console.log("email_data", emails)
	    let email_data = []
	    let variables = {}
	    for(let k in emails){
	        if(emails[k].string_id === "attestation_variables") {
	            variables = emails[k];
	        }else{
	            email_data.push(emails[k])
	        }
	    }
	    this.setState({email: email_data, variables: variables, ready: true})
	    // console.log("email_data", email_data, variables)

	}

	showEmailConfigModal = (email_id) => (event) => {
	    event.preventDefault();
	    console.log("email_id:", email_id);
	    const content = this.state.email.find((item) => {
	        return item._id === email_id;
	    })
	    console.log("email_id:", email_id);
	    this.setState({content, show_email_config_modal: true});
	}

	modifyEmails = (content) => {
		console.log("content:", content);
		let {email} = this.state;
		let newemail = [];
		let e = {};
		email.forEach((item) => {
		    if (item._id === content._id) {
		        item = content;
		        console.log("matched", item);
		        e = item;
		    }
		    newemail.push(item);
		})
		console.log("emails:", newemail);
		let postData = {command: "update_email", data: e};
		let api = new APICall();
		api.command(postData, this.afterEmailUpdate(newemail));
		// this.setState({email: newemail});
	}

	closeModal = () => {
		this.setState({show_email_config_modal: null, content: null});
	}

	tableData = () => {
	    let ret = {data: [], columns: []};
	    ret.columns =[
	                    {Header: 'Email', accessor: 'email', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
	                    {Header: 'Subject', accessor: 'subject', minWidth: 240, headerStyle: {textAlign: 'left'}},
	                    {'Header' : 'Enabled', accessor: 'cslenabled', Cell: row => (
	                        <div>
	                               {
	                                   (()=> {
	                                       if (row.original.enabled) {
	                                           return(<div style={{cursor: "pointer", fontSize:"21px", float: "right"}} onClick={this.handlenaSectionExcluded(row.original.id)}><IoIosCheckboxOutline /></div>);
	                                       } else {
	                                           return(<div  style={{cursor: "pointer", fontSize:"21px", float: "right"}} onClick={this.handlenaSectionIncluded(row.original.id)}><IoIosSquareOutline /></div>);
	                                       }
	                                   })()
	                               }
	                        </div>
	                     ), headerStyle:{textAlign: 'center'}},
	                    {'Header' : 'Action', Cell: row => (
	                       <div>
	                           <ConfigBtn onClick={this.showEmailConfigModal(row.original.id)}>Configure</ConfigBtn>
	                       </div>
	                    ), headerStyle:{textAlign: 'left'}}];
	    this.state.email.forEach((item) => {
	        let elem = {id: item._id, email: item.string_value.name,email_text: item.string_value.name, subject: item.string_value.subject, enabled: item.string_value.enabled, active: item.string_value.enabled === true ? "Yes": "No"};
	        ret.data.push(elem);
	    })
	    return ret;
	}

	handlenaSectionExcluded = (section_id) => (event) => {
        event.preventDefault()
		console.log("Sourav")
        let alert_param = {title: 'Warning', message: 'This will disable the email in all languages. Please confirm to proceed.', confirm: true, alertHandler: this.disableallEmail, stack:{id:section_id}}
        this.setState({ alert_param: alert_param });    
    }

	disableallEmail = (result, stack) => {
        if(!result || stack.prevent) {
            this.setState({alert_param: null})
            return
        }
        let {email} = this.state;
        let newemail = [];
        let e = {}
        email.forEach((item) => {
            if (item._id === stack.id) {
                item.string_value.enabled = item.string_value.enabled ? false : true; 
                // console.log("matched", item);
                 e = item;
            }
            newemail.push(item);
        })
        console.log("update", e)
        let postData = {command: "update_all_email", data: e};
        let api = new APICall();
        api.command(postData, this.afterDisabled);
        this.setState({email: newemail})
    }

	afterDisabled = (result) => {
		let lp = Store.getStoreData('language_pack')
        if (result.error_code === 0) {
            let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'This email has been disabled for all languages', ok_text: 'Ok', confirm: false,alertHandler: this.alertHandler, stack: { id: 0 }}
            this.setState({ alert_param: alert_param });
        } else {
            let alert_param = {title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'], message: result.error_msg, ok_text: 'Ok', confirm: false,alertHandler: this.alertHandler, stack: { id: 0 }}
            this.setState({alert_param: alert_param });
        }
    }

	alertHandler = (result, stack) => {
        this.setState({alert_param: null, show_email_config_modal: null})
        return
    }

	handlenaSectionIncluded = (section_id) => (event) => {
	    console.log("content:", section_id);
	    let {email} = this.state;
	    let newemail = [];
	    let e = {}
	    email.forEach((item) => {
	        if (item._id === section_id) {
	            item.string_value.enabled = item.string_value.enabled ? false : true; 
	            console.log("matched", item);
	             e = item;
	        }
	        newemail.push(item);
	    })
	    // console.log("emails:", e);
	    let postData = {command: "update_email", data: e};
	    let api = new APICall();
	    api.command(postData, this.afterEmailUpdate(newemail));
	    //this.setState({email: newemail})
	}

	afterEmailUpdate = (newemail) => (result) => {
		console.log("emails:", result);		
		this.setState({alert_param: null, show_email_config_modal: null, email: newemail})
		return
	    if (result.error_code === 0) {
	        let alert_param = {title: 'Success', message: 'Your Email text has been saved', ok_text: 'Ok', confirm: false,alertHandler: this.alertHandler, stack: { id: 0 }}
	        this.setState({ alert_param: alert_param });
	    } else {
	        let alert_param = {title: 'Error', message: result.error_msg, ok_text: 'Ok', confirm: false,alertHandler: this.alertHandler, stack: { id: 0 }}
	        this.setState({alert_param: alert_param });
	    }
	}


	changeLanguage = (e)=>{
	    console.log("e lang", e, this.state.module)
	    // this.setState({ active_lang:e })
	    this.callLanguage(e)
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneral(general);
	}

	performAction = (entity_id, action_type) => {
        switch (action_type) {            
            case 'export':
            this.exportToExcel()
                break;
        }
    }

	exportToExcel = () => {		
        let postData = {command: "get_allemails",module: 'attest'}; 
        let langs = Store.getStoreData('languages');
        let lnng = []
        for(let l in langs){
          lnng.push(langs[l].language_id)
        }
        postData['langs'] = lnng;
        postData['variables'] = 'attestation_variables';
        let api = new APICall();
        api.command(postData, this.getAllEmails);
    }

	getAllEmails = (result) => {
        let wb = { SheetNames: [], Sheets: {} };
        let langs = Store.getStoreData('languages');
        let emails = result.emails
        for(let l in langs){
          wb.SheetNames.push(langs[l].language_name);
          let result=[]
          for(let e of emails[langs[l].language_id]) {
            let elem = {
              "Email title": e.name,
              "Email subject": e.subject,
              "Email body text": e.body,
              "Enabled flag": e.enabled ? "Yes" : "No"}
              result.push(elem);
          }
          wb.Sheets[langs[l].language_name] = XLSX.utils.json_to_sheet(result);
        }
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'Email text' + '-' + tm + fileExtension);
    }

	render()
	{
		if (this.state.email === null) {
			return (
				<div>Loading...</div>
			);
		}
		return (
			<div>
			{
				(() => {
					if (this.state.show_email_config_modal !== null) {
						return (<EmailConfigModal variables={this.state.variables} content={JSON.parse(JSON.stringify(this.state.content))} modifyEmails={this.modifyEmails} closeModal={this.closeModal} />);
					}
				})()
			}
			<EmailContainer>
				<EmailInnerContainer>
					<EmailBoldLabel style={{display: "inline-block"}}>Enable Email Reminder?</EmailBoldLabel>
					{
						(() => {
							if (this.state.general.email_remainder_enable) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('email_remainder_enable')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('email_remainder_enable')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
				</EmailInnerContainer>
			</EmailContainer>
			<div styled={{clear:"both"}}></div>
			{
				(() => {
					if (this.state.general.email_remainder_enable) {
						return(
								<EmailContainer>
									<EmailInnerContainer>
										<EmailBoldLabel style={{display: "inline-block"}}>Emails</EmailBoldLabel>										
										<div style={{ width: '95%', display: 'flex' }}>
										    <LanguageTopHeader changeLanguage={this.changeLanguage} active_lang={this.state.active_lang} />
											<div style={{ float: 'right', width: '10%', position: 'relative' }}>
												<SimpleDropdown home={false} show={this.state.show_menu} entity_id={0} actions={this.state.active_lang === 'en' ? action_default : actions} performAction={this.performAction} active_lang={this.state.active_lang} />
											</div>
										</div>
										<CSLTable add={false} export={true} export_file_name={"Emails"} prefered_columns = {this.state.prefered_columns} processData={this.tableData} headerText={''} tableRows={5} refreshCallback={this.refreshState} />
									</EmailInnerContainer>
								</EmailContainer>
						)							
					}
				})()
			}
			{
                (() => {
                    if (this.state.alert_param !== null) {
                        return <AlertBox alertParam={this.state.alert_param} />
                    }
                })()
            }
			</div>
		);
	}
}

export default LPemail;