import React from 'react';
import {FaTimes, FaTrashAlt, FaQuestionCircle, FaPlusCircle} from 'react-icons/fa';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
import AddMoreUserModal from './AddMoreUserModal';
import AlertBox from './../Common/AlertBox';
import { IoIosArrowUp, IoIosSquareOutline, IoIosWarning } from "react-icons/io";


const EmailContainer = styled.div`
	width: 70%;
	position: absolute;
	background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1001;
	padding: 20px;
`;
const EmailInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const EmailTextArea = styled.textarea`
    height: 150px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-family: 'Montserrat', sans-serif;
`;
const VariablePill = styled.div`
	padding: 10px 15px;
	background-color: #ffffff;
	color: #222222;
	font-weight: 600;
	border: 1px solid #222222;
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 10px;
	border-radius: 2em;
	cursor: pointer;
`;
const CancelBtn = styled.button`
    padding: 10px 30px;
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 2px;
	color: #595959;
	cursor: pointer;	
`;
const SubmitBtn = styled.button`
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 15px 24px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
    font-weight: 600;
    font-size: 17px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 70%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #727272;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`; 
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto; 
    height: 20px;       
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

class DistributionConfigModal extends React.Component
{
    state = {ready: false,distribution_name: "",distribution_item: "",select: true,distributionid: null,gc_company: null,cur_gc_id:null, assingnedUserid: {},
    rolename: "",selectUseridarray: [],showCSLTable: false,rowchecked: false,sendContactDistribution: {},isUserAddButton: false, unselectedUser: [], show_unselectedusers_modal: false,
    selectUseridAllArray: [], alert_param: null};

	componentDidMount()
	{        
        if(this.props.curid !== 0) {
            console.log("content==>",this.props.content)
            let gc_company = Store.getStoreData('gc_company')
            let distribution_item = this.props.content.distributionItem 
            if(distribution_item === 'company') {
                let gc_assoc = {}
                for(let gc of gc_company) {
                    if(gc.is_gc)gc_assoc[gc.id] = 1
                }
                if(!(this.props.content.curgcid.toString() in gc_assoc)) {
                    distribution_item = 'allcontacts'
                }
            }  
            let rowchecked = true;
            // if(Object.keys(this.props.content.assingnedUserID).length !== 0 && Object.keys(this.props.content.assingnedUserID).length === this.props.content.selectUseridarray.length){
            //     rowchecked = true;
            // }
            //new implement start
            let selectUseridarray = [];

            let roleUser = []
            if('company_users' in this.props.permissions){
                roleUser = this.props.permissions.company_users;
            }
            let com_roles = Store.getStoreData('userroles');
            console.log("com_roles==>", com_roles, "roleUser==>", roleUser)
            let selectUseridarray_save = this.props.content.selectUseridarray;
            let selectUseridarray_save_object = {};
            selectUseridarray_save.forEach((user) => {
                if (user.user_id in com_roles && com_roles[user.user_id.toString()] !== 'no_access'){ 
                    selectUseridarray_save_object[user.user_id.toString()] = 1;
                    selectUseridarray.push(user)
                }
            })
            let activeUserID = {};
            if(this.props.content.distributionItem === "allcontacts"){
                roleUser.forEach((user) => {     
                    activeUserID[user.user_id.toString()] = 1;
                    if (user.user_id in com_roles && com_roles[user.user_id.toString()] !== 'no_access' && !(user.user_id in selectUseridarray_save_object)) {           
                        user.select = false;
                        selectUseridarray.push(user);                           
                    }         
                })
            }else if(this.props.content.distributionItem === "company"){
                let gc_company = Store.getStoreData('gc_company'); 
                let companyUsers = {};
                for(let gc of gc_company) {            
                    if(parseInt(gc.id) === parseInt(this.props.content.curgcid)){
                        companyUsers = gc.users; 
                    }
                }
                roleUser.forEach((user) => {
                    activeUserID[user.user_id.toString()] = 1;
                    if (user.user_id in companyUsers) {
                        if (com_roles[user.user_id.toString()] !== 'no_access' && !(user.user_id in selectUseridarray_save_object)) {   
                            user.select = false;
                            selectUseridarray.push(user);                            
                        }
                    }
                })
            }else if(this.props.content.distributionItem === "role"){
                roleUser.forEach((user) => {     
                    activeUserID[user.user_id.toString()] = 1;
                    if (this.props.content.rolename === user.role && user.user_id in com_roles && com_roles[user.user_id.toString()] !== 'no_access' && !(user.user_id in selectUseridarray_save_object)) {           
                        user.select = false;
                        selectUseridarray.push(user);                           
                    }                            
                })
            }
            let assign_userID = {};
            selectUseridarray.forEach((user) => {  
                if(user.select) {
                    assign_userID[user.user_id.toString()] = 1 
                }  
                // if (!user.select) {           
                //     rowchecked = false;   
                // }         
            })
            console.log("selectUseridarray==>", selectUseridarray)
            let selectUseridarray_update = [];
            let unselectedUser = [];
            for(let k of selectUseridarray){
                if(k.user_id in activeUserID){
                    if(k.select){
                        selectUseridarray_update.push(k)
                    }else{
                        unselectedUser.push(k)
                    }
                }
            }
            let isUserAddButton = false;
            if(selectUseridarray.length !== selectUseridarray_update.length){
                isUserAddButton = true;
            }
            //new implement end
            selectUseridarray_update.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 

            let update_gc = [];
            for(let d of gc_company){
                if(d.is_active === 0){
                    d.company_name = d.company_name+' (Inactive)'
                }
                update_gc.push(d)
            }

            this.setState({ready:true,distributionid: this.props.content.distributionID,gc_company: update_gc,cur_gc_id: this.props.content.curgcid,
            distribution_name: this.props.content.distributionName, distribution_item: distribution_item,select: this.props.content.select, rolename: this.props.content.rolename,
            showCSLTable: true, selectUseridarray: selectUseridarray_update, sendContactDistribution: assign_userID, rowchecked, isUserAddButton, unselectedUser, selectUseridAllArray: selectUseridarray})      
            
        }else{
            
            let cur_gc_id = 0; 
            console.log("Sourav=====>",cur_gc_id);
            let randNo = Math.floor(Math.random()*(999-100+1)+100);

            let roleUser = []
            if('company_users' in this.props.permissions){
                roleUser = this.props.permissions.company_users;
            }
            let selectUseridarray = [];
            let com_roles = Store.getStoreData('userroles');
            console.log("roleUser==>", roleUser,"com_roles==>", com_roles)
            roleUser.forEach((user) => {     
                if (user.user_id in com_roles && com_roles[user.user_id.toString()] !== 'no_access' ) {           
                    user.select = true;
                    selectUseridarray.push(user);
                    this.state.sendContactDistribution[user.user_id]=1;       
                }         
            })

            selectUseridarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );

            let gc_company = Store.getStoreData('gc_company')
            let update_gc = [];
            for(let d of gc_company){
                if(d.is_active === 1){
                    update_gc.push(d)
                }
            }

            this.setState({ready:true,distributionid: randNo, gc_company: update_gc, cur_gc_id: cur_gc_id, 
            distribution_item: "allcontacts", selectUseridarray: selectUseridarray,showCSLTable: true,rowchecked: true,sendContactDistribution: this.state.sendContactDistribution})
        }
	}

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
    }

    handleChangeText = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }
    
    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value,selectUseridarray: [],showCSLTable: false,rowchecked: false,sendContactDistribution: {},cur_gc_id: 0,rolename: "", isUserAddButton: false});
        if(event.target.value === 'allcontacts'){
            let roleUser = []
            if('company_users' in this.props.permissions){
                roleUser = this.props.permissions.company_users;
            }
            let selectUseridarray = [];
            let com_roles = Store.getStoreData('userroles');
            roleUser.forEach((user) => { 
                if (com_roles[user.user_id.toString()] !== 'no_access' ) {                     
                    user.select = true;
                    selectUseridarray.push(user);
                    this.state.sendContactDistribution[user.user_id]=1;  
                }              
            })
            selectUseridarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            this.setState({selectUseridarray: selectUseridarray,showCSLTable: true,rowchecked: true,sendContactDistribution: this.state.sendContactDistribution, isUserAddButton: false});
        }
        
    }  

    handleChangeCurGc = (evt) => {  
        this.state.sendContactDistribution = {}      
        let gc_company = Store.getStoreData('gc_company');        
        console.log("gc_company", gc_company);
        let companyUsers = {};
        for(let gc of gc_company) {            
            if(parseInt(gc.id) === parseInt(evt.target.value)){
                companyUsers = gc.users; 
            }
        }
        console.log("gc_company", companyUsers); 
        let roleUser = []
        if('company_users' in this.props.permissions){
            roleUser = this.props.permissions.company_users;
        }
        let selectUseridarray = [];
        let com_roles = Store.getStoreData('userroles');
        console.log("roleUser", roleUser); 
        roleUser.forEach((user) => {
            if (user.user_id in companyUsers) {
                if (com_roles[user.user_id.toString()] !== 'no_access' ) {   
                    user.select = true;
                    selectUseridarray.push(user);
                    this.state.sendContactDistribution[user.user_id]=1;
                }
            }
        })
        let showCSLTable = true;
        if(selectUseridarray.length === 0){
            this.state.sendContactDistribution = {}
            showCSLTable = false;
        }
        console.log("this.state.permissions.roles",selectUseridarray)
        console.log("sendContactDistribution",this.state.sendContactDistribution)    
        selectUseridarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );   
        this.setState({ cur_gc_id: evt.target.value, selectUseridarray: selectUseridarray, selectUseridAllArray: selectUseridarray, showCSLTable: showCSLTable,rowchecked: true,sendContactDistribution: this.state.sendContactDistribution});
    }

    handleChangeRole = (event) => {
        this.state.sendContactDistribution = {}
        let roleUser = []
        if('company_users' in this.props.permissions){
            roleUser = this.props.permissions.company_users;
        }
        let selectUseridarray = [];
        roleUser.forEach((user) => {
            if (user.role === event.target.value.toString()) {
                user.select = true;
                selectUseridarray.push(user);
                this.state.sendContactDistribution[user.user_id]=1;
            }
        })
        let showCSLTable = true;
        if(selectUseridarray.length === 0){
            this.state.sendContactDistribution = {}
            showCSLTable = false;
        }
        console.log("this.state.permissions.roles",selectUseridarray)
        console.log("sendContactDistribution",this.state.sendContactDistribution) 
        selectUseridarray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
        this.setState({[event.target.name]: event.target.value, selectUseridarray: selectUseridarray,selectUseridAllArray: selectUseridarray,showCSLTable: showCSLTable,rowchecked: true,sendContactDistribution: this.state.sendContactDistribution});
    }

    selectContact = (event) => {        
        let index = event.target.value;        
        var contactListAll = this.state.selectUseridarray;        
            
        if(contactListAll[index].select){
            contactListAll[index].select = false;            
            delete this.state.sendContactDistribution[contactListAll[index].user_id.toString()];                    
        } else {
            contactListAll[index].select = true;            
            let userid = contactListAll[index].user_id.toString();
            let val = 1;            
            this.state.sendContactDistribution[userid] = val;                            
        }
        contactListAll.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
        this.setState({selectUseridarray: contactListAll, sendContactDistribution: this.state.sendContactDistribution,rowchecked: false})
        let allselectcheck = true
        for(let d of contactListAll) {
            // console.log("select",d.select);
            if(!d.select){
                allselectcheck = false
            }
        }
        if(allselectcheck){
            this.setState({rowchecked: true})
        }
    }

    selectContactAll = (event) => {
        let rowchecked = this.state.rowchecked;
        rowchecked = rowchecked ? false : true;        
        let contactListAll = this.state.selectUseridarray;         
        
       if(rowchecked) {   
            let i = 0;            
            let selectArray = [];
            for(let d of contactListAll) {                
                let selectObject = {}           
                i++;   
                let val = 1;            
                this.state.sendContactDistribution[d.user_id] = val;                          
                selectObject.user_id = d.user_id;
                selectObject.company_id = d.company_id;
                selectObject.email = d.email;
                selectObject.name = d.name; 
                selectObject.role = d.role;                
                selectObject.select = true;                                
                selectArray.push(selectObject)
            }
            selectArray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
            this.setState({rowchecked: rowchecked,selectUseridarray: selectArray, sendContactDistribution: this.state.sendContactDistribution})
       }else{
            let i = 0;            
            let selectArray = [];
            for(let d of contactListAll) {                
                let selectObject = {}           
                i++; 
                delete this.state.sendContactDistribution[d.user_id.toString()];                            
                selectObject.user_id = d.user_id;
                selectObject.company_id = d.company_id;
                selectObject.email = d.email;
                selectObject.name = d.name; 
                selectObject.role = d.role;                
                selectObject.select = false;                                
                selectArray.push(selectObject)
            }
            selectArray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            this.setState({rowchecked: rowchecked,selectUseridarray: selectArray, sendContactDistribution: this.state.sendContactDistribution})
       }
   }

    processMyContactListData = () => {            
        var contactList = this.state.selectUseridarray;      
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{'Header' : <div><input type="checkbox" checked={this.state.rowchecked} onClick={this.selectContactAll}/></div>,sortable: false, Cell: row => (
                        <div>
                            <input type="checkbox" checked={row.original.select} value={row.index} onClick={this.selectContact}/>
                        </div>
                    ), width: 142, headerStyle: {textAlign: 'left'}},         
                    {Header: 'Contact', accessor: 'name_value', minWidth: 100, headerStyle: {textAlign: 'center'}}];         
                      
        for(let d of contactList) {           
            i++;
            let elem = {'index' : i,
                    'id': d.user_id, 
                    'name_value' : d.name,
                    'select' : d.select                    
                    };
            ret.data.push(elem);
        
        }
        
        return ret;
    }

    submitDistributionList = (event) => {
        event.preventDefault();
        let problems = false
        let message = '';
        let lp = Store.getStoreData('language_pack')
        if(this.state.distribution_name === ""){
            //alert("Please insert a distribution name.");
            message += 'Please insert a distribution name.';
            problems = true;          
        }else if(Object.keys(this.state.sendContactDistribution).length === 0){
            //alert("Please chose the distribution item & Select contact(s) you want to include this distribution list.");     
            message += 'Please chose the distribution item & Select contact(s) you want to include this distribution list.';
            problems = true;
        }
        if(problems){
            let alert_param = {title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'], message: message, ok_text: 'OK', confirm: false, 
                alertHandler: this.aftercheckvalidationAll, stack: {}}
            this.setState({alert_param: alert_param})      
        }
        else{

            let roleUser = []
            if('company_users' in this.props.permissions){
                roleUser = this.props.permissions.company_users;
            }
            let selectUserList = [];            
            roleUser.forEach((user) => {     
                if (user.user_id in this.state.sendContactDistribution) {  
                    let selectObject = {};
                    selectObject.user_id = user.user_id;
                    selectObject.role = user.role;
                    selectObject.name = user.name;
                    selectObject.company_id = user.company_id;       
                    selectUserList.push(selectObject);   
                }   
            })
            let selectUserListnew = [];
            let userEmail = Store.getStoreData('contactsemail');
            selectUserList.forEach((user) => {     
                if (user.user_id in userEmail) {  
                    user.email = userEmail[user.user_id];       
                    selectUserListnew.push(user);   
                }   
            })

            let selectUseridarrayUpdate = this.state.selectUseridarray;
            if(this.props.curid !== 0) {
                let userArray = [];
                this.state.selectUseridAllArray.forEach((item) => {
                    if (item.user_id in this.state.sendContactDistribution) {
                        item.select = true;                        
                    }else{
                        item.select = false;
                    }
                    userArray.push(item)
                })
                selectUseridarrayUpdate = userArray;
            }

            console.log("selectUseridarrayUpdate==>",selectUseridarrayUpdate)
            let distributionObject = {}
            distributionObject.distributionID = this.state.distributionid;
            distributionObject.distributionName = this.state.distribution_name;
            distributionObject.curgcid = this.state.cur_gc_id;
            distributionObject.rolename = this.state.rolename;
            distributionObject.select = this.state.select;
            distributionObject.distributionItem = this.state.distribution_item;
            distributionObject.assingnedUserID = this.state.sendContactDistribution;
            distributionObject.distributionGroupUserID = selectUserListnew;
            // distributionObject.selectUseridarray = this.state.selectUseridarray;
            distributionObject.selectUseridarray = selectUseridarrayUpdate;
            console.log("resonObject==>",distributionObject)
            if(this.props.curid !== 0) {
                this.props.updateDistributionValue(distributionObject);
            }else{                
                this.props.saveDistributionValue(distributionObject);
            }
        }
        
    }
    aftercheckvalidationAll = (result, stack) => {  
        console.log(result,stack)
        this.setState({alert_param: null})        
    }

    addUnselectedUser = (event) => {
		event.preventDefault();
		this.setState({show_unselectedusers_modal: true})
	}

    closeUserModal = () => {		
		this.setState({show_unselectedusers_modal: false})
	}

    updatedSelectedUsers = (selectUserArray, selectUserObject) => {		
        let selectUseridarray =  this.state.selectUseridarray;
        for(let d of selectUserArray){
            selectUseridarray.push(d);
        }
        let sendContactDistribution = this.state.sendContactDistribution;
        for(let k in selectUserObject){
            sendContactDistribution[k.toString()] = selectUserObject[k.toString];
        }
        let unselectedUserNew = [];
        for(let k of this.state.unselectedUser){
            if(!(k.user_id in selectUserObject)){                
                unselectedUserNew.push(k)
            }
        }
        console.log("selectUseridarray==>", selectUseridarray)
        console.log("unselectedUser==>", unselectedUserNew)
		this.setState({selectUseridarray, sendContactDistribution, unselectedUser: unselectedUserNew, show_unselectedusers_modal: false})
	}
	

	render()
	{
		if (!this.state.ready) {
			return (<div>Loading...</div>);
		}
        let tableBannerMessage = 'Select contact(s) you want to include in this distribution list';
        if(this.props.curid !== 0 && this.state.isUserAddButton) {
            tableBannerMessage = 'Contacts included within this distribution list, click + to add new staff'
        }

		return (
            <div>
                {
					(() => {
						if (this.state.show_unselectedusers_modal) {
							return (<AddMoreUserModal unselectedUser={JSON.parse(JSON.stringify(this.state.unselectedUser))} closeModal={this.closeUserModal} updatedSelectedUsers={this.updatedSelectedUsers}/>);
						}
					})()
				}
			<EmailContainer>
                <MRModalHeader>
                    <MRModalHeaderText>Distribution List</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>                
                </MRModalHeader>            
                <MRModalBody>
                    <MRModalLabel style={{display: "inline-block"}}>Name of the Distribution</MRModalLabel><span style={{marginLeft: "5px",color: "red"}}>*</span>
                    <MRModalInput name="distribution_name" value={this.state.distribution_name} onChange={this.handleChangeText}/>
                               
                    <MRModalLabel style={{marginTop: "30px",display: "inline-block"}}>Distribution Item</MRModalLabel><span style={{marginLeft: "5px",color: "red"}}>*</span>
                    <MRModalSelect onChange={this.handleChange} name="distribution_item" value={this.state.distribution_item}>
                        {/* <option  value="">Select</option> */}
                        {/* <option  value="group">Group</option>
                        <option  value="team">Team</option>  */}
                        <option  value="role">Role</option>
                        <option  value="company">Company</option> 
                        <option  value="allcontacts">View All Contacts</option>                        
                    </MRModalSelect>
                    {
                        (() => {
                            if(this.state.distribution_item === "company"){
                                return(<div>
                                    <MRModalLabel style={{marginTop: "30px",display: "inline-block"}}>Select Company</MRModalLabel><span style={{marginLeft: "5px",color: "red"}}>*</span>
                                    <MRModalSelect name="cur_gc_id" value={this.state.cur_gc_id} onChange={this.handleChangeCurGc}>
                                    <option key={0} value={0}>Select</option>
                                        {
                                                this.state.gc_company.map((gc) => {
                                                if(gc.is_gc)return(
                                                                <option key={gc.id} value={gc.id}>{gc.company_name}</option>
                                                                );
                                            })
                                        }
                                    </MRModalSelect>
                                </div>)
                            }
                        })()
                    }
                    {
                        (() => {
                            if(this.state.distribution_item === "role"){
                                return(<div>
                                    <MRModalLabel style={{marginTop: "30px",display: "inline-block"}}>Select Role</MRModalLabel><span style={{marginLeft: "5px",color: "red"}}>*</span>
                                    <MRModalSelect name="rolename" value={this.state.rolename} onChange={this.handleChangeRole}>                                    
                                        <option  value="">Select</option>
                                        <option  value="manager">Admin Manager</option>
                                        <option  value="team">Team</option> 
                                        <option  value="am">Attestation Manager</option>      
                                    </MRModalSelect>
                                </div>)
                            }
                        })()
                    }
                    {
                        (() => {
                            if(this.state.showCSLTable){
                                return(
                                    <div style={{marginTop: "20px"}}>
                                    <OverviewInfoHeader>
                                        <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{tableBannerMessage}</div> 
                                        {
                                            (() => {
                                                if(this.state.isUserAddButton){
                                                    return(<div style={{cursor: "pointer",float: "right", color: "#ffffff", fontSize: "17px"}} onClick={this.addUnselectedUser}>
                                                    <FaPlusCircle />
                                                </div>)
                                                }
                                            })()
                                        }                                        
                                        <div style={{clear: "both"}}></div>
                                    </OverviewInfoHeader>
                                    <OverviewInfoBody>
                                        {
                                            (() => {
                                                if(this.state.selectUseridarray.length <= 10){
                                                    return(<CSLTable add={false} processData={this.processMyContactListData} headerText={''} tableRows={10}/>)
                                                }else{
                                                    return(<CSLTable add={false} processData={this.processMyContactListData} headerText={''} tableRows={20}/>)
                                                }
                                            })()
                                        }
                                        
                                    </OverviewInfoBody>
                                    </div>
                                )
                            }
                        })()
                    }
                    <div style={{float: "right",marginTop: "20px"}}>
                        <CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
                        <SaveBtn onClick={this.submitDistributionList}>Submit</SaveBtn>
                    </div>
                </MRModalBody>                
                <div style={{clear: "both"}}></div> 
			</EmailContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
		);
	}
}

export default DistributionConfigModal;