import React from "react";
import styled from "styled-components";
import Store from "../../Common/Store.js";
import AMAttestationList from "./AMAttestationList";
import AMAskAttestationModal from "./AMAskAttestationModal";
import APICall from "../../Common/APICall";

const SubmitButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #223b5a;
  color: #ffffff;
  width: 160px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #37ada7;
  margin-left: 12px;
  font-size: 11px;
  padding-left: 12px;
  cursor: pointer;
  margin-top: 26px;
`;

const IndexContainer = styled.div`
  padding-top: 8px;
  padding-left: 5px;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;

class AMIndex extends React.Component {
  state = {
    isLoaded: false,
    pending_tasks: [],
    attestationTaskList: [],
    showModal: null,
    ready: false,
  };
  constructor(props) {
    super(props);
  }

  refreshCallback = () => {
    this.props.refreshCallback();
  };

  componentDidMount() {
    let myattestationTask = Store.getStoreData("mytasks");
    this.setState({ attestationTaskList: myattestationTask, ready: true });
  }
  componentDidUpdate(prevprops) {
    if (this.props === prevprops) return;
    let myattestationTask = Store.getStoreData("mytasks");
    this.setState({ attestationTaskList: myattestationTask, ready: true });
  }

  showAttestationModal = (row) => (event) => {
    event.preventDefault();
    let index = row.index === null ? 0 : row.index;
    // console.log('row in showAskQuestionModal', row);
    // console.log('index in showAskQuestionModal', index);
    let q_index = index;
    Store.updateStore("q_index", q_index);
    this.setState({ showModal: "AMAskAttestationModal" });
  };

  closeModal = (event) => {
    this.setState({ showModal: null });
  };

  closeModalUpdate = (event) => {
    this.setState({ showModal: null });
    let postData = { command: "refreshTaskAllAM" };
    let api = new APICall();
    api.command(postData, this.processTaskrefresh);
  };

  processTaskrefresh = (result) => {
    // console.log("result==>",result)
    Store.updateStore("mytasks", result.result["my_tasks"]);
    Store.updateStore("mypendingtask", result.result["pending_task"]);
    Store.updateStore("mycompletetask", result.result["completed_task"]);
    Store.updateStore("followupcount", result.result["followupCount"]);
    Store.updateStore("followuptask", result.result["followup_tasks"]);

    let flagedObject = {};
    let flagedValue = result.result["flaged_task"];
    for (let d of flagedValue) {
      if (d[1] === true) {
        flagedObject[d[0]] = true;
      }
    }

    let ChildTasksObject = {};
    let childTasksValue = result.result["child_share_task"];
    Store.updateStore("child_share_task_all", childTasksValue);
    let contacts_all = Store.getStoreData('contacts'); 
    for (let d of childTasksValue) {
        if(d[9] in contacts_all){
            let data = {
                contact_id: d[2],
                cur_lane: d[3],
                acknowledgementDueDate: d[4],
                dueDate: d[5],
                Type: d[6],
                parent_task_id: d[7],
            };
            ChildTasksObject[d[0]] = data;
        }
      
    }
    Store.updateStore("flagged_count_object", flagedObject);
    Store.updateStore("child_tasks_all", ChildTasksObject);

    let attest_index = Store.getStoreData("attest-index");
    if (
      attest_index !== null &&
      "result" in attest_index &&
      "my_tasks" in attest_index.result
    ) {
      attest_index.result.my_tasks = result.result["my_tasks"];
    }
    if (
      attest_index !== null &&
      "result" in attest_index &&
      "pending_task" in attest_index.result
    ) {
      attest_index.result.pending_task = result.result["pending_task"];
    }
    if (
      attest_index !== null &&
      "result" in attest_index &&
      "completed_task" in attest_index.result
    ) {
      attest_index.result.completed_task = result.result["completed_task"];
    }
    if (
      attest_index !== null &&
      "result" in attest_index &&
      "followupCount" in attest_index.result
    ) {
      attest_index.result.followupCount = result.result["followupCount"];
    }
    if (
      attest_index !== null &&
      "result" in attest_index &&
      "followup_tasks" in attest_index.result
    ) {
      attest_index.result.followup_tasks = result.result["followup_tasks"];
    }
    if (
      attest_index !== null &&
      "result" in attest_index &&
      "flaged_task" in attest_index.result
    ) {
      attest_index.result.flaged_task = result.result["flaged_task"];
    }
    if (
      attest_index !== null &&
      "result" in attest_index &&
      "child_share_task" in attest_index.result
    ) {
      attest_index.result.child_share_task = result.result["child_share_task"];
    }

    this.setState({
      attestationTaskList: result.result["my_tasks"],
      ready: true,
    });
  };

  render() {
    let task_id = this.props.taskId !== 0 ? this.props.taskId : 0;
    let lp = Store.getStoreData('language_pack')
    // console.log("task_id2===>",task_id)
    if (!this.state.ready) {
      return <div>Loading...</div>;
    }
    return (
      <IndexContainer>
        {(() => {
          if (task_id === 0) {
            return (
              <SubmitButton id="new_attest" onClick={this.showAttestationModal({ index: -1 })}>
                {lp['add_new_attestation_button'] === undefined ? 'New Attestation' : lp['add_new_attestation_button']}
              </SubmitButton>
            );
          }
        })()}
        <AMAttestationList
          add={false}
          attestationTaskList={this.state.attestationTaskList}
          taskId={task_id}
          refreshCallback={this.refreshCallback}
          closeModalUpdate={this.closeModalUpdate}
        />
        {(() => {
          if (this.state.showModal !== null) {
            switch (this.state.showModal) {
              case "AMAskAttestationModal":
                return (
                  <div
                    style={{
                      position: "absolute",
                      left: "2%",
                      top: "50px",
                      width: "100%",
                      height: "600px",
                    }}
                  >
                    <InactiveOverlay style={{ height: "400%" }} />
                    <AMAskAttestationModal
                      closeModal={this.closeModal}
                      closeModalUpdate={this.closeModalUpdate}
                    />
                  </div>
                );
                break;
            }
          }
        })()}
      </IndexContainer>
    );
  }
}
export default AMIndex;
