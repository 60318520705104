import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';
import { FaEdit,FaPencilAlt, FaTimes, FaEllipsisV, FaPlus, FaPlusCircle, FaMinusCircle, FaTrashAlt, FaRegTrashAlt, FaSave, FaChevronUp } from "react-icons/fa";
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import APICall from '../../Common/APICall.js';
import Utils from '../../Common/Utils.js';
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox';
import CSLCheckBox from '../Common/CSLCheckBox';
import Reorder, { reorder, reorderFromTo } from 'react-reorder';
import HtmlEditor from '../Common/HtmlEditor';
import Store from '../../Common/Store.js';
import Dropzone from '../Common/DropzoneQuestionset';
import VEllipsisMenuFolder from "./VerticalEllipsisMenuFolder";
import CSLToggle from './../Common/CSLToggle';
import { Container, Draggable } from 'react-smooth-dnd';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import moment from "moment";
import XcelLoader from "./../Common/XcelLoader.js";

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const ModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;
const ModalLabelsub = styled.div`    
    margin-bottom: 10px;
    display: block;
    font-weight: 200;
    margin-top: 0px;
    font-size: 90%;
    color: #758597;    
`;
const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 18px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const ModalInput = styled.input`
    height: 25px;
    width: calc(99% - 20px);
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 12px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const QSearchInput = styled.input`
    height: 15px;
    width: calc(99% - 20px);
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 10px;
    font-weight: 400;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 2px;
`;
const SectionNameInput = styled.input`
    height: 18px;
    width: 200px;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #ffffff;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: #ffffff;
    border-radius: 5px;
    padding: 6px;
    background-color: #04ADA8;
    margin-top: -8px;
`;
const ModalTextarea = styled.textarea`
    width: calc(99% - 20px);
    height: 80px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #545454;
	font-family: 'Montserrat', sans-serif;
	font-size: 12px;
    font-weight: 600;
`;

const MRModalDropDown = styled.select`
    display: block;
    box-sizing: border-box;
    height: 36px;
    width: 97%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #222222;
    margin-bottom: 10px;
    padding: 7px;
`;

const AddSection = styled.div`
    background-color: #04ADA8;
    box-sizing: border-box;
    height: 35px;
    width: 99%;
    border: 1px solid #04ADA8;
    border-radius: 6px;
    box-shadow: 0 0 4px #c4c4c4;
    color: white;
    font-weight: bold;
    margin-top: 10px;
    padding-left: 10px;
    padding-top: 8px;
`
const QuestionsSection = styled.div`
    position: relative;
    background-color: #ffffff;
    box-sizing: border-box;
    width: calc(99% - 10px);
    border: 1px solid #04ADA8;
    box-shadow: 0 0 4px #c4c4c4;
    color: #04ADA8;
    font-weight: bold;
    margin-top: 0px;
    padding-left: 5px;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
`

const QuestionLineMenu = styled.div`
    position: absolute;
    right: 20px;
    width: 180px;
    height: 82px;
    margin: 0px;
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 12px;
    font-size: 16px;
    background-color: #F2F2F2;
    box-sizing: border-box;
    border: 1px solid #04ADA8;
    border-radius: 5px;
`

const QuestionLineMenuLine = styled.div`
    width: calc(100% - 20px);
    height: 30px;
    margin: 0px;
    padding-left: 10px;
    padding-top: 2px;
    font-size: 16px;
    cursor: pointer;
    background-color: #F2F2F2;
    &:hover {
      background-color: #04ADA8;
      border-color: #2e948f;
      color: white;
    }
`

const AddButton = styled.div`
    width: 30px;
    height: 30px;
    margin: 0px;
    float: right;
    padding-left: 10px;
    font-size: 16px;
    cursor: pointer;
`
const EditButton = styled.div`
    width: 30px;
    height: auto;
    margin: 0px;
    padding-left: 10px;
    font-size: 13px;
    cursor: pointer;
    float: left;
    margin-left: 5px;
`
const ModalContainer = styled.div`
    position: fixed;
    background-color: #ffffff;
    top: 50px;
    left: calc(50vw - 250px);
    width: 650px;
    box-sizing: border-box;
    height: 400px;
    min-height: 300px;
    box-sizing: border-box;
    background-color: #F3F7FB;
    border: 1px solid #545454;
    border-radius: 5px;
    z-index: 1010;
    opacity: 1.0;
`
const ModalHeader = styled.div`
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 100px;
    min-height: 100px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    border-left: 7px solid #04ADA8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 1020;
    opacity: 1.0;
`

const QuestionLine = styled.div`
    padding: 5px;
    width: calc(100% - 10px);
    height: 30px;
    cursor: grabbing;
`

const QuestionTitle = styled.div`
    font-size: 13px;
    font-weight: 500;
    float: left;
    width: calc(100% - 150px);
`

const ModalBody = styled.div`
    width: 100%;
    padding-left: 15px;
    background-color: #ffffff;
    box-sizing: border-box;
    height: auto;
    min-height: 200px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    z-index: 1020;
    opacity: 1.0;
    overflow-y: auto;
`
const ModalFooter = styled.div`
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 97px;
    min-height: 97px;
    box-sizing: border-box;
    border: 1px solid #F3F7FB;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1020;
    opacity: 1.0;
`

const ModalHeaderTextLarge = styled.div`
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 2px;
    padding-right: 0px;
    width: calc(100% - 60px);
    color: #5D748E;
    font-size: 16px;
    font-weight: 700;
    float: left;
`
const ModalHeaderClose = styled.div`
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 2px;
    padding-left: 0px;
    width: 30px;
    color: #5D748E;
    font-size: 20px;
    font-weight: 700;
    float: right;
    cursor: pointer;
`
const ModalHeaderTextSmall = styled.div`
    padding-left: 10px;
    padding-top: 3px;
    width: calc(100% - 10px);
    color: #5D748E;
    font-size: 12px;
    font-weight: 700;
`

const ClearFloat = styled.div`
    clear: both;
`
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  width: calc(100vw - 300px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 60px;
  z-index: 1000;
  opacity: 0.8;
`;

const SaveButton = styled.button`
    padding: 10px 30px;
    background-color: #37ADA7;
    border: 1px solid #37ADA7;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    margin-right: 25px;
`;

const CancelButton = styled.button`
    padding: 10px 20px;
    background-color: #ffffff;
    border: 1px solid #37ADA7;
    border-radius: 5px;
    color: #333333;
    cursor: pointer;
    margin-right: 5px;
`
const FooterButtonsContainer = styled.div`
    text-align: right;
    padding-top: 25px;
    width: 99%;
`;
const CancelBtn = styled.button`
    padding: 10px 20px;
    background-color: #ffffff;
    border: 1px solid #37ADA7;
    border-radius: 2px;
    color: #333333;
    cursor: pointer;
    margin-right: 5px;
`;
const SaveBtn = styled.button`
    padding: 10px 30px;
    background-color: #37ADA7;
    border: 1px solid #37ADA7;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    margin-left: 8px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: calc(100% - 10px);
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #ffffff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    // width: 92%;
    margin: 0 auto;
    margin-top: 30px;
    border: 1px solid #d7dddf;    
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 92%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

const SerialHeader = styled.div`
  text-align: center;
  flex: 30 0 auto;
  width: 4%;
  max-width: 4%;
  cursor: pointer;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  font-weight: bold;
  height: 35px;
`;

const FieldNameTitleHeader = styled.div`
  text-align: left;
  flex: 100 0 auto;
//   width: 160px;
  min-width: 45%;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
  font-weight: bold;
`;

const FieldTypeTitleHeader = styled.div`
  text-align: left;
  flex: 100 0 auto;
//   width: 140px;
  min-width: 11%;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
  font-weight: bold;
`;
const DisplayForTitleHeader = styled.div`
  text-align: left;
  flex: 100 0 auto;
//   width: 80px;
  min-width: 15%;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
  font-weight: bold;
`;
const RequiredTitleHeader = styled.div`
  text-align: left;
  flex: 100 0 auto;
  width: 30px;
  min-width: 10%;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
  font-weight: bold;
`;

const ActionHeader = styled.div`
  text-align: center;
  flex: 55 0 auto;
//   width: 70px;
	min-width: 5%;
//   max-width: 55px;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  height: 35px;
  font-weight: bold;
`;

const ElipsisHeader = styled.div`
  text-align: center;
  flex: 55 0 auto;
//   width: 20px;
//   max-width: 20px;
  min-width: 2%;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  height: 35px;
  font-weight: bold;
`;

const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };
  
  class AddQSetQuestion extends React.Component {
      state = {
          title: '',
          attestation_required: 0,
          disable_comments: 0,
          allow_na: 0,
          show_default: 0,
          attestation_text: '',
          confirmation_text: '',
          section_id: 0,
          text: '',
          question_index: -1,
          is_active: 1,
          isloaded: false,
          cur_files_json: null,
          binFiles: [],
          binfiles_id: 0,
          qsetbinfilesArray: [],
          isLoadedIcon: false,
          isExitingfilesRemove: false,
          alert_param: null,
          correct_answer: "Yes",
          flagged_opt: 'incorrect',
          req_attach: 'no_attach',
          header_text: 'Add'
      }
  
      setComponentState = () => {
          console.log('this.props.text==>', this.props)
          let qtext = this.props.text
          if(Utils.isBase64(this.props.text)){
              qtext = window.decodeURIComponent(atob(qtext))
          }
          Store.updateStore('question_text', null);
          this.setState({
              title: this.props.title,
              text: qtext,
              show_default: this.props.show_default,
              attestation_required: this.props.attestation_required,
              disable_comments: this.props.disable_comments,
              allow_na: 'allow_na' in this.props ? this.props.allow_na : 0, //new 1
              correct_answer: 'correct_answer' in this.props ? this.props.correct_answer : 'Yes', //new 2
              flagged_opt: 'flagged_opt' in this.props ? this.props.flagged_opt : 'incorrect', //new 3
              req_attach: 'req_attach' in this.props ? this.props.req_attach : 'no_attach', //new 4
              attestation_text: this.props.attestation_text,
              confirmation_text: this.props.confirmation_text,
              section_id: this.props.section_id,
              question_index: this.props.question_index,
              is_active: 'is_active' in this.props ? this.props.is_active : 1,
              binfiles_id: this.props.binfiles_id,
              header_text: 'header_text' in this.props ? this.props.header_text : 'Add'
          })
          if('binfiles_id' in this.props && this.props.binfiles_id !== 0){
              let postData = {command: "fetch_qsetbinfile", bin_fileid: this.props.binfiles_id};
              console.log("postData",postData)            
              let api = new APICall();
              api.command(postData, this.processfetchAttachmentQuestionsets);
          }else{
              this.setState({isloaded: true})
          }
      }
  
      processfetchAttachmentQuestionsets = (result) => {
          console.log("result==>", result)
          this.setState({cur_files_json: result.bin_files,isloaded: true})
      }
  
      componentDidMount()
      {       
          this.setComponentState()
      }
  
      componentDidUpdate(prevProps)
      {
          if(this.props === prevProps)return
          this.setComponentState()
      }
  
      switchRadio = (value) => {
          let show_default = value === 0 ? 0 : this.state.show_default
          this.setState({attestation_required: value})
      }
      switchRadioAllowNA = (value) => {          
          this.setState({allow_na: value})
      }

      handleChangeCorrectAnswer = (evt) => {
        this.setState({correct_answer: evt.target.value})
      }
      handleChangeReqAttach = (evt) => {
        this.setState({req_attach: evt.target.value})
      }
      
      handleChangeFlaggedOption = (evt) => {
        this.setState({flagged_opt: evt.target.value})
      }      
  
      switchShowDefault = (value) => {
          this.setState({show_default: value})
      }
  
      switchIsActive = (value) => {
          this.setState({is_active: value})
      }

      switchDisableComments = (value) => {
          this.setState({disable_comments: value})
      }
  
      changeTitle = (event) => {
          let title = event.target.value
          this.setState({title})
      }
      changeText = (event) => {
          let text = event.target.value
          this.setState({text})
      }
  
      changeAttestationText = (event) => {
          let attestation_text = event.target.value
          this.setState({attestation_text})
      }
  
      changeConfirmationText = (event) => {
          let confirmation_text = event.target.value
          this.setState({confirmation_text})
      }
  
  
  
      saveQuestion = () => {
          if(this.state.title.trim() === '') {
              let lp = Store.getStoreData('language_pack')
              //alert('You must specify a title for the Question.')
              let alert_param = {title: lp['error_popup_title'] === undefined ? 'ERROR' : lp['error_popup_title'], message: 'You must specify a title for the Question.', ok_text: 'Ok', confirm: false, 
                                        alertHandler: this.checkalertparam, stack: {}}
              this.setState({alert_param: alert_param}) 
              return
          }
          let qText = Store.getStoreData('question_text');
          if(qText === null){
              qText = this.state.text;
          }
          let qtext_value = btoa(window.encodeURIComponent(qText));          
          //binfiles start
          let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
  
          console.log("bin_files==>", bin_files)
          if(this.state.binfiles_id === 0){
              if(bin_files.length !== 0){
                  let postData = {command: "create_qsetbinfile", bin_files: bin_files};
                  console.log("postData",postData)            
                  let api = new APICall();
                  this.setState({isLoadedIcon: true})
                  api.command(postData, this.processAttachmentQuestionsets(qtext_value));
              }else{
                  this.props.saveQuestion(this.state, qtext_value)
              }
          }else{
              if(bin_files.length !== 0){
                  let postData = {command: "update_qsetbinfile", bin_files: bin_files, bin_fileid: this.state.binfiles_id};
                  console.log("postData",postData)            
                  let api = new APICall();
                  this.setState({isLoadedIcon: true})
                  api.command(postData, this.processupdateAttachmentQuestionsets(qtext_value));
              }else if(this.state.isExitingfilesRemove){
                  let postData = {command: "update_qsetbinfile", bin_files: bin_files, bin_fileid: this.state.binfiles_id};
                  console.log("postData",postData)            
                  let api = new APICall();
                  this.setState({isLoadedIcon: true})
                  api.command(postData, this.processupdateAttachmentQuestionsets(qtext_value));
              }else{
                  this.props.saveQuestion(this.state, qtext_value)
              }
          }        
      }
      checkalertparam = (result, stack) => {
          console.log(result,stack)
          this.setState({alert_param: null})
      }
  
      processupdateAttachmentQuestionsets = (qtext_value) => (result) => {
          console.log("result==>", result) 
          let qsetbinfilesArray = result.qsetbinfilesArray;    
          this.setState({isLoadedIcon: false, qsetbinfilesArray})           
          this.props.saveQuestion(this.state, qtext_value)
      }
  
      processAttachmentQuestionsets = (qtext_value) => (result) => {
          console.log("result==>", result)
          let binfiles_id = result.binfiles_id;
          let qsetbinfilesArray = result.qsetbinfilesArray;
          this.setState({binfiles_id: binfiles_id, qsetbinfilesArray, isLoadedIcon: false})
          this.props.saveQuestion(this.state, qtext_value)
      }    
  
      filesLoaded = (files) => {
          let contact_id = Store.getStoreData('contact_id');
          for(let i=0; i < files.length; i++) {
              files[i].name = files[i].name.replace('&', '');
              //console.log('File_name', files[i].name);
              if(!('user_id' in files[i])) {
                  files[i].user_id = contact_id;
                  files[i].upload_date = Date.now();
              }
              
          }
          console.log('files in Modal', files);
          this.setState({binFiles: files});
      }
      onExitsFilesUpdated = (exitsfiles) => {  
          console.log("exitsfiles===>", exitsfiles)      
          this.setState({cur_files_json: exitsfiles, isExitingfilesRemove: true});
      }
  
      render() {
          //console.log('AddQSetModal state', this.state)
          if(!this.state.isloaded)return(<div></div>)
          return (<div>
              <InactiveOverlay />
              {
                  (() => {
                      if(this.state.isLoadedIcon){
                          return <div><CSLLoader style={{position: 'absolute'}}/></div>
                      }
                  })()
              }  
              <ModalContainer style={{height: 643, zIndex: "1000"}}>
                  <ModalHeader>
                      <ModalHeaderTextLarge>{this.state.header_text} Question</ModalHeaderTextLarge><ModalHeaderClose onClick={this.props.closeAddQuestionModal}><FaTimes /></ModalHeaderClose>
                      <ClearFloat />
                      <ModalHeaderTextSmall>Complete the required fields to create a new Attestation Record</ModalHeaderTextSmall>
                  </ModalHeader>
                  <ModalBody style={{height: 443}}>
                  <ModalLabel>Question Title</ModalLabel>
                  <ModalInput onChange={this.changeTitle} value={this.state.title} />
                  <ModalLabel>Question Text</ModalLabel>
                  {/* <ModalTextarea onChange={this.changeText} value={this.state.text} /> */}
                  <div style={{width: "98%"}}><HtmlEditor content={this.state.text}/></div>
                  <ModalLabel>Upload Attachment</ModalLabel>
                  <ModalNameInputDiv>
                      <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} onExitsFilesUpdated={this.onExitsFilesUpdated}/>
                  </ModalNameInputDiv>
                  <ModalLabel>Is Active?</ModalLabel>
                  {
                      (() => {
                          if(this.state.is_active === 1) {
                              return (
                                  <RadioBlock>
                                      <SelectedRadio>Yes</SelectedRadio>
                                      <UnselectedRadio onClick={() => this.switchIsActive(0)}>No</UnselectedRadio>
                                  </RadioBlock>
                                  )
                          }
                          return (
                                  <RadioBlock>
                                      <UnselectedRadio onClick={() => this.switchIsActive(1)}>Yes</UnselectedRadio>
                                      <SelectedRadio>No</SelectedRadio>
                                  </RadioBlock>
                                  )
                      })()
                  }
                  <ModalLabel>Attestation required at question level</ModalLabel>
                  {
                      (() => {
                          if(this.state.attestation_required === 1) {
                              return (
                                  <RadioBlock>
                                      <SelectedRadio>Yes</SelectedRadio>
                                      <UnselectedRadio onClick={() => this.switchRadio(0)}>No</UnselectedRadio>
                                  </RadioBlock>
                                  )
                          }
                          return (
                                  <RadioBlock>
                                      <UnselectedRadio onClick={() => this.switchRadio(1)}>Yes</UnselectedRadio>
                                      <SelectedRadio>No</SelectedRadio>
                                  </RadioBlock>
                                  )
                      })()
                  }
                  <ModalLabel>Disable Comments for Incorrect Answers</ModalLabel>
                  {
                      (() => {
                          if(this.state.disable_comments === 1) {
                              return (
                                  <RadioBlock>
                                      <SelectedRadio>Yes</SelectedRadio>
                                      <UnselectedRadio onClick={() => this.switchDisableComments(0)}>No</UnselectedRadio>
                                  </RadioBlock>
                              )
                          }
                          return (
                              <RadioBlock>
                                  <UnselectedRadio onClick={() => this.switchDisableComments(1)}>Yes</UnselectedRadio>
                                  <SelectedRadio>No</SelectedRadio>
                              </RadioBlock>
                          )
                      })()
                  }
                  {
                      (() => {
                          if(this.state.attestation_required === 1) return <ModalLabel>Show Default</ModalLabel>
                      })()
                  }
                  
                  {
                      (() => {
                          if(this.state.attestation_required === 1) {
                              if(this.state.show_default === 1) {
                                  return (
                                  <RadioBlock>
                                      <SelectedRadio>Yes</SelectedRadio>
                                      <UnselectedRadio onClick={() => this.switchShowDefault(0)}>No</UnselectedRadio>
                                  </RadioBlock>
                                  )
                              }
                              return (
                                  <RadioBlock>
                                      <UnselectedRadio onClick={() => this.switchShowDefault(1)}>Yes</UnselectedRadio>
                                      <SelectedRadio>No</SelectedRadio>
                                  </RadioBlock>
                                  )
                          }
                      })()
                  }
                  {
                      (() => {
                          if(this.state.attestation_required === 1 && this.state.show_default === 0) {
                              return (<div>
                                  <ModalLabel>Attestation Text</ModalLabel>
                                  <ModalTextarea onChange={this.changeAttestationText} value={this.state.attestation_text} />
                                  <ModalLabel>Confirmation Text</ModalLabel>
                                  <ModalTextarea onChange={this.changeConfirmationText} value={this.state.confirmation_text} />
                              </div>)
                          }
                      })()
                  }
                  <ModalLabel>Allow N/A Response</ModalLabel>
                  {
                      (() => {
                          if(this.state.allow_na === 1) {
                              return (
                                  <RadioBlock style={{marginBottom: "10px"}}>
                                      <SelectedRadio>Yes</SelectedRadio>
                                      <UnselectedRadio onClick={() => this.switchRadioAllowNA(0)}>No</UnselectedRadio>
                                  </RadioBlock>
                                  )
                          }
                          return (
                                  <RadioBlock style={{marginBottom: "10px"}}>
                                      <UnselectedRadio onClick={() => this.switchRadioAllowNA(1)}>Yes</UnselectedRadio>
                                      <SelectedRadio>No</SelectedRadio>
                                  </RadioBlock>
                                  )
                        })()
                    }
                    <ModalLabel style={{display: "block"}}>Correct Answer</ModalLabel>
                    {/* <ModalLabelsub>Anything other than the correct answer may trigger a ‘flagged’ response.</ModalLabelsub> */}
                    <MRModalDropDown name="correct_answer" value={this.state.correct_answer} onChange={this.handleChangeCorrectAnswer}>
						<option value='Yes'>Yes</option>
						<option value='No'>No</option>						
					</MRModalDropDown>
                    {
                        (() => {
                            if(this.props.is_flag_review === 1){
                                return(<div>
                                        <ModalLabel style={{display: "block"}}>Flagged Options</ModalLabel>
                                        {/* <ModalLabelsub>Anything other than the correct answer may trigger a ‘flagged’ response.</ModalLabelsub> */}
                                        <MRModalDropDown name="flagged_opt" value={this.state.flagged_opt} onChange={this.handleChangeFlaggedOption}>
                                            <option value='no_flag'>No flag</option>
                                            <option value='incorrect'>Incorrect</option>
                                            {
                                                (() => {
                                                    if(this.state.allow_na === 1){
                                                        return(<option value='na'>N/A</option>)
                                                    }
                                                })()
                                            }
                                            {
                                                (() => {
                                                    if(this.state.allow_na === 1){
                                                        return(<option value='incorrect_na'>Incorrect and N/A</option>)
                                                    }
                                                })()
                                            }				
                                        </MRModalDropDown>
                                </div>)
                            }
                        })()
                    }
                    
                    <ModalLabel style={{display: "block"}}>Requires Attachment for Responses?</ModalLabel>
                    <MRModalDropDown name="req_attach" value={this.state.req_attach} onChange={this.handleChangeReqAttach}>						
						<option value='mand_corrt_ans'>Mandatory for Correct Answer</option>						
						<option value='mand_incorrt_ans'>Mandatory for Incorrect Answer</option>						
						<option value='mand_both'>Mandatory for Both</option>	
						<option value='no_attach'>No Attachment</option>	
                        <option value='optional'>Optional</option>					
					</MRModalDropDown>              
                  {/* <AlwaysScrollToBottom /> */}
                  </ModalBody>
                  <ModalFooter>
                      <SaveButton onClick={this.saveQuestion} style={{float: 'right', marginTop: 15, backgroundColor: '#143151'}}>Submit</SaveButton>
                      <CancelButton onClick={this.props.closeAddQuestionModal} style={{float: 'right', marginTop: 15, backgroundColor: '#ffffff'}}>Cancel</CancelButton>
                  </ModalFooter>
              </ModalContainer>
              <AlertBox alertParam={this.state.alert_param} />
          </div>)
      }
  }

class QSetSection extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {section: null, open_section_id: null, is_editing: false, show_addquestion_modal: false, edit_question: null, alert_param: null, edit_question_index: null, search_string: '', show_inactive: true,
            questions: [], fixed_section_name: '', isShowSaveSectionName: false
        }

    setComponentState = () => {
        let section = this.props.section
        for(let q of section.questions) {
            q.is_active = 'is_active' in q ? q.is_active : 1
        }
        let fixed_section_name = this.props.section.name
        this.setState({section, open_section_id: this.props.open_section_id, questions: section.questions, fixed_section_name, is_editing: false})
    }

    componentDidMount()
    {       
        this.setComponentState()
    }

    componentDidUpdate(prevProps)
    {
        if(this.props === prevProps)return
        this.setComponentState()
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false)
    }

    handleClick = (e) => {
        if(this.editbox.contains(e.target))return

        document.removeEventListener('mousedown', this.handleClick, false)
        let section = this.state.section
        // section.name = e.target.value
        this.props.saveSection(section)
        this.setState({is_editing: false})
    }


    saveQuestion = (q,qtext_value) => {

        let index = q.question_index
        let section = this.state.section
        // let new_score = (section.questions.length + 1) * 10000
        if(index < 0) {
            section.questions.push({
                title: q.title,
                text: qtext_value,
                is_active: q.is_active,
                disable_comments: q.disable_comments,
                attestation_required: q.attestation_required,
                allow_na: q.allow_na,
                correct_answer: q.correct_answer,
                flagged_opt: q.flagged_opt,
                req_attach: q.req_attach,
                show_default: q.show_default,
                attestation_text: q.attestation_text,
                confirmation_text: q.confirmation_text,
                binfiles_id: q.binfiles_id,
                qsetbinfilesArray: q.qsetbinfilesArray
            })
        } else section.questions[index] = {
            title: q.title,
            text: qtext_value,
            is_active: q.is_active,
            disable_comments: q.disable_comments,
            attestation_required: q.attestation_required,
            allow_na: q.allow_na,
            correct_answer: q.correct_answer,
            flagged_opt: q.flagged_opt,
            req_attach: q.req_attach,
            show_default: q.show_default,
            attestation_text: q.attestation_text,
            confirmation_text: q.confirmation_text,
            binfiles_id: q.binfiles_id,
            qsetbinfilesArray: q.qsetbinfilesArray
        }
        this.props.saveSectionDirect(section)
        console.log('section.questions', section.questions)
        this.setState({section, show_addquestion_modal: false, open_section_id: this.props.open_section_id})
    }

    addQuestion = () => {
        this.setState({
                        show_addquestion_modal: true, 
                        edit_question: {
                            question_index: -1, 
                            title: '', 
                            text: '', 
                            attestation_required: 0,
                            disable_comments: 0,
                            allow_na: 0,
                            correct_answer: 'Yes',
                            flagged_opt: 'incorrect',
                            req_attach: 'no_attach',
                            show_default: 1,
                            attestation_text: '',
                            confirmation_text: '',
                            binfiles_id: 0,
                            header_text: 'Add'                            
                        }
                    })
    }

    editQuestion = (index) => {
        let section = this.state.section
        let question = section.questions[index]
        //console.log("question==>", question)
        this.setState({ 
                        show_addquestion_modal: true, edit_question_index: null, edit_question: {
                            question_index: index, 
                            title: question.title, 
                            text: question.text, 
                            attestation_required: question.attestation_required,
                            disable_comments: question.disable_comments,
                            allow_na: 'allow_na' in question ? question.allow_na : 0,
                            correct_answer: 'correct_answer' in question ? question.correct_answer : 'Yes',
                            flagged_opt: 'flagged_opt' in question ? question.flagged_opt : 'incorrect',
                            req_attach: 'req_attach' in question ? question.req_attach : 'no_attach',
                            show_default: question.show_default,
                            attestation_text: question.attestation_text,
                            confirmation_text: question.confirmation_text,
                            is_active: 'is_active' in question ? question.is_active : 1,
                            binfiles_id: 'binfiles_id' in question ? question.binfiles_id : 0                           
                        }
                    })
    }

    closeAddQuestionModal = () => {
        this.setState({show_addquestion_modal: false, edit_question: null})
    }

    openSection = () => {
        this.setState({open_section_id: this.state.section.id})
    }
    closeSection = () => {
        this.setState({open_section_id: null})
    }

    editSectionName = () => {
        // document.addEventListener('mousedown', this.handleClick, false)

        this.setState({is_editing: true})
    }    

    changeNickName = (event) => {
        let section = this.state.section
        section.name = event.target.value       
        
        let isShowSaveSectionName = false        
        if(this.state.fixed_section_name !== event.target.value){
            isShowSaveSectionName = true
        }
        this.setState({section, isShowSaveSectionName})
    }

    saveSectionName = () => {
        let section = this.state.section
        this.props.saveSectionDirect(section)
    }

    onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
        let section = this.state.section
        section.questions = reorder(section.questions, previousIndex, nextIndex);
        this.props.saveSection(section)
        this.setState({section})
    }

    processRemoveQuestion =(result, stack) => {
        console.log('processRemoveQuestion result, stack', result, stack)
        if(!result) {
            this.setState({alert_param: null})
            return
        }

        let section = this.state.section
        section.questions.splice(stack.index, 1)
        this.props.saveSectionDirect(section)
        this.setState({section, alert_param: null})
    }

    processRemoveSection =(result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.props.removeSection(this.state.section.id)
        this.setState({alert_param: null})
    }

    removeQuestion = (index) => {
        let alert_param = {
                            title: 'Warning', 
                            confirm: true, 
                            message: 'Are you sure you want to remove this question? Note that this action cannot be reversed.', 
                            alertHandler: this.processRemoveQuestion,
                            stack:{index: index}
                          }
        this.setState({alert_param, edit_question_index: null})
    }

    showQuestionMenu = (index) => {
        let edit_question_index = this.state.edit_question_index
        if(edit_question_index === null)edit_question_index = index
        else edit_question_index = null

        this.setState({edit_question_index})
    }

    removeSection = (section_id) => {
        let alert_param = {
                            title: 'Warning', 
                            confirm: true, 
                            message: 'Are you sure you want to delete the section? This can not be undone.', 
                            alertHandler: this.processRemoveSection,
                            stack:{id: section_id}
                        }
        this.setState({alert_param})
    }

    searchQuestions = (event) => {
        this.setState({search_string: event.target.value})
    }

    checkCallback = (checked, index) => {
        let section = this.state.section
        section.questions[index].is_active = checked ? 1 : 0
        this.props.saveSectionDirect(section)
        this.setState({section})        
    }

    hideShowInactive = (checked) => {
        this.setState({show_inactive: checked})
    }

    getKey() {
        return this.keyCount++;
    }

    editDelete = (index) => (obj) => {
		console.log("curid==>", index)
		console.log("obj==>", obj)
		if(obj === 'Edit'){
			let section = this.state.section
            let question = section.questions[index]
            //console.log("question==>", question)
            this.setState({
                show_addquestion_modal: true, edit_question_index: null, edit_question: {
                    question_index: index,
                    title: question.title,
                    text: question.text,
                    attestation_required: question.attestation_required,
                    disable_comments: question.disable_comments,
                    allow_na: 'allow_na' in question ? question.allow_na : 0,
                    correct_answer: 'correct_answer' in question ? question.correct_answer : 'Yes',
                    flagged_opt: 'flagged_opt' in question ? question.flagged_opt : 'incorrect',
                    req_attach: 'req_attach' in question ? question.req_attach : 'no_attach',
                    show_default: question.show_default,
                    attestation_text: question.attestation_text,
                    confirmation_text: question.confirmation_text,
                    is_active: 'is_active' in question ? question.is_active : 1,
                    binfiles_id: 'binfiles_id' in question ? question.binfiles_id : 0,
                    header_text: 'Edit'
                }
            })
		}else if(obj === 'Delete'){
			let alert_param = {
                title: 'Warning', 
                confirm: true, 
                message: 'Are you sure you want to remove this question? Note that this action cannot be reversed.', 
                alertHandler: this.processRemoveQuestion,
                stack:{index: index}
              }
            this.setState({alert_param, edit_question_index: null})	
		}
	  };

      applyDrag = (arr, dragResult) => {
        console.log("arr===>", arr);
        console.log("dragResult===>", dragResult);
        const { removedIndex, addedIndex, payload } = dragResult;
        if (removedIndex === null && addedIndex === null) return arr;

        const result = [...arr];
        let itemToAdd = payload;

        if (removedIndex !== null) {
            itemToAdd = result.splice(removedIndex, 1)[0];
        }

        if (addedIndex !== null) {
            result.splice(addedIndex, 0, itemToAdd);
        }

        // //apply to module config		
        let section = this.state.section
        section.questions = result;
        this.setState({section})
        // this.props.updateDragQuestionset(this.factory(result));		
        this.props.saveSectionDirect(section)
        return result;
    };

    render() {
        if(this.state.section === null)return(<div></div>)
        return(<div style={{width: "98%"}}>
            <OverviewInfoHeader>
            {
                (() => {
                    if(this.state.is_editing) {
                        return(<div>
                                <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "16px"}}>
                                    <ModalInput style={{height: "15px", fontSize: "15px"}} ref={editbox => this.editbox = editbox} value={this.state.section.name} onChange={this.changeNickName} />
                                </div> 
                                {
                                    (() => {
                                        if(this.state.isShowSaveSectionName){
                                            return (<EditButton title='Save section name.' onClick={() => this.saveSectionName()} style={{marginLeft: "0px", paddingTop: "5px"}}><FaSave /></EditButton>)
                                        }
                                    })()
                                }                                  
                                <div style={{float:'right'}}>
                                    <div style={{float: "left", cursor: "pointer",fontSize: "19px",color: "#04ADA8"}} onClick={() => this.addQuestion()}>
                                        <FaPlusCircle  /> 
                                    </div>    
                                    <div title='Remove this section.' style={{float: "left", cursor: "pointer",fontSize: "19px",color: "#04ADA8", marginLeft: "10px"}} onClick={() => this.removeSection(this.state.section.id)}>
                                        <FaRegTrashAlt  /> 
                                    </div>                                
                                    <div style={{float: "right", cursor: "pointer",fontSize: "19px",color: "#E4E4E4", marginLeft: "10px"}}>
                                        <FaEllipsisV  /> 
                                    </div> 
                                    <div style={{float: "right", cursor: "pointer",fontSize: "19px",color: "#E4E4E4", marginLeft: "10px"}}>
                                        <FaChevronUp  /> 
                                    </div>                                   
                                </div>                                              
                                <div style={{clear: "both"}}></div>
                            </div>)
                    }else{
                        return(<div>                            
                                <div style={{float: "left", color: "#323232", fontWeight: "600",fontSize: "15px"}}>{this.state.section.name}</div>      
                                <EditButton title='Edit section name.' onClick={() => this.editSectionName()}><FaPencilAlt /></EditButton>     
                                <div style={{float:'right'}}>
                                    <div style={{float: "left", cursor: "pointer",fontSize: "19px",color: "#04ADA8"}} onClick={() => this.addQuestion()}>
                                        <FaPlusCircle  /> 
                                    </div>                                    
                                    <div title='Remove this section.' style={{float: "left", cursor: "pointer",fontSize: "19px",color: "#04ADA8", marginLeft: "10px"}} onClick={() => this.removeSection(this.state.section.id)}>
                                        <FaRegTrashAlt  /> 
                                    </div>                                    
                                    <div style={{float: "right", cursor: "pointer",fontSize: "19px",color: "#E4E4E4", marginLeft: "10px"}} >
                                        <FaEllipsisV  /> 
                                    </div>
                                    <div style={{float: "right", cursor: "pointer",fontSize: "19px",color: "#E4E4E4", marginLeft: "10px"}}>
                                        <FaChevronUp  /> 
                                    </div>
                                </div>            
                                <div style={{clear: "both"}}></div>                            
                        </div>)
                    }
                })()
            }
            
        </OverviewInfoHeader>
        <OverviewInfoBody style={{padding: "0px", width: "100%"}}>
            {
                (() => {
                    if(this.state.questions.length === 0){
                        return(<div style={{textAlign: "center",fontStyle: "italic", color: "#adadad", padding: "10px"}}>No questions found in this section.</div>)
                    }else{                        
                        if(this.state.questions.length !== 0){
                            return(<div style={{ display: "flex", backgroundColor: '#F5F5FD'}}>
                                <SerialHeader style={{lineHeight: "35px"}}>#</SerialHeader>
                                <FieldNameTitleHeader style={{lineHeight: "35px"}}>QUESTION</FieldNameTitleHeader>							
                                <FieldTypeTitleHeader style={{lineHeight: "35px"}}>ANSWER</FieldTypeTitleHeader>							
                                <DisplayForTitleHeader style={{lineHeight: "35px"}}>FLAGS</DisplayForTitleHeader>							
                                <RequiredTitleHeader style={{lineHeight: "35px"}}>ALLOW N/A?</RequiredTitleHeader>							
                                <ActionHeader style={{borderRight: "none", lineHeight: "35px"}}>ACTIVE</ActionHeader>
                                <ElipsisHeader style={{borderLeft: "none"}}></ElipsisHeader>
                            </div>)
                        }                            
                    }
                })()
            }
            <Container onDrop={e => this.setState({ questions: this.applyDrag(this.state.questions, e) })}>
            {(this.state.questions).map((q, i) => {    
                let flagged = 'Incorrect'
                if('flagged_opt' in q){
                    if(q.flagged_opt === 'no_flag'){
                        flagged = 'No flag'
                    }                    
                    if(q.flagged_opt === 'incorrect'){
                        flagged = 'Incorrect'
                    }                    
                    if(q.flagged_opt === 'na'){
                        flagged = 'N/A'
                    }                    
                    if(q.flagged_opt === 'incorrect_na'){
                        flagged = 'Incorrect and N/A'
                    }                    
                }                                    
                return (
                    <Draggable>                
                        <div key={this.getKey()} style={{ display: 'flex' }}>
                            <SerialHeader style={{fontWeight: 400, color: '#282828', backgroundColor: '#ffffff', height: "auto"}}>
                                {i + 1}
                            </SerialHeader>
                            <FieldNameTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", width: "34%", height: "auto"}}>
                                {q.title}
                            </FieldNameTitleHeader>
                            <FieldTypeTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", height: "auto"}}>
                                {'correct_answer' in q ? q.correct_answer : 'Yes'}
                            </FieldTypeTitleHeader>
                            <DisplayForTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", height: "auto"}}>
                                {flagged}
                            </DisplayForTitleHeader>
                            <RequiredTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", height: "auto"}}>
                                {'allow_na' in q ? q.allow_na === 1 ? 'Yes' : 'No' : 'No'}
                            </RequiredTitleHeader>
                            <ActionHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", borderRight: "none", height: "auto"}}>
                                <AddButton
                                    style={{
                                        marginRight: '30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '14px'
                                    }}>
                                    <CSLToggle
                                        checked={q.is_active === 1 ? true : false}
                                        id={i}
                                        onClick={this.checkCallback}
                                    />
                                </AddButton>
                            </ActionHeader>
                            <ElipsisHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", borderLeft: "none", height: "auto"}}>												
                                <VEllipsisMenuFolder editDelete={this.editDelete(i)} options={["Edit", "Delete"]} />																							
                            </ElipsisHeader>
                        </div>
                    </Draggable>     
                );
            })}  
            </Container>
        </OverviewInfoBody>
        {
            (() => {
                if(this.state.show_addquestion_modal) {
                    let q = this.state.edit_question
                    return (
                        <div>
                            <AddQSetQuestion 
                                closeAddQuestionModal={this.closeAddQuestionModal} 
                                saveQuestion={this.saveQuestion}
                                question_index={q.question_index} 
                                title={q.title}
                                text={q.text}
                                attestation_required={q.attestation_required}
                                disable_comments={q.disable_comments}
                                allow_na={'allow_na' in q ? q.allow_na : 0}
                                correct_answer={'correct_answer' in q ? q.correct_answer : 'Yes'}
                                flagged_opt={'flagged_opt' in q ? q.flagged_opt : 'incorrect'}
                                req_attach={'req_attach' in q ? q.req_attach : 'no_attach'}
                                is_active={'is_active' in q ? q.is_active : 1}
                                show_default={'show_default' in q ? q.show_default : 1}
                                attestation_text={'attestation_text' in q ? q.attestation_text : ''}
                                confirmation_text={'confirmation_text' in q ? q.confirmation_text : ''}
                                section_id={this.state.open_section_id}
                                binfiles_id={q.binfiles_id}       
                                is_flag_review={this.props.is_flag_review}
                                header_text={q.header_text}                             
                            />
                        </div>
                        )
                }
            })()
        }
        <AlertBox alertParam={this.state.alert_param} />
        </div>)
    }

}

class QuestionSet_update extends React.Component
{
	state = {qset_id: null, qset: null, qset_header: {nick_name: '', is_active: 1}, open_section_id: '0', isLoadedIconFinal: false, is_editing: false, 
            isShowSaveQuestionSet: false, fixed_nick_name: '', ready: false
        };

    processGetQset = (result) => {
        let fixed_nick_name = this.props.qset_header.nick_name;
        this.setState({qset_id: this.props.qset_id, qset: result.result.qset, qset_header: this.props.qset_header, fixed_nick_name, isShowSaveQuestionSet: false})
    }

    setComponentState = () => {
        // if(this.props.qset_id !== '0') {
        //     const postData = {command: "qsets", action: 'get_qset', qset_id: this.props.qset_id};
        //     const api = new APICall();
        //     api.command(postData, this.processGetQset); 
        //     this.setState({qset_id: null})
        // } else {
        //     this.setState({qset_id: '0'})
        // }
        console.log("this.props==>", this.props)
        if(this.props.qset_id !== null){
            if(this.props.qset_id !== '0'){
                if(this.props.qset !== null){
                    let fixed_nick_name = this.props.qset_header.nick_name;
                    this.setState({qset_id: this.props.qset_id, qset: this.props.qset, qset_header: this.props.qset_header, fixed_nick_name, isShowSaveQuestionSet: false, ready: true})
                }else{
                    this.setState({ready: false})
                }
            }else{
                this.setState({qset_id: this.props.qset_id, ready: true})
            }
        }else{
            this.setState({ready: false})
        }
    }

    saveQSet = () => {
        this.setState({isLoadedIconFinal: true})
        this.props.updateQSet(this.state)
    }
    closePage = () => {
        this.setState({qset_id: '0'})
    }

	componentDidMount()
	{		
        this.setComponentState()
	}

	componentDidUpdate(prevProps)
	{
		if(this.props === prevProps)return
        this.setComponentState()
	}

    switchRadio = (value) => {
        let qset_header = this.state.qset_header
        qset_header.is_active = value
        this.setState({qset_header})
        this.props.updateQSetGeneral(this.state)
    }
    isFlagReview = (value) => {
        let qset_header = this.state.qset_header
        qset_header.is_flag_review = value
        this.setState({qset_header})
        this.props.updateQSetGeneral(this.state)
    }

    changeNickName = (event) => {
        let qset_header = this.state.qset_header
        qset_header.nick_name = event.target.value
        let isShowSaveQuestionSet = false
        console.log("fixed_nick_name==>", this.state.fixed_nick_name)
        console.log("event.target.value==>", event.target.value)
        if(this.state.fixed_nick_name !== event.target.value){
            isShowSaveQuestionSet = true
        }
        this.setState({qset_header, isShowSaveQuestionSet})
    }

    addSection = () => {
        let qset = this.state.qset
        qset.sections = 'sections' in qset ? qset.sections : {}
        let num_sections = Object.keys(qset.sections).length
        let section_name = `Section ${num_sections + 1}`
        let section_id =  Utils.genKey(8)
        qset.sections[section_id] = {name: section_name, id: section_id, questions: []}
        this.setState({qset})
        this.props.updateQSetGeneral(this.state)
    }

    exportToExcel = () => {

        console.log("this.state.qset===>", this.state.qset)

        let wb = { SheetNames: [], Sheets: {} };
        let qset = Object.keys(this.state.qset.sections).length !== 0 ? this.state.qset.sections : {};        
        wb.SheetNames.push("QuestionSets");
        wb.Sheets["QuestionSets"] = XLSX.utils.json_to_sheet(XcelLoader.attestation_export_questionsets(qset), { origin: "A2" });
        wb.Sheets["QuestionSets"] = XLSX.utils.sheet_add_aoa(wb.Sheets["QuestionSets"], [["Question Set Name:", this.state.qset_header.nick_name]], { origin: "A1" });
        const format = "DD-MM-YYYY HH:mm:ss";
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "QuestionSets" + "-" + tm + fileExtension);
    };

    saveSection = (section) => {
        let qset = this.state.qset
        qset.sections[section.id] = section
        this.setState({qset, open_section_id: section.id})
    }
    
    saveSectionDirect = (section) => {
        let qset = this.state.qset
        qset.sections[section.id] = section
        this.setState({qset, open_section_id: section.id})
        this.props.updateQSetGeneral(this.state)
    }

    removeSection = (section_id) => {
        let qset = this.state.qset

        delete qset.sections[section_id]
        this.setState({qset})
        this.props.updateQSetGeneral(this.state)
    }

    editSectionName = () => {
        document.addEventListener('mousedown', this.handleClick, false)

        this.setState({is_editing: true})
    }

    handleClick = (e) => {
        if(this.editbox.contains(e.target))return

        document.removeEventListener('mousedown', this.handleClick, false)
        let section = this.state.section
        // section.name = e.target.value
        this.props.saveSection(section)
        this.setState({is_editing: false})
    }

    saveQuestionSetName = () => {
        this.props.updateQSet(this.state)
    }

	render()
	{
        console.log('Qset this.state', this.state)
        if (!this.state.ready) {
            return (<div><CSLLoader /></div>);
        }  
		if (this.state.qset_id === '0') {
			return (<ModalLabel>No question set loaded.</ModalLabel>);
		}

        let always_active = 'always_active' in this.props ? this.props.always_active : false
        console.log('Qset this.state', this.state)
        return (
            <div>
                {
                    (() => {
                        if(this.state.isLoadedIconFinal){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                } 
                <ModalBody style={{minHeight: 'calc(100vh - 250px)'}}>                    
                    <ModalLabel style={{display: "block"}}>Question Set Name</ModalLabel>                    
                    <ModalInput style={{width: "50%", display: "inline-block"}} onChange={this.changeNickName} value={this.state.qset_header.nick_name} disabled={always_active}/>
                    {
                        (() => {
                            if(this.state.isShowSaveQuestionSet){
                                return(<EditButton style={{display: "inline-block", float: "none", fontSize: "15px"}} title='Save question set name.' onClick={() => this.saveQuestionSetName()}><FaSave /></EditButton>)
                            }
                        })()
                    }  
                    <div style={{display: "inline-block", width: "100%",marginTop: "20px", marginBottom: "10px"}}>         
                        {
                            (() => {
                                if(this.state.qset_header.is_flag_review === 1){
                                    return(<div style={{cursor: "pointer", fontSize: "21px", float: "left", display: "inline-block", width: "5%"}} onClick={() => this.isFlagReview(0)}><IoIosCheckboxOutline /></div>)
                                }else{
                                    return(<div style={{cursor: "pointer", fontSize: "21px", float: "left", display: "inline-block", width: "5%"}} onClick={() => this.isFlagReview(1)}><IoIosSquareOutline /></div>)
                                }
                            })()
                        }  
                           
                        <div style={{display: "inline-block", width: "90%"}}>
                            <div style={{fontFamily: "Montserrat,sans-serif", fontWeight: "600" ,color: "#222222"}}>Flag Attestation for Review</div>
                            <div style={{fontFamily: "Montserrat, sans-serif", color: "#758597", fontSize: "12px", marginTop: "2px", fontStyle: "italic"}}>When enabled, when a negative answer is selected, the attestation will be highlighted to ‘managers’ within the system to review the responses. Attestations will remain flagged until they have been reviewed by a user with a manager role.</div>
                        </div>
                    </div>                                           
                    <ModalLabel style={{display: "block"}}>Active? 
                    {
                        (() => {
                            if(always_active) return <span style={{fontSize: 10, fontWeight: 400, marginLeft: "5px"}}>(Since this Question Set has been selected for the attestation, this cannot be deacivated.)</span>
                        })()
                    }
                    </ModalLabel>
                    {
                        (() => {
                            if(this.state.qset_header.is_active === 1) {
                                return (
                                    <RadioBlock>
                                        <SelectedRadio>Yes</SelectedRadio>
                                        {
                                            (() => {
                                                if(always_active) return <UnselectedRadio>No</UnselectedRadio>
                                                else return <UnselectedRadio onClick={() => this.switchRadio(0)}>No</UnselectedRadio>
                                            })()
                                        }
                                        
                                    </RadioBlock>
                                    )
                            }
                            return (
                                    <RadioBlock>
                                        <UnselectedRadio onClick={() => this.switchRadio(1)}>Yes</UnselectedRadio>
                                        <SelectedRadio>No</SelectedRadio>
                                    </RadioBlock>
                                    )
                        })()
                    }  
                    {
                        (() => {
                            if(this.state.qset_header.is_active === 1){
                                return(<div style={{width: "100%"}}>        <div style={{marginTop: "15px", color: "#04ADA8", cursor: "pointer", fontWeight: "600", textDecoration: "underline", display: "inline-block",width: "50%"}} onClick={() => this.addSection()}>
                                            Add Section
                                        </div>
                                        <div style={{marginTop: "15px", color: "#04ADA8", cursor: "pointer", fontWeight: "600", textDecoration: "underline", width: "7%", display: "inline-block", float: "right", marginRight: "3%"}} onClick={() => this.exportToExcel()}>
                                            Export
                                        </div>
                                    </div>)
                            }
                        })()
                    } 
                    <ClearFloat />
                    {
                        (() => {
                            if(this.state.qset_header.is_active === 1){
                                if(Object.keys(this.state.qset.sections).length > 0){
                                    return (<div>
                                        {
                                            Object.keys(this.state.qset.sections).map((id, i) => {
                                                return <QSetSection key={i} 
                                                            section={this.state.qset.sections[id]} 
                                                            open_section_id={this.state.open_section_id} 
                                                            is_flag_review={this.state.qset_header.is_flag_review}
                                                            saveSection={this.saveSection}
                                                            saveSectionDirect={this.saveSectionDirect}
                                                            removeSection={this.removeSection}
                                                        />                             
                                            })
                                        }
                                    </div>) 
                                }
                            }
                        })()
                    }                    
                </ModalBody>
                {/* {
                    (() => {
                        if(!always_active) return (<FooterButtonsContainer>   
                                                        <CancelBtn onClick={this.closePage}>Cancel</CancelBtn>
                                                        <SaveBtn onClick={this.saveQSet}>Save</SaveBtn>
                                                    </FooterButtonsContainer>)
                    })()
                }             */}
            </div>
            )
	}
}

export default QuestionSet_update;
