import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseBlank, faGear, faSquareList, faMessagesQuestion } from "@fortawesome/pro-thin-svg-icons";
import Store from '../Common/Store';

const HeadContainer = styled.div`
	display: block;
	height: 35px;
	background-color: #ffffff;
	padding-left: 10px;
	padding-right: 10px;
`;
const HomeLogo = styled.div`
	float: left;
	margin-top: 7px;
	font-size: 16px;
	color: #282828;
`;
const HomeText = styled.div`
	float: left;
	margin-top: 7px;
	color: #282828;
	margin-left: 10px;
	font-size: 13px;
`;
const Categories = styled.div`
	float: right;
	font-size: 17px;
	background-color: #ebebeb;
	color: #c4c4c4;
	width: 25px;
	height: 25px;
	margin-left: 5px;
	margin-top: 4px;
	padding-left: 4px;
	padding-top: 3px;
	border-radius: 2px;
`;
class CSLHeader extends React.Component {
	redirectToModuleConfig = (event) => {
		debugger;
		event.preventDefault();
		const url ='/moduleconfig'
		window.open(url, 'fc_mc', 'width=1400, height=900');
	};

    render()
    {
		let use_site_layout = false
		if(localStorage.getItem('site_layout')!==null){
		let site_layout = JSON.parse(localStorage.getItem('site_layout'));
		use_site_layout = site_layout.result.use_site_layout;
		}
		if(use_site_layout) return (<div></div>)
      let role = Store.getStoreData('role')
      let allow_am_edit_qsets = Store.getStoreData('allow_am_edit_qsets')
        return (
            <HeadContainer>
                <div style={{clear: "both"}}></div>
                <a href={'/'}>
                    <HomeText>
                        <FontAwesomeIcon icon={faHouseBlank} style={{marginRight: '7px'}} />
                        <span style={{ fontWeight: '600' }}>{this.props.headerText}</span>
                    </HomeText>
                </a>
              { (role === 'admin_manager' || (role === 'am' && allow_am_edit_qsets)) &&
                <Categories title='Questions Manager' >
                  <a href={'/questionsmanager'}>
                    <FontAwesomeIcon icon={faMessagesQuestion} style={{ color: '#848484' }} />
                  </a>
                </Categories>
              }
              { role === 'admin_manager' &&
                <Categories title='Module Config' >
                    <a href={'/moduleconfig'}>
                        <FontAwesomeIcon icon={faGear} style={{ color: '#848484' }} />
                    </a>
                </Categories>
              }
              { role === 'admin_manager' &&
                <Categories title='Audit Logs' >
                    <a href={process.env.REACT_APP_AUDIT_URL}>
                        <FontAwesomeIcon icon={faSquareList} style={{ color: '#848484' }} />
                    </a>
                </Categories>
              }
            </HeadContainer>
        );
    }
}
export default CSLHeader;
