import React from 'react';
import Store from '../Common/Store'
import { FaTasks } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import * as Style from './Common/StyledComponents'

class TaskHeader extends React.Component
{
    render()
    {
        let use_site_layout = false
		if(localStorage.getItem('site_layout')!==null){
		let site_layout = JSON.parse(localStorage.getItem('site_layout'));
		use_site_layout = site_layout.result.use_site_layout;
		}
		if(use_site_layout) return (<div></div>)
        let config_url = '/';
        return (
            <Style.HeadContainer>
                <Style.HomeLogo><FaTasks /></Style.HomeLogo>
                <Style.HomeText><span style={{fontWeight: "600"}}>Task Manager: Attestations Task Viewer</span></Style.HomeText>
                <Style.Categories><a href={config_url}><TiArrowBack /></a></Style.Categories>
                <div style={{clear: "both"}}></div>
            </Style.HeadContainer>
        );
    }
}
export default TaskHeader;
