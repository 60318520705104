import React from 'react';
import styled from 'styled-components';
import Store from './../../Common/Store';
import Dropzone from './../Common/DropzoneQuestionsetResp';
import APICall from "../../Common/APICall";

const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:20px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

class ResponceAttachment extends React.Component {

    state = {
        ready: false,
        cur_files_json: null,
        binFiles: [],
        binfiles_id: 0,
        isExitingfilesRemove: false    
    };

    constructor(props) {
        super(props);
    }

    componentDidMount()
    {
        this.setComponentState()
    }

    componentDidUpdate(prevprops)
    {
        if(this.props === prevprops)return
        this.setComponentState()
    }

    setComponentState = () => {  
        console.log("resp_attach===>", this.props.resp_attach)
        console.log("resp_binfiles_id===>", this.props.resp_binfiles_id)
        this.setState({
            cur_files_json: this.props.resp_attach, 
            binfiles_id: this.props.resp_binfiles_id,
            ready: true
        })      
    }

    filesLoaded = (files, isremove, uid) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        console.log('files in Modal', files);
        // this.setState({binFiles: files});
        this.uploadedonServer(files, isremove, uid)
    }

    uploadedonServer = (files, isremove, uid) => {
        console.log("this.state.cur_files_json===>", this.state.cur_files_json)
        console.log("this.state.cur_files_json===>", isremove)
        let bin_files = files;
        console.log("bin_files===>", bin_files)
        console.log("this.state.binfiles_id===>", this.state.binfiles_id)
        if(this.state.binfiles_id === 0){
            if(bin_files.length !== 0){
                let postData = {command: "create_qsetbinfile", bin_files: bin_files};
                console.log("postData",postData)            
                let api = new APICall();
                // this.setState({isLoadedIcon: true})
                api.command(postData, this.processAttachmentQuestionsetsResponce);
            }
        }else{
            if(bin_files.length !== 0){
                let postData = {command: "update_qsetbinfile_response", bin_files: bin_files, bin_fileid: this.state.binfiles_id, isremove: false, uid: uid};
                console.log("postData",postData)            
                let api = new APICall();
                // this.setState({isLoadedIcon: true})
                api.command(postData, this.processAttachmentQuestionsetsResponce);
            }else if(isremove){
                let postData = {command: "update_qsetbinfile_response", bin_files: bin_files, bin_fileid: this.state.binfiles_id, isremove: true, uid: uid};
                console.log("postData",postData)            
                let api = new APICall();
                // this.setState({isLoadedIcon: true})
                api.command(postData, this.processAttachmentQuestionsetsResponce);
            }
        }
    }

    processAttachmentQuestionsetsResponce = (result) => {
        console.log("result==>", result)
        let binfiles_id = result.binfiles_id;
        let responcebinfilesArray = result.qsetbinfilesArray;
        // this.setState({binfiles_id: binfiles_id, cur_files_json: responcebinfilesArray})
        this.props.saveQuestionsetResponce(binfiles_id, responcebinfilesArray)
    }   

    

    render(){
        console.log("this.state.cur_files_json===>", this.state.cur_files_json)
        if(!this.state.ready){
            return(<div></div>)
        }
        return (
            <ModalNameInputDiv>
                <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} downloadThroughApi={true} qsetbinfiles_id={this.state.binfiles_id}/>
            </ModalNameInputDiv>
        )
    }
}
export default ResponceAttachment;
