import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { FaTimes, FaPlusCircle, FaRegCircle, FaRegDotCircle, FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import APICall from "../../Common/APICall";
import Store from "../../Common/Store.js";
import Dropzone from "../Common/Dropzone";
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import CSLTable from "../Common/CSLTable";
import DatePicker from "react-date-picker";
import AMFollowupTask from "./AMFollowupTask";
import Utils from "../../Common/Utils";
import CSLLoader from "./../Common/CSLLoader";
import FollowupTaskModal from "./../AM/FollowupTaskModal";
import { MdModeEdit, MdDelete } from "react-icons/md";
import AlertBox from '../Common/AlertBox';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import moment from "moment";
import XcelLoader from "./../Common/XcelLoader";
const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 80%;
    position: absolute;
    top: 200px;
    z-index: 1002;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;
    border-left: 9px solid #04ada8;
    border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;
    color: #143151;
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 10px;
    border-radius: 4px;
`;
const MRModalLabel = styled.div`
    color: #1c3756;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalLabelSub = styled.div`
    color: #000000;
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #dfdfdf;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #143151;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #143151;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: "Montserrat", sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat, sans-serif;
    color: #5f6368;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 20px;
    color: #282828;
    font-family: "Montserrat", sans-serif;
`;
const CSLDateCover = styled.div`
    width: 284px;
    border-radius: 3px;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const UserHr = styled.div`
    border-top: 2px solid #e9ebed;
`;
const SpacerXL = styled.div`
    height: 20px;
`;
const SpacerL = styled.div`
    height: 10px;
`;
const OverviewInfoHeader = styled.div`
    padding: 15px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0 auto;
    margin-top: 30px;
    border: 1px solid #d7dddf;
`;
const OverviewInfoBody = styled.div`
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 0 auto;
    margin-bottom: 30px;
`;
const SearchInput = styled.input`
    height: 25px;
    width: 95%;
    margin-right: 180px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 13px;
    font-family: "Montserrat", sans-serif;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #f2ebd9;
    font-weight: bold;
    margin-bottom: 25px;
    color: #e39e00;
`;

const SectionHeader = styled.div`
    background-color: #44546a;
    color: white;
    padding-top: 5px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 700;
    height: 30px;
    width: calc(100% - 10px);
`;

const QuestionLine = styled.div`
    background-color: white;
    color: #44546a;
    width: calc(100% - 10px);
    border-bottom: 2px solid #e8eaec;
`;

const QuestionHeaderCont = styled.div`
    // background-color: white;
    width: 100%;
    margin-top: 1rem;
    padding 0.52rem 0.8rem;
    padding-right: 0;
    // border: 1px solid cadetblue;
    border-radius: 0.26rem;
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const QuestionNumber = styled.div`
    color: #44546a;
    // padding-top: 5px;
    // padding-left: 10px;
    font-size: 16px;
    font-weight: 700;
    // height: 30px;
    // width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const QuestionBox = styled.div`
    // background-color: white;
    color: #44546a;
    // padding-top: 5px;
    // padding-left: 10px;
    font-size: 16px;
    font-weight: 700;
    // min-height: 26px;
    // width: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
const AnswerButton = styled.div`
    width: 70px;
    height: 40px;
    margin: 0px;
    padding-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    float: left;
    // text-align: center;
    display: flex;
    align-items: center;
`;

const RegDismissalReason = styled.textarea`
    height: 130px;
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #444444;
    font-weight: 100;
    font-family: "Montserrat", sans-serif;
`;

const ClearFloat = styled.div`
    clear: both;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213e61;
    color: #f2f2f2;
    border: 1px solid #213e61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 8px;
    cursor: pointer;
    float: left;
    margin-left: 26px;
`;

const AlwaysScrollToView = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
};

class AMQuestionSetCompleteTaskModal extends React.Component {
    state = {
        ready: false,
        binFiles: [],
        cur_files_json: null,
        qset_cur_files_json: null,
        documentVersion: "",
        uploadedDate: new Date(),
        editedDate: new Date(),
        completeDate: new Date(),
        resendUserID: 0,
        resendTaskID: 0,
        questionSetFlaged: false,
        questionSetisReviewed: false,
        assigntoName: "",
        assignByName: "",
        showfollowupclose: true,
        showModal: false,
        showFollowupModal: false,
        followupTask: [],
        review_list: [],
        taskid: 0,
        isLoadedIcon: false,
        isAlwaysScrollToView: true,
        downloadThroughApiQuestionset: false,
        alert_param: null
    };

    componentDidMount() {
        let task_data = Store.getStoreData("cur_complete_task");

        console.log("COMPLETE TASK ::: ", task_data);

        //let parent_task_data = Store.getStoreData('cur_parent_pending_task');
        let parent_task_binFiles = Store.getStoreData("cur_parent_binFiles");

        let subtaskdata = task_data.subtask;
        let task_id = subtaskdata.id;
        let parenttaskdata = task_data.parenttask;
        let parent_task_json = JSON.parse(parenttaskdata.task_json);
        let task_json = JSON.parse(subtaskdata.task_json);

        //let binfile_parent_json = JSON.parse(parent_task_data.task_json);

        let cur_files_json = parent_task_binFiles;
        // if('bin_files' in binfile_parent_json.object_data){
        //     cur_files_json = binfile_parent_json.object_data.bin_files;
        // }

        let duedate = new Date(subtaskdata.due_date);
        let uploadmonth = parseInt(duedate.getMonth());
        let uploadedmonth = uploadmonth + 1;
        var uploadedDateStr = duedate.getDate() < 10 ? "0" + duedate.getDate() + "/" : duedate.getDate() + "/";
        uploadedDateStr += uploadedmonth >= 10 ? uploadedmonth + "/" + duedate.getFullYear() : "0" + uploadedmonth + "/" + duedate.getFullYear();

        let actiondate = new Date(parent_task_json.task_data.actiondate);
        let editmonth = parseInt(actiondate.getMonth());
        let editedmonth = editmonth + 1;
        var editedDateStr = actiondate.getDate() < 10 ? "0" + actiondate.getDate() + "/" : actiondate.getDate() + "/";
        editedDateStr += editedmonth >= 10 ? editedmonth + "/" + actiondate.getFullYear() : "0" + editedmonth + "/" + actiondate.getFullYear();

        const complete_at_date_obj = new Date(task_json.task_data.actiondate);
        const complete_at_day = complete_at_date_obj.getDate() < 10 ? "0" + complete_at_date_obj.getDate().toString() : complete_at_date_obj.getDate();
        const complete_at_month = complete_at_date_obj.getMonth() + 1 < 10 ? `0${(complete_at_date_obj.getMonth() + 1).toString()}` : complete_at_date_obj.getMonth() + 1;
        const complete_at_year = complete_at_date_obj.getFullYear();
        const complete_at_date = `${complete_at_day}/${complete_at_month}/${complete_at_year}`;

        //New section

        let questionSetFlaged = false;
        if ("qset_answer" in task_json.object_data && "flagged" in task_json.object_data.qset_answer) {
            questionSetFlaged = task_json.object_data.qset_answer.flagged;
        }
        let questionSetisReviewed = false;
        if ("qset_answer" in task_json.object_data && "isReviewed" in task_json.object_data.qset_answer && task_json.object_data.qset_answer.isReviewed === true) {
            questionSetisReviewed = task_json.object_data.qset_answer.isReviewed;
        }

        let assigntoName = Store.getStoreData("contacts")[subtaskdata.cur_assigned_to];
        let assignByName = Store.getStoreData("contacts")[parenttaskdata.contact_id];

        let review_list = Store.getStoreData("reviewee_list");

        let com_roles = Store.getStoreData("userroles");

        let gc_users = [];
        for (let i = 0; i < review_list.length; i++) {
            if (com_roles[review_list[i].ID.toString()] !== "no_access") {
                gc_users.push({
                    ID: review_list[i].ID,
                    ContactName: review_list[i].ContactName,
                });
            }
        }

        let gc_users_all = Store.getStoreData("gc_users");
        let has_gc = Store.getStoreData("has_gc");
        let role = Store.getStoreData("role");
        let update_gc_users = [];
        if (role === "am" && has_gc === true) {
            gc_users.forEach((item) => {
                let reviewid = item.ID.toString();
                if (reviewid in gc_users_all) {
                    update_gc_users.push(item);
                }
            });
        } else {
            update_gc_users = gc_users;
        }

        console.log("gc_users==>", update_gc_users);

        let qsetbinfilesIDObject = {};
        if (parent_task_json.object_data.at_type === "QuestionSet") {
            console.log("task_json", task_json.object_data);
            if (task_json.object_data.qset_answer.status === "complete") {
                for (let sid in task_json.object_data.qset_answer.sections) {
                    for (let q of task_json.object_data.qset_answer.sections[sid].questions) {
                        if ("binfiles_id" in q && q.binfiles_id !== 0) {
                            qsetbinfilesIDObject[q.binfiles_id] = 1;
                        }
                    }
                }
            }
        }

        this.setState({
            cur_files_json: cur_files_json,
            documentVersion: task_json.object_data.headerinfo.document_version,
            uploadedDate: uploadedDateStr,
            editedDate: editedDateStr,
            description: task_json.object_data.headerinfo.description,
            completeDate: complete_at_date,
            task: task_json,
            resendUserID: subtaskdata.contact_id,
            resendTaskID: subtaskdata.id,
            questionSetFlaged: questionSetFlaged,
            assigntoName: assigntoName,
            assignByName: assignByName,
            taskid: task_id,
            review_list: update_gc_users,
            questionSetisReviewed,
        });

        console.log("qsetbinfilesIDObject==>", qsetbinfilesIDObject);
        if (questionSetFlaged && subtaskdata.child_task_ids !== null) {
            let command_data = {command: "listfollowups", parent_id: task_id, isListOnly: true};
            console.log("followuptasks list command", command_data);
            let api = new APICall();
            api.command(command_data, this.showFollowupsTask(qsetbinfilesIDObject));
        } else {
            if('qsetbinfilesIds' in parent_task_json.object_data){
                let qsetbinfilesIds = parent_task_json.object_data.qsetbinfilesIds;
                this.setState({qset_cur_files_json: qsetbinfilesIds, downloadThroughApiQuestionset: true, ready:true})
            }else if (Object.keys(qsetbinfilesIDObject).length !== 0) {
                let postData = {command: "fetch_multipleQsetbinfile", bin_fileids: qsetbinfilesIDObject};
                console.log("postData", postData);
                let api = new APICall();
                api.command(postData, this.processfetchAttachmentQuestionsets);
            } else {
                this.setState({ ready: true });
            }
            //this.setState({ ready: true });
        }
    }

    showFollowupsTask = (qsetbinfilesIDObject) => (result) => {
        Store.updateStore("updated_followups", result.result.followuptasks);
        console.log("result.result.followuptasks", result.result.followuptasks);
        let task_data = Store.getStoreData("cur_complete_task");
        let parenttaskdata = task_data.parenttask;
        let parent_task_json = JSON.parse(parenttaskdata.task_json);
        if('qsetbinfilesIds' in parent_task_json.object_data){
            let qsetbinfilesIds = parent_task_json.object_data.qsetbinfilesIds;
            this.setState({qset_cur_files_json: qsetbinfilesIds, downloadThroughApiQuestionset: true, ready:true})
        }else if (Object.keys(qsetbinfilesIDObject).length !== 0) {
            let postData = {
                command: "fetch_multipleQsetbinfile",
                bin_fileids: qsetbinfilesIDObject,
            };
            console.log("postData", postData);
            let api = new APICall();
            api.command(postData, this.processfetchAttachmentQuestionsets);
        } else {
            this.setState({ ready: true });
        }
        //this.setState({ ready: true });
    };

    processfetchAttachmentQuestionsets = (result) => {
        console.log("result==>", result);
        this.setState({ qset_cur_files_json: result.result, ready: true });
    };

    resendEmailtoUser = () => {
        console.log("taskid", this.state.resendTaskID, "userid", this.state.resendUserID);
        let postData = {
            command: "resend_email_to_share_user",
            task_id: this.state.resendTaskID,
            send_mail: true,
            contact_id: this.state.resendUserID,
            system_id: 1001,
        };
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterresendCompleted);
    };

    afterresendCompleted = (result) => {
        //alert("The attestation has been re-sent.");
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'The attestation has been re-sent.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.customAMCompleteTask, stack: {result: result}}
        this.setState({alert_param: alert_param});
    };
    customAMCompleteTask = (result) => {
        this.setState({alert_param: null, isLoadedIcon: false});
    }

    closeView = () => {
        if (Store.getStoreData("isreferenceUrl") === true) {
            if (Store.getStoreData("isReferenceEmail") === true) {
                window.location = "/";
            } else {
                window.location = process.env.REACT_APP_TMVIEW_URL;
            }
        } else {
            this.props.closeModal();
        }
    };
    filesLoaded = (files) => {
        let contact_id = Store.getStoreData("contact_id");
        for (let i = 0; i < files.length; i++) {
            files[i].name = files[i].name.replace("&", "");
            //console.log('File_name', files[i].name);
            if (!("user_id" in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
        }
        //console.log('files in Modal', files);
        this.setState({ binFiles: files });
    };

    showAddFollowupTaskModal = (index) => (event) => {
        Store.updateStore("ftask_index", -1);
        this.setState({
            showModal: true,
            showfollowupclose: false,
            isAlwaysScrollToView: false,
        });
    };
    closeFollowupModal = () => {
        this.setState({ showModal: false, showfollowupclose: true });
    };

    EditfollowupTaskValueFetch = (taskValue) => {
        console.log(taskValue);
        let currentfollowupData = Store.getStoreData("cur_followup_task");
        let followupid = currentfollowupData.id;
        let task_json = JSON.parse(currentfollowupData.task_json);
        task_json["object_data"].subject = taskValue.subject;
        task_json["object_data"].note = taskValue.note;
        task_json["task_data"].cur_assigned_to = taskValue.assignedto;
        task_json["task_data"].due_date = taskValue.dueby;
        task_json["action_data"] = {
            action: "COMP_ATTESTATION_FOLLOWUP_ASSIGNMENT",
            actiondate: Date.now(),
            assigned_to: taskValue.assignedto,
            cur_lane: "COMP_ATTESTATION_FOLLOWUP_ASSIGNMENT",
            due_by: taskValue.dueby,
            note: taskValue.note,
        };
        let postData = {
            command: "update_followup_task",
            task_json: task_json,
            task_id: followupid,
            bin_files: taskValue.binFiles,
        };
        console.log(postData);
        this.setState({ isLoadedIcon: true });
        const api = new APICall();
        api.command(postData, this.processEditFollowupTask);
    };

    processEditFollowupTask = (result) => {
        //alert("The follow-up task has been updated successfully.");
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'The follow-up task has been updated successfully.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.customAMCompleteTask, stack: {result: result}}
        this.setState({alert_param: alert_param})
        console.log(result);
        let command_data = {
            command: "listfollowups",
            parent_id: this.state.taskid,
            isListOnly: true,
        };
        console.log("followuptasks list command", command_data);
        let api = new APICall();
        api.command(command_data, this.updateFollowups);
    };

    followupTaskValueFetch = (taskValue) => {
        console.log(taskValue);
        let ref_id = Utils.genKey(12);
        let task = {
            task_data: {
                module_id: "attest",
                last_action: "COMP_ATTESTATION_FOLLOWUP_ASSIGNMENT",
                ref_id: ref_id,
                actiondate: Date.now(),
                cur_assigned_to: taskValue.assignedto,
                task_type: "COMP_ATTEST_FOLLOWUP",
                parent_task_id: this.state.taskid,
                cur_lane: "COMP_ATTESTATION_FOLLOWUP_ASSIGNMENT",
                due_date: taskValue.dueby,
            },
            object_data: { subject: taskValue.subject, note: taskValue.note },
            action_data: [
                {
                    action: "COMP_ATTESTATION_FOLLOWUP_ASSIGNMENT",
                    actiondate: Date.now(),
                    assigned_to: taskValue.assignedto,
                    cur_lane: "COMP_ATTESTATION_FOLLOWUP_ASSIGNMENT",
                    due_by: taskValue.dueby,
                    note: taskValue.note,
                },
            ],
        };
        let postData = {
            command: "createfollowuptask",
            task: task,
            bin_files: taskValue.binFiles,
            parentTaskId: this.state.taskid,
        };
        console.log("task follow-up", postData);
        this.setState({ isLoadedIcon: true });
        let api = new APICall();
        api.command(postData, this.processAddTask);
    };
    processAddTask = (result) => {
        //alert("The follow-up task has been added.");
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'The follow-up task has been added.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.customAMCompleteTask, stack: {result: result}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
        console.log(result);
        let command_data = {
            command: "listfollowups",
            parent_id: this.state.taskid,
            isListOnly: true,
        };
        console.log("followuptasks list command", command_data);
        let api = new APICall();
        api.command(command_data, this.updateFollowups);
    };
    updateFollowups = (result) => {
        Store.updateStore("updated_followups", result.result.followuptasks);
        console.log("result.result.followuptasks", result);
        this.setState({
            showModal: false,
            showfollowupclose: true,
            isLoadedIcon: false,
        });
    };

    customDeleteFollowupTask = (taskid) => (event) => {
        event.preventDefault();
        let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this record?', ok_text: 'Yes', cancel_text: 'No', confirm: true,
                           alertHandler: this.deleteFollowupTask, stack: {taskid: taskid}}
        this.setState({alert_param: alert_param})
    }

    deleteFollowupTask = (result, stack) => {
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log("ROWS", stack.taskid);
        let postData = { command: "remove_followup_task", task_id: stack.taskid };
        console.log("postData", postData);
        this.setState({ alert_param: null, isLoadedIcon: true });
        let api = new APICall();
        api.command(postData, this.processRemoveFollowupData);
        //}
    };

    processRemoveFollowupData = (result) => {
        console.log("result", result);
        //alert("Follow-up task deleted successfully");
        let lp = Store.getStoreData('language_pack')
        let alert_param = {title: lp['success_popup_title'] === undefined ? 'Success' : lp['success_popup_title'], message: 'Follow-up task deleted successfully.', ok_text: 'Ok', confirm: false,
                           alertHandler: this.customupdateprocessRemoveFollowupData, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
        /*let command_data = {
            command: "listfollowups",
            parent_id: this.state.taskid,
            isListOnly: true,
        };
        console.log("followuptasks list command", command_data);
        let api = new APICall();
        api.command(command_data, this.updateFollowups);*/
    };

    customupdateprocessRemoveFollowupData = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
        let command_data = {
            command: "listfollowups",
            parent_id: this.state.taskid,
            isListOnly: true,
        };
        console.log("followuptasks list command", command_data);
        let api = new APICall();
        api.command(command_data, this.updateFollowups);
    }

    processMyfollowupTaskData = () => {
        var taskdata = Store.getStoreData("updated_followups");

        let gc_users = Store.getStoreData("gc_users");
        let has_gc = Store.getStoreData("has_gc");
        let role = Store.getStoreData("role");
        let update_taskdata = [];
        if (role === "am" && has_gc === true) {
            taskdata.forEach((item) => {
                let curassignto = item.cur_assigned_to.toString();
                if (curassignto in gc_users) {
                    update_taskdata.push(item);
                }
            });
        } else {
            update_taskdata = taskdata;
        }

        console.log("update_taskdata", update_taskdata);
        let ret = { data: [], columns: [] };
        let i = 0;
        ret.columns = [
            {
                Header: "#",
                accessor: "index",
                width: 30,
                headerStyle: { textAlign: "left" },
            },
            {
                Header: "Assigned To",
                accessor: "assign_to",
                minWidth: 150,
                headerStyle: { textAlign: "left" },
            },
            {
                Header: "Notes",
                accessor: "notes",
                minWidth: 300,
                headerStyle: { textAlign: "left" },
            },
            {
                Header: "Due Date",
                accessor: "due_date",
                minWidth: 100,
                headerStyle: { textAlign: "left" },
            },
            {
                Header: "Status",
                accessor: "status",
                minWidth: 100,
                headerStyle: { textAlign: "left" },
                Cell: (cellInfo) => (
                    <div>
                        <MRButton
                            style={{
                                cursor: "default",
                                backgroundColor: `${cellInfo.value.color}`,
                            }}
                        >
                            {cellInfo.value.text}
                        </MRButton>
                    </div>
                ),
            },
            {
                Header: "Action",
                Cell: (row) => (
                    <div style={{ display: "inline-flex" }}>
                        <MRButton onClick={this.goToFollowTaskUrl(row.original.id)}>Action</MRButton>
                        {(() => {
                            if (row.original.status_value !== "Completed") {
                                return (
                                    <div style={{ display: "inline-flex" }}>
                                        <div
                                            style={{
                                                fontSize: "20px",
                                                marginLeft: "15px",
                                                cursor: "pointer",
                                                color: "#282828",
                                                fontWeight: "bold",
                                            }}
                                            onClick={this.editFollowupTask(row.original.id)}
                                        >
                                            <MdModeEdit />
                                        </div>
                                        <div
                                            style={{
                                                fontSize: "20px",
                                                marginLeft: "15px",
                                                cursor: "pointer",
                                                color: "#282828",
                                                fontWeight: "bold",
                                            }}
                                            onClick={this.customDeleteFollowupTask(row.original.id)}
                                        >
                                            <MdDelete />
                                        </div>
                                    </div>
                                );
                            }
                        })()}
                    </div>
                ),
                width: 180,
                headerStyle: { textAlign: "left" },
            },
        ];
        for (let d of update_taskdata) {
            i++;
            let tdata = JSON.parse(d.task_json).object_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let status = "Pending";
            if (d.last_action === "COMP_ATTESTATION_FOLLOWUP_DISMISS") {
                status = "Dismissed";
            } else if (d.last_action === "COMP_ATTESTATION_FOLLOWUP_COMPLETE") {
                status = "Completed";
            }
            //console.log("d.cur_assigned_to",taskdata1.cur_assigned_to)
            let assigntoName = Store.getStoreData("contacts")[taskdata1.cur_assigned_to];
            let notes = tdata.note;
            let due_date_str = d.due_date ? d.due_date : "1970-01-01T00:00:00.000Z";

            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0" + c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0" + (c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day + "/" + month + "/" + year;

            var dd = parseInt(due_date_str.substring(8, 12));
            var mm = parseInt(due_date_str.substring(5, 7));
            var yyyy = parseInt(due_date_str.substring(0, 4));
            console.log("day, month, year", dd, mm, yyyy);

            let due_date_status = new Date(yyyy, mm - 1, dd);
            var due_date_status_final = new Date(due_date_status);
            due_date_status_final.setHours(due_date_status.getHours() + 23);
            due_date_status_final.setMinutes(due_date_status.getMinutes() + 59);
            due_date_status_final.setSeconds(due_date_status.getSeconds() + 59);

            let today = new Date();
            console.log("due_date_status", due_date_status_final, "today", today);
            let text = due_date_status_final > today ? status : "Overdue";
            let color = due_date_status_final > today ? "#2E8B57" : "#FF0000";
            if (status === "Pending") {
                text = due_date_status_final > today ? status : "Overdue";
                color = due_date_status_final > today ? "#2E8B57" : "#FF0000";
            } else if (status === "Completed") {
                text = status;
                color = "#2E8B57";
            }
            let elem = {
                index: i,
                id: d.id,
                assign_to: assigntoName,
                notes: notes,
                due_date: due_date,
                status: { text: text, color: color },
                status_value: text,
            };
            ret.data.push(elem);
        }
        return ret;
    };

    editFollowupTask = (followupid) => (event) => {
        event.preventDefault();
        let postData = { command: "get_single_subtask", task_id: followupid };
        let api = new APICall();
        this.setState({ isLoadedIcon: true, isAlwaysScrollToView: false });
        api.command(postData, this.editFollowupModal);
    };
    editFollowupModal = (result) => {
        console.log("followup_task", result.result.task);
        Store.updateStore("ftask_index", 0);
        Store.updateStore("cur_followup_task", result.result.task.subtask);
        Store.updateStore("cur_parentTask", result.result.task.parenttask);
        this.setState({ showModal: true, showfollowupclose: false });
    };

    goToFollowTaskUrl = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: task_id };
        let api = new APICall();
        this.setState({ isLoadedIcon: true });
        api.command(postData, this.openFollowupModal);
    };

    openFollowupModal = (result) => {
        console.log(result);
        Store.updateStore("followup_task", result.result.task);
        this.setState({
            showFollowupModal: "view_followup_modal",
            isLoadedIcon: false,
        });
    };

    closeModal = (event) => {
        this.setState({ showFollowupModal: null });
    };

    exportToExcel = () => {
        let wb = { SheetNames: [], Sheets: {} };
        let completed_tasks = this.state;
        wb.SheetNames.push("Completed Task");
        wb.Sheets["Completed Task"] = XLSX.utils.json_to_sheet(XcelLoader.completed_export_tasks(completed_tasks));
        const format = "DD-MM-YYYY HH:mm:ss";
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Completed Attestation" + "-" + tm + fileExtension);
    };

    updateReviewedConfirmation = (event) => {
        event.preventDefault();
        let lp = Store.getStoreData('language_pack')
        let msg = lp['unflag_attest_popup_text'] === undefined ? 'This attestation will now show as unflagged, are you happy that this attestation has been reviewed and is now complete?' : lp['unflag_attest_popup_text']
        let alert_param = {title: lp['unflag_attest_popup_header'] === undefined ? 'Warning' : lp['unflag_attest_popup_header'], message: msg, ok_text: 'Yes', cancel_text: 'No', confirm: true,
                        alertHandler: this.updateReviewed, stack: {}}
        this.setState({alert_param: alert_param, isAlwaysScrollToView: false})
    }

    updateReviewed = (result, stack) => {
        // event.preventDefault();

        // let lp = Store.getStoreData('language_pack')
        // let msg = lp['unflag_attest_popup_text'] === undefined ? 'This attestation will now show as unflagged, are you happy that this attestation has been reviewed and is now complete?' : lp['unflag_attest_popup_text']
        // let alert_param = {title: lp['unflag_attest_popup_header'] === undefined ? 'Warning' : lp['unflag_attest_popup_header'], message: msg, ok_text: 'Yes', cancel_text: 'No', confirm: true,
        //                 alertHandler: this.customUpdateReviewed, stack: {}}
        // this.setState({alert_param: alert_param})
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        let task_data = Store.getStoreData("cur_complete_task");
        let subtaskdata = task_data.subtask;
        let task_json = JSON.parse(subtaskdata.task_json);

        task_json["object_data"].qset_answer.isReviewed = true;
        task_json["action_data"] = {
            action: "COMP_ATTESTATION_SHARE_COMPLETED",
            actiondate: Date.now(),
            assigned_to: 0,
            cur_lane: "COMP_ATTESTATION_SHARE_COMPLETED",
            performed_by: Store.getStoreData("contact_id"),
        };
        let postData = {
            command: "update_reviewed_task",
            task_json: task_json,
            taskid: subtaskdata.id,
            parent_task_id: subtaskdata.parent_task_id,
            bin_files: [],
        };
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.processUpdateReviewTask);
    };
    customUpdateReviewed = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null, isLoadedIcon: false})
    }

    processUpdateReviewTask = (result) => {
        console.log("result", result);
        this.props.updateShareListReviewed(result);
    };

    render() {
        if (!this.state.ready) {
            return <div>Loading...</div>;
        }
        let lp = Store.getStoreData('language_pack')
        let updatedFollowups = Store.getStoreData("updated_followups") === null ? [] : Store.getStoreData("updated_followups");
        console.log("CompleteTaskModal this.state", this.state);
        let q_no = 0;
        let msg = 'This is a read only version of this attestation. It was acknowledged on '+ this.state.completeDate+'.'
        return (
            <div>
                {(() => {
                    if (this.state.isLoadedIcon) {
                        return (
                            <div>
                                <CSLLoader style={{ position: "absolute" }} />
                            </div>
                        );
                    }
                })()}
                <MRModalContainer>
                    {(() => {
                        if (this.state.isAlwaysScrollToView) {
                            return <AlwaysScrollToView />;
                        }
                    })()}
                    <MRModalHeader>
                        <MRModalHeaderText>{lp['review_attestation'] === undefined ? 'Review Attestation' : lp['review_attestation']}</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeView}>
                            <FaTimes />
                        </MRModalHeaderCloseBtn>
                        <div style={{ clear: "both" }}></div>
                        <MRModalSubHeaderText>{lp['complete_attestation_prompt'] === undefined ? 'Complete the acknowledgement below and submit.' : lp['complete_attestation_prompt']}</MRModalSubHeaderText>
                        <div style={{ clear: "both" }}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <div style={{ clear: "both" }}></div>
                        <div style={{ position: "relative", padding: "5px 35px" }}>
                            {(() => {
                                if (this.state.questionSetFlaged) {
                                    if (Store.getStoreData("role") === "team") {
                                        return <MRModalBodyInner style={{ backgroundColor: "#F3D8DC", color: "#D26B7A" }}>{lp['attestation_flagged_message'] === undefined ? 'This attestation has been submitted and is flagged for management review.' : lp['attestation_flagged_message']}</MRModalBodyInner>;
                                    } else {
                                        if (this.state.questionSetisReviewed === false) {
                                            let q_no_new = 0;
                                            let quesNo = {};
                                            Object.keys(this.state.task.object_data.qset_answer.sections).map((id, i) => {
                                                let sec = this.state.task.object_data.qset_answer.sections[id];
                                                sec.questions.map((q, j) => {
                                                    if (q.is_active === 1) {
                                                        q_no_new++;
                                                        if('correct_answer' in q){
                                                            if (q.correct_answer !== q.answer) {
                                                                let quno = "Qu " + q_no_new;
                                                                quesNo[quno] = 1;
                                                            }
                                                        }else{
                                                            if (q.answer !== "Yes") {
                                                                let quno = "Qu " + q_no_new;
                                                                quesNo[quno] = 1;
                                                            }
                                                        }
                                                    }
                                                });
                                            });
                                            let allflagquestionNo = "";
                                            let a = 0;
                                            for (let k in quesNo) {
                                                a++;
                                                allflagquestionNo += k;
                                                if (a <= Object.keys(quesNo).length - 1) {
                                                    allflagquestionNo += ", ";
                                                }
                                            }
                                            // return (
                                            //   <MRModalBodyInner style={{backgroundColor: "#F3D8DC",color: "#D26B7A"}}>This attestation has been Flagged for review. Please review the following responses and assign follow up tasks if necessary.</MRModalBodyInner>
                                            // );
                                            let msges = 'This attestation has been flagged for review. Please review the following responses: '+ allflagquestionNo +' and assign follow up tasks as required.'
                                            return (
                                                <MRModalBodyInner style={{ backgroundColor: "#F3D8DC", color: "#D26B7A" }}>
                                                    {lp['attest_flagged_prompt_text'] === undefined ? msges : lp['attest_flagged_prompt_text'].replace(/{{number}}+/g, allflagquestionNo)}
                                                </MRModalBodyInner>
                                            );
                                        }
                                    }
                                } else {
                                    return <MRModalBodyInner>{lp['viewing_completed_attestation_message'] === undefined ? msg : lp['viewing_completed_attestation_message'].replace('[[date]]', this.state.completeDate)}</MRModalBodyInner>;
                                }
                            })()}

                            <div
                                style={{
                                    height: "auto",
                                    backgroundColor: "#ffffff",
                                    borderRadius: "4px",
                                    paddingTop: 5,
                                    paddingLeft: 25,
                                    paddingRight: 25,
                                    paddingBottom: 5,
                                    marginBottom: "5px",
                                }}
                            >
                                <MRModalLabel>{this.state.task.object_data.headerinfo.document_name}</MRModalLabel>
                                <div
                                    style={{
                                        fontFamily: "'Montserrat', sans-serif",
                                        whiteSpace: "pre-line",
                                    }}
                                >
                                    {this.state.description}
                                </div>
                                <hr style={{ color: "#EAEAEA", marginTop: "20px" }} />
                                <div style={{ display: "inline-block", float: "left" }}>
                                    <div style={{ display: "inline-block" }}>
                                        <MRModalLabel>{lp['assigned_to_field_title'] === undefined ? 'Assigned To' : lp['assigned_to_field_title']}</MRModalLabel>
                                        <MRModalLabelSub>{this.state.assigntoName}</MRModalLabelSub>
                                    </div>
                                    <div style={{ display: "inline-block", marginLeft: "80px" }}>
                                        <MRModalLabel>{lp['assigned_by_field_title'] === undefined ? 'Assigned By' : lp['assigned_by_field_title']}</MRModalLabel>
                                        <MRModalLabelSub>{this.state.assignByName}</MRModalLabelSub>
                                    </div>
                                    <div style={{ display: "inline-block", marginLeft: "80px" }}>
                                        <MRModalLabel>{lp['assigned_date_field_title'] === undefined ? 'Assigned Date' : lp['assigned_date_field_title']}</MRModalLabel>
                                        <MRModalLabelSub>{this.state.editedDate}</MRModalLabelSub>
                                    </div>
                                    <div style={{ display: "inline-block", marginLeft: "80px" }}>
                                        <MRModalLabel>{lp['due_date_field_title'] === undefined ? 'Due Date' : lp['due_date_field_title']}</MRModalLabel>
                                        <MRModalLabelSub>{this.state.uploadedDate}</MRModalLabelSub>
                                    </div>
                                </div>

                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                        <div
                            style={{
                                position: "relative",
                                padding: "5px 35px",
                                marginTop: 2,
                            }}
                        >
                            <div
                                style={{
                                    height: "auto",
                                    backgroundColor: "#ffffff",
                                    borderRadius: "4px",
                                    paddingLeft: "25px",
                                    marginBottom: "25px",
                                    paddingRight: "25px",
                                    marginTop: 10,
                                    paddingTop: 5,
                                }}
                            >
                                {Object.keys(this.state.task.object_data.qset_answer.sections).map((id, i) => {
                                    let sec = this.state.task.object_data.qset_answer.sections[id];
                                    let isShowSecName = false;
                                    sec.questions.map((q) => {
                                        if (q.is_active === 1) {
                                            isShowSecName = true;
                                        }
                                    });
                                    return (
                                        <div key={i}>
                                            {(() => {
                                                if (isShowSecName) {
                                                    return <SectionHeader>{sec.name}</SectionHeader>;
                                                }
                                            })()}
                                            {sec.questions.map((q, j) => {
                                                //console.log('sec, q, i, j', sec, q, i, j)
                                                let qtext = q.text;
                                                let isdesign = false;
                                                if (Utils.isBase64(qtext)) {
                                                    qtext = window.decodeURIComponent(atob(qtext));
                                                    isdesign = true;
                                                }
                                                if (q.is_active === 1) {
                                                    q_no++;
                                                    return (
                                                        <QuestionLine key={1000 * (i + 1) + j}>
                                                            {
                                                                (() => {
                                                                    if('correct_answer' in q){
                                                                       return(<QuestionHeaderCont style={q.correct_answer !== q.answer ? { backgroundColor: "#f3d9dd" } : {}}>
                                                                                <QuestionNumber>Q{q_no}</QuestionNumber>
                                                                                <QuestionBox>{q.title}</QuestionBox>
                                                                            </QuestionHeaderCont>
                                                                        )
                                                                    }else{
                                                                        return(<QuestionHeaderCont style={q.answer !== "Yes" ? { backgroundColor: "#f3d9dd" } : {}}>
                                                                                <QuestionNumber>Q{q_no}</QuestionNumber>
                                                                                <QuestionBox>{q.title}</QuestionBox>
                                                                            </QuestionHeaderCont>
                                                                        )
                                                                    }
                                                                })()
                                                            }

                                                            <ClearFloat />
                                                            <div
                                                                dangerouslySetInnerHTML={{ __html: qtext }}
                                                                style={{
                                                                    textAlign: "justify",
                                                                    fontFamily: "'Montserrat', sans-serif",
                                                                    fontSize: "16px",
                                                                    padding: "5px",
                                                                    marginLeft: "5px",
                                                                }}
                                                            ></div>
                                                            {(() => {
                                                                if ("binfiles_id" in q && q.binfiles_id !== 0 && q.binfiles_id in this.state.qset_cur_files_json) {
                                                                    return (
                                                                        <ModalNameInputDiv style={{ marginLeft: "12px" }}>
                                                                            <Dropzone initFiles={this.state.qset_cur_files_json[q.binfiles_id]} addnew={false} downloadThroughApiQuestionset={this.state.downloadThroughApiQuestionset} qsetbinfiles_id={q.binfiles_id}/>
                                                                        </ModalNameInputDiv>
                                                                    );
                                                                }
                                                            })()}
                                                            <QuestionBox
                                                                style={{
                                                                    paddingTop: "10px",
                                                                    paddingLeft: "5px",
                                                                    marginLeft: "5px",
                                                                }}
                                                            >
                                                                {lp['viewing_flagged_attest_response'] === undefined ? 'Response:' : lp['viewing_flagged_attest_response']}
                                                            </QuestionBox>
                                                            <AnswerButton>
                                                                {(() => {
                                                                    if (q.answer === "Yes") {
                                                                        return <div>Yes</div>;
                                                                    } else if (q.answer === "No") {
                                                                        return <div>No</div>;
                                                                    } else {
                                                                        return <div>N/A</div>;
                                                                    }
                                                                })()}
                                                            </AnswerButton>
                                                            <ClearFloat />
                                                            {(() => {
                                                                // if (q.answer !== "Yes" && q.answer !== null)
                                                                if('correct_answer' in q){
                                                                    if(q.correct_answer !== q.answer && q.answer !== null)
                                                                    return (
                                                                        <div
                                                                            style={{
                                                                                marginLeft: "9px",
                                                                                marginBottom: "10px",
                                                                            }}
                                                                        >
                                                                            <MRModalLabel>{lp['viewing_flagged_attest_comment'] === undefined ? 'Comment' : lp['viewing_flagged_attest_comment']}</MRModalLabel>
                                                                            <MRModalSubHeaderText
                                                                                style={{
                                                                                    marginTop: "20px",
                                                                                    paddingRight: "30px",
                                                                                    display: "contents",
                                                                                }}
                                                                            >
                                                                                {q.comment}
                                                                            </MRModalSubHeaderText>
                                                                        </div>
                                                                    );
                                                                }else{
                                                                    if (q.answer !== "Yes" && q.answer !== null)
                                                                    return (
                                                                        <div
                                                                            style={{
                                                                                marginLeft: "9px",
                                                                                marginBottom: "10px",
                                                                            }}
                                                                        >
                                                                            <MRModalLabel>{lp['viewing_flagged_attest_comment'] === undefined ? 'Comment' : lp['viewing_flagged_attest_comment']}</MRModalLabel>
                                                                            <MRModalSubHeaderText
                                                                                style={{
                                                                                    marginTop: "20px",
                                                                                    paddingRight: "30px",
                                                                                    display: "contents",
                                                                                }}
                                                                            >
                                                                                {q.comment}
                                                                            </MRModalSubHeaderText>
                                                                        </div>
                                                                    );
                                                                }
                                                            })()}
                                                            {
                                                                (() => {
                                                                    if('req_attach' in q && q.req_attach === 'optional'){
                                                                        return(<ModalNameInputDiv style={{ marginLeft: "12px" }}>
                                                                                    <Dropzone initFiles={q.resp_attach} addnew={false} downloadThroughApiQuestionset={true} qsetbinfiles_id={q.resp_binfiles_id}/>
                                                                                </ModalNameInputDiv>)
                                                                    }else if('req_attach' in q && q.req_attach === 'mand_corrt_ans' && q.correct_answer === q.answer){
                                                                        return(<ModalNameInputDiv style={{ marginLeft: "12px" }}>
                                                                                    <Dropzone initFiles={q.resp_attach} addnew={false} downloadThroughApiQuestionset={true} qsetbinfiles_id={q.resp_binfiles_id}/>
                                                                                </ModalNameInputDiv>)
                                                                    }else if('req_attach' in q && q.req_attach === 'mand_incorrt_ans' && q.correct_answer !== q.answer && (q.answer !== null && q.answer !== 'N/A')){
                                                                        return(<ModalNameInputDiv style={{ marginLeft: "12px" }}>
                                                                                    <Dropzone initFiles={q.resp_attach} addnew={false} downloadThroughApiQuestionset={true} qsetbinfiles_id={q.resp_binfiles_id}/>
                                                                                </ModalNameInputDiv>)
                                                                    }else if('req_attach' in q && q.req_attach === 'mand_both' && (q.answer !== null && q.answer !== 'N/A')){
                                                                        return(<ModalNameInputDiv style={{ marginLeft: "12px" }}>
                                                                                    <Dropzone initFiles={q.resp_attach} addnew={false} downloadThroughApiQuestionset={true} qsetbinfiles_id={q.resp_binfiles_id}/>
                                                                                </ModalNameInputDiv>)
                                                                    }
                                                                })()

                                                            }
                                                        </QuestionLine>
                                                    );
                                                }
                                            })}
                                        </div>
                                    );
                                })}
                                {/* <MRModalLabel style={{ marginLeft: "9px" }}>Overall Comment</MRModalLabel> */}
                                <MRModalLabel style={{ marginLeft: "9px" }}>{lp['comment_box_title'] === undefined ? 'Comments' : lp['comment_box_title']}</MRModalLabel>
                                <MRModalSubHeaderText style={{ paddingRight: "30px", marginLeft: "9px" }}>{this.state.task.object_data.qset_answer.overall_comment}</MRModalSubHeaderText>
                                <ClearFloat />
                            </div>
                            {(() => {
                                if ("isFollowup" in this.props && this.props.isFollowup === true) {
                                    if (this.state.questionSetFlaged) {
                                        return (
                                            <RegDismisOuterContainer>
                                                <OverviewInfoHeader>
                                                    <div
                                                        style={{
                                                            float: "left",
                                                            color: "#ffffff",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        {lp['followup_tasks_header'] === undefined ? 'Follow-up Tasks' : lp['followup_tasks_header']}
                                                    </div>
                                                    {(() => {
                                                        if (this.state.showfollowupclose && this.state.questionSetisReviewed === false) {
                                                            return (
                                                                <div
                                                                    style={{
                                                                        float: "right",
                                                                        color: "#ffffff",
                                                                        fontSize: "17px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={this.showAddFollowupTaskModal(-1)}
                                                                >
                                                                    <FaPlusCircle />
                                                                </div>
                                                            );
                                                        }
                                                    })()}
                                                    <div style={{ clear: "both" }}></div>
                                                </OverviewInfoHeader>
                                                {(() => {
                                                    if (this.state.showModal) {
                                                        return (
                                                            <div>
                                                                <AMFollowupTask
                                                                    followuptaskvalue={this.state.followupTask}
                                                                    taskId={this.state.taskid}
                                                                    revieweeList={this.state.review_list}
                                                                    closeFollowupTaskModal={this.closeFollowupModal}
                                                                    refreshTaskValue={this.followupTaskValueFetch}
                                                                    EditrefreshTaskValue={this.EditfollowupTaskValueFetch}
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                })()}
                                                {(() => {
                                                    if (updatedFollowups.length !== 0) {
                                                        return (
                                                            <OverviewInfoBody>
                                                                <CSLTable add={false} processData={this.processMyfollowupTaskData} headerText={""} tableRows={3} refreshCallback={this.refreshCallback} />
                                                            </OverviewInfoBody>
                                                        );
                                                    } else {
                                                        return (
                                                            <OverviewInfoBody>
                                                                <div
                                                                    style={{
                                                                        textAlign: "center",
                                                                        fontStyle: "italic",
                                                                        color: "#dddddd",
                                                                    }}
                                                                >
                                                                    {lp['no_followup_tasks'] === undefined ? 'There are no follow-up tasks assigned.' : lp['no_followup_tasks']}
                                                                </div>
                                                            </OverviewInfoBody>
                                                        );
                                                    }
                                                })()}
                                            </RegDismisOuterContainer>
                                        );
                                    }
                                }
                            })()}

                            <div style={{ clear: "both" }}></div>
                        </div>
                    </MRModalBody>
                    <MRModalFooter>
                        <div style={{ position: "relative", padding: "17px" }}>
                            {(() => {
                                if (Store.getStoreData("role") !== "team" && this.state.questionSetFlaged === true && this.state.questionSetisReviewed === false) {
                                    return <MRModalSaveCloseBtn onClick={this.updateReviewedConfirmation}>{lp['mark_flag_attest_as_review_btn'] === undefined ? 'Reviewed' : lp['mark_flag_attest_as_review_btn']}</MRModalSaveCloseBtn>;
                                }
                            })()}
                            <MRModalCancelBtn onClick={this.closeView}>{lp['cancel_button_com'] === undefined ? 'Cancel' : lp['cancel_button_com']}</MRModalCancelBtn>
                            {(() => {
                                if (Store.getStoreData("role") !== "team") {
                                    return <ExportButton onClick={() => this.exportToExcel()}>{lp['export_button'] === undefined ? 'Export' : lp['export_button']}</ExportButton>;
                                }
                            })()}
                        </div>
                    </MRModalFooter>
                </MRModalContainer>
                {(() => {
                    if (this.state.showFollowupModal !== null) {
                        switch (this.state.showFollowupModal) {
                            case "view_followup_modal":
                                return <FollowupTaskModal closeModal={this.closeModal} />;
                                break;
                        }
                    }
                })()}
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        );
    }
}

export default AMQuestionSetCompleteTaskModal;
