import React from 'react';
import styled from 'styled-components';
import { FaEdit,FaPencilAlt, FaTimes,FaPlusCircle } from "react-icons/fa";
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;
const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 18px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 99%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 12px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
`;
const MRModalTextarea = styled.textarea`
    width: 99%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #545454;
	font-family: 'Montserrat', sans-serif;
	font-size: 12px;
    font-weight: 600;
`;

const MRModalDropDown = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 90%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #222222;
`;

class General extends React.Component
{
	state = {general: null};

	componentDidMount()
	{		
		this.setState({general: this.props.general,additionalText: this.props.general.configure_attestation_wording_details.additional_text,confirmationText: this.props.general.configure_attestation_wording_details.confirmation_text})
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({general: this.props.general,additionalText: this.props.general.configure_attestation_wording_details.additional_text,confirmationText: this.props.general.configure_attestation_wording_details.confirmation_text})			
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.setState({general})
		this.props.updateGeneral(general);
	}

	handleChangeAdditionalText = (evt) => { 
		this.setState({[evt.target.name]: evt.target.value});
		this.props.updateAdditionalText(evt.target.value)
	}
	
	handleChangeConfirmationText = (evt) => {
		this.setState({[evt.target.name]: evt.target.value});
		this.props.updateConfirmationText(evt.target.value)
	}

	handleChangeAtType = (evt) => {
		evt.preventDefault()
		let {general} = this.state;
		general.at_type = evt.target.value
		this.setState({general})
		this.props.updateGeneral(general);
	}

	render()
	{
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}

		return (
			<div>			
			<GeneralContainer>
				<GeneralInnerContainer>
					<GeneralLabel style={{marginBottom: "10px"}}>Attestation Type</GeneralLabel>
					<MRModalDropDown name="at_type" value={this.state.general.at_type} onChange={this.handleChangeAtType}>
						<option value='All'>All</option>
						<option value='Document'>Document</option>
						<option value='QuestionSet'>Question Set</option>
					</MRModalDropDown>
					
					{
						(() => {
							if(this.state.general.at_type === 'All' || this.state.general.at_type === 'QuestionSet'){
								return(<div>
									<GeneralLabel>Allow Attestations Managers to setup and edit Question Sets?</GeneralLabel>
									{										
										(() => {
											if (this.state.general.allow_am_stp_edit_ques_sets) {
												return (
													<RadioBlock>
														<SelectedRadio>Yes</SelectedRadio>
														<UnselectedRadio onClick={this.switchRadio('allow_am_stp_edit_ques_sets')}>No</UnselectedRadio>																												
													</RadioBlock>
												);
											} else {
												return (
													<RadioBlock>
														<UnselectedRadio onClick={this.switchRadio('allow_am_stp_edit_ques_sets')}>Yes</UnselectedRadio>
														<SelectedRadio>No</SelectedRadio>																												
													</RadioBlock>
												);
											}
										})()
									}
								</div>)
							}else{
								return(<div>
										<GeneralLabel style={{color: "#bfbfbf"}}>Allow Attestations Managers to setup and edit Question Sets?</GeneralLabel>
										{										
											(() => {
												if (this.state.general.allow_am_stp_edit_ques_sets) {
													return (
														<RadioBlock>
															<SelectedRadio style={{backgroundColor: "#72b9b5",border: "1px solid #bfbfbf",cursor: "default"}}>Yes</SelectedRadio>
															<UnselectedRadio style={{color: "#9c9c9c",border: "1px solid #bfbfbf",cursor: "default"}}>No</UnselectedRadio>																												
														</RadioBlock>
													);
												} else {
													return (
														<RadioBlock>
															<UnselectedRadio style={{color: "#9c9c9c",border: "1px solid #bfbfbf",cursor: "default"}}>Yes</UnselectedRadio>	
															<SelectedRadio style={{backgroundColor: "#72b9b5",border: "1px solid #bfbfbf",cursor: "default"}}>No</SelectedRadio>																											
														</RadioBlock>
													);
												}
											})()
										}
								</div>)
							}
						})()
					}
					<GeneralLabel>Allow Repeat functionality</GeneralLabel>
					{
						(() => {
							if (this.state.general.allow_repeat_functionality_enable) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('allow_repeat_functionality_enable')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('allow_repeat_functionality_enable')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}				
					<GeneralLabel>Configure attestation wording</GeneralLabel>
					{
						(() => {
							if (this.state.general.configure_attestation_wording_enable) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('configure_attestation_wording_enable')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('configure_attestation_wording_enable')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					{
						(() => {
							if(this.state.general.configure_attestation_wording_enable){
								return(
									<div>
										<MRModalLabel>Additional Text</MRModalLabel>  
										<MRModalInput type="text" name="additionalText" onChange={this.handleChangeAdditionalText} value={this.state.additionalText} />
										<MRModalLabel>Confirmation text</MRModalLabel>
										<MRModalTextarea rows="6" name="confirmationText" value={this.state.confirmationText} onChange={this.handleChangeConfirmationText} />
									</div>
								)
							}
						})()
					}			
				</GeneralInnerContainer>												
			</GeneralContainer>
			</div>
		);
	}
}

export default General;