import React from 'react';
import {FaTimes} from 'react-icons/fa';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';


const EmailContainer = styled.div`
	width: 70%;
	position: absolute;
	background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1002;
	padding: 20px;
`;
const CancelBtn = styled.button`
    padding: 10px 30px;
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 2px;
	color: #595959;
	cursor: pointer;	
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 15px 24px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
    font-weight: 600;
    font-size: 17px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`; 
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    width: 96%;
    margin: 0 auto; 
    height: 20px;       
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
`;

class AddMoreUserModal extends React.Component
{
    state = {ready: false,selectUseridarray: [],rowchecked: false,sendContactDistribution: {}};

	componentDidMount()
	{ 
            //new implement end
        let selectUseridarray_update = this.props.unselectedUser;
        selectUseridarray_update.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 

        this.setState({ready:true,
            selectUseridarray: selectUseridarray_update })  
	}

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
    }    

    selectContact = (event) => {        
        let index = event.target.value;        
        var contactListAll = this.state.selectUseridarray;        
            
        if(contactListAll[index].select){
            contactListAll[index].select = false;            
            delete this.state.sendContactDistribution[contactListAll[index].user_id.toString()];                    
        } else {
            contactListAll[index].select = true;            
            let userid = contactListAll[index].user_id.toString();
            let val = 1;            
            this.state.sendContactDistribution[userid] = val;                            
        }
        contactListAll.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
        this.setState({selectUseridarray: contactListAll, sendContactDistribution: this.state.sendContactDistribution,rowchecked: false})
        let allselectcheck = true
        for(let d of contactListAll) {
            // console.log("select",d.select);
            if(!d.select){
                allselectcheck = false
            }
        }
        if(allselectcheck){
            this.setState({rowchecked: true})
        }
    }

    selectContactAll = (event) => {
        let rowchecked = this.state.rowchecked;
        rowchecked = rowchecked ? false : true;        
        let contactListAll = this.state.selectUseridarray;         
        
       if(rowchecked) {   
            let i = 0;            
            let selectArray = [];
            for(let d of contactListAll) {                
                let selectObject = {}           
                i++;   
                let val = 1;            
                this.state.sendContactDistribution[d.user_id] = val;                          
                selectObject.user_id = d.user_id;
                selectObject.company_id = d.company_id;
                selectObject.email = d.email;
                selectObject.name = d.name; 
                selectObject.role = d.role;                
                selectObject.select = true;                                
                selectArray.push(selectObject)
            }
            selectArray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} ); 
            this.setState({rowchecked: rowchecked,selectUseridarray: selectArray, sendContactDistribution: this.state.sendContactDistribution})
       }else{
            let i = 0;            
            let selectArray = [];
            for(let d of contactListAll) {                
                let selectObject = {}           
                i++; 
                delete this.state.sendContactDistribution[d.user_id.toString()];                            
                selectObject.user_id = d.user_id;
                selectObject.company_id = d.company_id;
                selectObject.email = d.email;
                selectObject.name = d.name; 
                selectObject.role = d.role;                
                selectObject.select = false;                                
                selectArray.push(selectObject)
            }
            selectArray.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
            this.setState({rowchecked: rowchecked,selectUseridarray: selectArray, sendContactDistribution: this.state.sendContactDistribution})
       }
   }

    processMyContactListData = () => {            
        var contactList = this.state.selectUseridarray;      
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[{'Header' : <div><input type="checkbox" checked={this.state.rowchecked} onClick={this.selectContactAll}/></div>,sortable: false, Cell: row => (
                        <div>
                            <input type="checkbox" checked={row.original.select} value={row.index} onClick={this.selectContact}/>
                        </div>
                    ), width: 142, headerStyle: {textAlign: 'left'}},         
                    {Header: 'Contact', accessor: 'name_value', minWidth: 100, headerStyle: {textAlign: 'center'}}];         
                      
        for(let d of contactList) {           
            i++;
            let elem = {'index' : i,
                    'id': d.user_id, 
                    'name_value' : d.name,
                    'select' : d.select                    
                    };
            ret.data.push(elem);
        
        }
        
        return ret;
    }

    submitDistributionList = (event) => {
        event.preventDefault();
        console.log("selectUseridarray==>", this.state.selectUseridarray)
        console.log("sendContactDistribution==>", this.state.sendContactDistribution)
        let selectedUser = [];
        for(let k of this.state.selectUseridarray){
            if(k.select){
                selectedUser.push(k)
            }
        }
        this.props.updatedSelectedUsers(selectedUser,this.state.sendContactDistribution)
    }

	render()
	{
		if (!this.state.ready) {
			return (<div>Loading...</div>);
		}

		return (            
			<EmailContainer>
                <MRModalHeader>
                    <MRModalHeaderText></MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>                
                </MRModalHeader>            
                <MRModalBody>  
                    <div style={{marginTop: "20px"}}>
                        <OverviewInfoHeader>
                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Select contact(s) you want to include in this distribution list</div>                                                                              
                            <div style={{clear: "both"}}></div>
                        </OverviewInfoHeader>
                        <OverviewInfoBody>                                        
                            <CSLTable add={false} processData={this.processMyContactListData} headerText={''} tableRows={10}/>
                        </OverviewInfoBody>
                    </div>
                                
                    <div style={{float: "right",marginTop: "20px"}}>
                        <CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
                        <SaveBtn onClick={this.submitDistributionList}>Submit</SaveBtn>
                    </div>
                </MRModalBody>                
                <div style={{clear: "both"}}></div> 
			</EmailContainer>
            
		);
	}
}

export default AddMoreUserModal;