import React, { Component } from "react";
import "./Dropzone.css";
import styled from 'styled-components';
import {FiDownload} from 'react-icons/fi'
import {MdClear} from 'react-icons/md'
import { saveAs } from 'file-saver';
import * as Style from './StyledComponents';
import APICall from '../../Common/APICall';
import AlertBox from './AlertBox.js';
import Store from '../../Common/Store.js';

const FileContainer = styled.div`
    width: 300px;
    display: flex;
`;
const FileInfoContainer = styled.div`
    margin-left:10px;
    color: #969EA2;
    width: 220px;
    height: 70px;
`;
const FileTypeContainer = styled.div`
    width: 100px;
    min-height:70px;
    height: 70px;
    background-color: #fff;
    color: #969EA2;
    font-size:22px;
    text-align:center;
    padding-top:22px;
    font-weight:800;
    border: 1px solid #AA9EA2;
`;
const AttachmentButton = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #37ADA7;
    border: 1px solid #37ADA7;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    &:hover {
      background-color: #2e948f;
      border-color: #2e948f;
    }
`;

class DropzoneQuestionset extends Component {

  state = {files: [], bin_files: [], files_to_show: [], addnew: true, alert_param: null};
  binFiles = [];
  numFiles = 0;
  prevNumFiles = 0;
  numFilesLoading = 0;

  constructor(props) {
    super(props);
    this.state = { hightlight: false, got_file: 0, files: [], files_to_show: [], alert_param: null};
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  updateProgress = (evt) => {
    if (evt.lengthComputable) {
      var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
      //console.log('percentLoaded',percentLoaded);
    }
  }

  readBinaryFile = (file) => {
    let reader = new FileReader();
    let that = this;
    console.log("Sourav2222",that.binFiles)
    that.binFiles.forEach((tfile,index) => {
      //console.log("tfile.uid",typeof tfile.uid)
      if(typeof tfile.uid === 'undefined'){
        that.binFiles.splice(index,1);
      }
    })
    reader.onprogress = this.updateProgress;
    reader.onload = (function(theFile) {
      return function(e) {
        // let processed_bin = e.target.result.replace(/\n/g,'');
        let fElement = {uid: that.genKey(10), name: theFile.name, bin_file: window.btoa(e.target.result).replace(/\n/g,'')};
        that.binFiles.push(fElement);
        that.numFiles++;
      };
    })(file);

    reader.readAsBinaryString(file);
  }

  checkFilesUploading = () => {
    if(this.numFiles < this.prevNumFiles + this.numFilesLoading){
      setTimeout(this.checkFilesUploading, 500);
    } else {
      let lp = Store.getStoreData('language_pack');
      let msg = lp['file_uploaded_popup_text'] === undefined ? 'New files loaded. You may safely save your form now.' : lp['file_uploaded_popup_text'];
      //alert('New files loaded. You may safely save your form now.');
      //console.log('this.binFiles', this.binFiles);
      let alert_param = {title: lp['alert_popup_title'] === undefined ? 'Alert' : lp['alert_popup_title'], message: msg, ok_text: 'Ok',cancel_text: 'No', confirm: false, alertHandler: this.processFilesUploading, stack: {} };
      if(this.numFilesLoading !== 0){
        this.setState({alert_param: alert_param});
      }
      /*let files_to_show = JSON.parse(JSON.stringify(this.state.bin_files));
      this.binFiles.forEach((tfile) => {
        files_to_show.push(tfile);
      })
      //console.log("Sourav111",this.binFiles)
      this.props.onFilesAdded(this.binFiles);
      this.setState({files_to_show});*/
    }
  }
  processFilesUploading = (result , stack) => {
		this.setState({alert_param: null} , ()=>{
			if(result === false) return;
			let files_to_show = JSON.parse(JSON.stringify(this.state.bin_files));
      // console.log("files_to_show+++>", files_to_show)
      // console.log("files_to_show+++>", this.binFiles)
      let files_to_show_object = {};
      for(let d of files_to_show){
        files_to_show_object[d.uid] = d
      }
      this.binFiles.forEach((tfile) => {
        files_to_show_object[tfile.uid] = tfile;
      })
      let up_file_to_Show = []
      for(let k in files_to_show_object){
        up_file_to_Show.push(files_to_show_object[k])
      }
      // this.binFiles.forEach((tfile) => {
      //   files_to_show.push(tfile);
      // })
      // console.log("3==>", files_to_show)
      // console.log("4++>", this.binFiles)
      // let binfile_update = []
      // for(let d of this.binFiles){
      //   if(!(d.uid in files_to_show_object)){
      //     binfile_update.push(d)
      //   }
      // }

      console.log("binfile_update==>", up_file_to_Show)
      this.props.onFilesAdded(this.binFiles, false, '');
      this.setState({files_to_show: up_file_to_Show});
		});
	}

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    console.log("files.length",files.length)
    let tempFiles = this.state.files;
    this.numFilesLoading = files.length;
    this.prevNumFiles = this.numFiles;
    setTimeout(this.checkFilesUploading, 500);

    for (var i = 0; i < files.length; i++) {
      tempFiles.push(files.item(i));
      this.readBinaryFile(files.item(i));
      //console.log("this.binFiles", this.binFiles);
    }
  }

  onDragOver(evt) {
    evt.preventDefault();

    if (this.props.disabled) return;

    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();

    if (this.props.disabled) return;

    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array, false, '');
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  handleRemove = (uid) => {
    // console.log('remove index', index);
    let tempFiles = JSON.parse(JSON.stringify(this.state.files));
    let newtempFiles = [];
    let val_object = {}
    this.binFiles.forEach((tfile) => {
      if (tfile.uid !== uid) {
        newtempFiles.push(tfile);
        val_object[tfile.uid] = tfile
      }
    });
    this.binFiles = newtempFiles;
    console.log("this.binFiles",this.binFiles.length)
    // tempFiles.splice(index,1);
    let files_to_show = JSON.parse(JSON.stringify(this.state.bin_files));
    console.log("files_to_show==>", files_to_show)
    let up_files_to_show = []
    for(let d of files_to_show){
      if (d.uid !== uid) {
        console.log("Sourav")
        up_files_to_show.push(d)
        val_object[d.uid] = d
      }
    }
    console.log("up_files_to_show===>", up_files_to_show)
    newtempFiles.forEach((tfile) => {
      up_files_to_show.push(tfile);
    });
    let val_array = []
    for(let k in val_object){
      val_array.push(val_object[k])
    }
    console.log("files_to_show_update===>", val_array)
    this.setState({files: newtempFiles, files_to_show: val_array, bin_files: up_files_to_show},
      ()=>{this.props.onFilesAdded(this.binFiles, true, uid)}
      );
  }

  // handleRemove = (uid) => {
  //   console.log('uid', uid);
  //   let tempFiles = JSON.parse(JSON.stringify(this.state.files));
  //   let newtempFiles = [];
  //   this.binFiles.forEach((tfile) => {
  //     if (tfile.uid !== uid) {
  //       newtempFiles.push(tfile);
  //     }
  //   });
  //   this.binFiles = newtempFiles;
  //   console.log("this.binFiles",this.binFiles.length)
  //   // tempFiles.splice(index,1);
  //   let files_to_show = JSON.parse(JSON.stringify(this.state.bin_files));
  //   let files_to_show_update = [];
  //   let update_existing_file_json = [];
  //   files_to_show.forEach((tfile) => {
  //     if (tfile.uid !== uid) {
  //       files_to_show_update.push(tfile);
  //       update_existing_file_json.push(tfile)
  //     }
  //   });
  //   newtempFiles.forEach((tfile) => {
  //     files_to_show_update.push(tfile);
  //   });
  //   // if(files_to_show.length !== update_existing_file_json.length){
  //   //   this.props.onExitsFilesUpdated(update_existing_file_json)
  //   // }
  //   console.log("files_to_show_update===>", files_to_show_update)
  //   this.setState({files: newtempFiles, files_to_show: files_to_show_update, bin_files: files_to_show_update}, ()=>{this.props.onFilesAdded(this.binFiles, true)});
  // }

  handleDownload_old = (index) => {
    // console.log('download index', index);
    if(this.numFiles < this.prevNumFiles + this.numFilesLoading) {
      alert('Please wait, the files are still loading!');
      return;
    }
    // console.log('binfile',this.binFiles[index]);
    var binaryString =window.atob(this.binFiles[index].bin_file);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, this.binFiles[index].name);
  }

  handleDownload = (uid) => {
    console.log("uid===>", uid)
    console.log("this.props.qsetbinfiles_id===>", this.props.qsetbinfiles_id)
    if('downloadThroughApi' in this.props && this.props.downloadThroughApi){
      let api = new APICall();
      let postData = { command: "download_questionset_attachment", image_id: uid, task_id: this.props.qsetbinfiles_id };
      console.log("postData", postData);
      api.command(postData, this.processDownloadFile(uid));
    }else{
      let dbinfile = this.state.files_to_show.find(item => item.uid === uid);
      let dnld = dbinfile.bin_file.replace(/ /g, '+');
      let binaryString = window.atob(dnld);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
      }
      var blob = new Blob([bytes]);
      saveAs(blob, dbinfile.name);
      if('saveDownloadData' in this.props && this.props.saveDownloadData === true){
        this.props.updateDownloadData(uid)
      }
    }
  }

  processDownloadFile = (uid) => (result) => {
    console.log("Attachment", result, uid);
    let dbinfile = result.result;
    let dnld = dbinfile.bin_file.replace(/ /g, "+");
    let binaryString = window.atob(dnld);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, dbinfile.name);
    if('saveDownloadData' in this.props && this.props.saveDownloadData === true){
      this.props.updateDownloadData(uid)
    }
  };

  componentDidMount() {
    let existing_files = JSON.parse(JSON.stringify(this.props.initFiles ?? []));
    // existing_files.forEach((efile) => {
    //   efile.removable = false;
    // })
    let addnew = true;
    if ("addnew" in this.props) {
      addnew = this.props.addnew;
    }
    console.log("Sourav===>", existing_files)
    this.setState({bin_files: existing_files, files_to_show: existing_files, files: [], addnew});
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps !== this.props) {
  //     let existing_files = this.props.initFiles === null ? [] : JSON.parse(JSON.stringify(this.props.initFiles));
  //     existing_files.forEach((efile) => {
  //       efile.removable = false;
  //     })
  //     this.setState({bin_files: existing_files, files_to_show: existing_files});
  //   }
  // }

  genKey = (length) => {
     var result           = '';
     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
     var charactersLength = characters.length;
     for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
     return result;
  }

  render() {
    //console.log('state in render', this.state);

    return (
      <div>
        {
          (() => {
                  if(this.state.files_to_show.length > 0) {
                    return (
                            <div style={{width:'100%'}}>
                            {
                              this.state.files_to_show.map((f, index) => {
                                //console.log("f", f);
                                const file_parts = f.name.split(".");
                                let ext = "PDF";
                                switch(file_parts[file_parts.length - 1]) {
                                  case 'jpeg':
                                  case 'jpg' : ext = 'JPG';break;
                                  case 'png' : ext = 'PNG'; break;
                                  case 'PNG' : ext = 'PNG'; break;
                                  //case 'docx' : ext = 'DOCX'; break;
                                  case 'docx' : ext = 'DOC'; break;
                                  case 'doc' : ext = 'DOC'; break;
                                  case 'msg' : ext = 'MSG'; break;
                                  case 'txt' : ext = 'TXT'; break;
                                  case 'ppt' : ext = 'PPT'; break;
                                  //case 'pptx' : ext = 'PPTX'; break;
                                  case 'pptx' : ext = 'PPT'; break;
                                  case 'xls' : ext = 'XLS'; break;
                                  //case 'xlsx' : ext = 'XLSX'; break;
                                  case 'xlsx' : ext = 'XLS'; break;
                                  default: ext = 'PDF'; break;
                                }
                                //console.log('ext', ext);
                                let file_icon_var = ext === 'DOCX' ? 'DOC' : ext;
                                file_icon_var = ext === 'XLSX' ? 'XLS' : ext;
                                const file_icon = `/dropdownImages/${ext}.png`;
                                //console.log("file_icon==>", file_icon)
                                return (
                                  <div key={index} style={{width: "50%", float: "left", boxSizing: "border-box", padding: index === 0 || index%2 === 0 ? "10px 10px 10px 0px" : "10px 0px 10px 10px"}}>
                                    <div style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
                                        <div style={{float: "left", padding: "10px", boxSizing: "border-box"}}>
                                          <img src={file_icon} height="100px" width="auto" />
                                          {/*<FileTypeContainer>{ext}</FileTypeContainer>*/}
                                        </div>
                                        <div style={{float: "left", padding: "10px", boxSizing: "border-box"}}>
                                          <div><strong title={f.name} style={{color: '#000000'}}>{f.name.length > 20 ? f.name.substring(0, 20) : f.name}</strong><br /><br /></div>
                                          <div style={{cursor: "pointer"}} onClick={() => this.handleDownload(f.uid)}><FiDownload /> Download</div>
                                          {
                                            (() => {
                                              if (!("removable" in f)) {
                                                return (<div style={{cursor: "pointer", marginTop: "10px"}} onClick={() => this.handleRemove(f.uid)}><MdClear /> Remove</div>);
                                              }
                                            })()
                                          }
                                        </div>
                                        <div style={{clear: "both"}}></div>
                                    </div>
                                  </div>
                                );
                              })
                            }
                            <div style={{clear: "both"}}></div>
                            </div>
                      )
                  }
          })()
        }
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          accept=".pdf,.jpg,.jpeg,.png,.docx,.doc,.msg,.txt,.ppt,.pptx,.xls,.xlsx"
          onChange={this.onFilesAdded}
        />
        {
          (() => {
            //console.log("Dropzone",this.state.addnew)
            if (this.state.addnew) {
              return (
                <div style={{paddingTop: "20px"}}>
                  <AttachmentButton onClick={this.openFileDialog}>Add an attachment</AttachmentButton>
                </div>
              );
            }
          })()
        }
        <AlertBox alertParam = {this.state.alert_param}/>
      </div>
    );
  }
}

export default DropzoneQuestionset;
