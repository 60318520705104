import React from "react";
import styled from "styled-components";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import wNumb from "wnumb";
import { IoIosTrash } from "react-icons/io";
import { FaPencilAlt, FaSave, FaTimes, FaPlusCircle } from "react-icons/fa";

const EmailContainer = styled.div`
  width: 90%;
`;
const EmailInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 20px 45px;
`;
const EmailBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;
const EmailLabel = styled.div`
  color: #595959;
  margin-top: 5px;
`;
const GeneralsubLabel = styled.div`
  font-weight: 400;
  color: #595959;
  margin-top: 20px;
`;
const ConfigBtn = styled.button`
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  border: 1px solid #37ada7;
  font-size: 13px;
  border-radius: 4px;
  padding: 5px 20px;
  cursor: pointer;
`;
const SelectedRadio = styled.div`
  background-color: #37ada7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
`;
const UnselectedRadio = styled.div`
  background-color: #f4f4f4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
`;
const RadioBlock = styled.div`
  margin-top: 10px;
`;
const MRModalInput = styled.input`
  height: 25px;
  width: 25%;
  box-shadow: rgb(196, 196, 196) 0px 0px 4px;
  color: #545454;
  font-family: montserrat;
  font-size: 12px;
  font-weight: 600;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  border-radius: 3px;
  padding: 6px;
`;

const ReminderGroupHead = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 0.26rem 45px 0.26rem 30px;
`;

class Reminders extends React.Component {
  state = { reminders: null };  

  componentDidMount() {    
    this.setState({ reminders: this.props.reminders });    
  }

  componentDidUpdate(prevProps) {    
    if (prevProps !== this.props) {
      this.setState({ reminders: this.props.reminders });
    }
    
  }  

  switchRadio = (key) => (event) => {
    event.preventDefault();
    let { reminders } = this.state;
    reminders[key] = reminders[key] ? false : true;
    this.setState({ reminders });
    this.props.updateReminder(reminders);
  };  

  onChangeInterval = (id, type) => (values, handle) => {
    // let value = Math.floor(parseFloat(values[handle]))
    // let {remainders} = this.state;
    // let newremainders = [];
    // remainders.remiders_value.forEach((item) => {
    // 	if (item.id === id) {
    // 		item.remiders_days = value;
    // 		console.log("matched", item);
    // 	}
    // 	newremainders.push(item);
    // })
    // remainders.remiders_value = newremainders;
    // this.props.updateReminderSlider(remainders);
    let { reminders } = this.state;
    let newreminders;
    let value;
    switch (type) {
      case "advance":
        newreminders = [];
        value = Math.floor(parseFloat(values[handle]));
        reminders.advance_reminders_values.forEach((item) => {
          if (item.id === id) {
            item.reminders_days = value;
          }
          newreminders.push(item);
        });
        reminders.advance_reminders_values = newreminders;
        break;
      case "overdue":
        newreminders = [];
        value = Math.floor(parseFloat(values[handle]));
        reminders.remiders_value.forEach((item) => {
          if (item.id === id) {
            item.remiders_days = value;
          }
          newreminders.push(item);
        });
        reminders.remiders_values = newreminders;
        break;
      default:
        console.log("error occured out of switched context!");
    }
    this.props.updateReminderSlider(reminders);
  };

  editReminder = (id, type) => (event) => {
    event.preventDefault();
    //    let {remainders} = this.state;
    // let newremainders = [];
    //      remainders.remiders_value.forEach((item) => {
    // 	if (item.id === id) {
    // 		item.isedit = 1;
    // 		console.log("matched", item);
    // 	}
    // 	newremainders.push(item);
    // })
    //      remainders.remiders_value = newremainders;
    //      this.setState({remainders: remainders});

    // console.log('REMINDER EDIT ===> ', type)

    let { reminders } = this.state;
    let newreminders;
    switch (type) {
      case "advance":
        newreminders = [];
        reminders.advance_reminders_values.forEach((item) => {
          if (item.id === id) {
            item.isedit = 1;
          }
          newreminders.push(item);
        });
        reminders.advance_reminders_values = newreminders;
        break;
      case "overdue":
        newreminders = [];
        reminders.remiders_value.forEach((item) => {
          if (item.id === id) {
            item.isedit = 1;
          }
          newreminders.push(item);
        });
        reminders.remiders_values = newreminders;
        break;
      default:
        console.log("error occured out of switched context!");
    }
    this.setState({ reminders });
    console.log(reminders);
  };

  handleChange = (id, type) => (evt) => {
    //      console.log("evt",evt.target.value)
    //      let {remainders} = this.state;
    // let newremainders = [];
    // remainders.remiders_value.forEach((item) => {
    // 	if (item.id === id) {
    // 		item.name = evt.target.value;
    // 		console.log("matched", item);
    // 	}
    // 	newremainders.push(item);
    // })
    // remainders.remiders_value = newremainders;
    // this.setState({remainders: remainders});
    let { reminders } = this.state;
    let newreminders;
    switch (type) {
      case "advance":
        newreminders = [];
        reminders.advance_reminders_values.forEach((item) => {
          if (item.id === id) {
            item.name = evt.target.value;
          }
          newreminders.push(item);
        });
        reminders.advance_reminders_values = newreminders;
        break;
      case "overdue":
        newreminders = [];
        reminders.remiders_value.forEach((item) => {
          if (item.id === id) {
            item.name = evt.target.value;
          }
          newreminders.push(item);
        });
        reminders.remiders_values = newreminders;
        break;
      default:
        console.log("error occured out of switched context!");
    }
    this.setState({ reminders });
  };

  saveReminderName = (id, type) => (event) => {
    event.preventDefault();
    //      let {remainders} = this.state;
    // let newremainders = [];
    // remainders.remiders_value.forEach((item) => {
    // 	if (item.id === id) {
    // 		item.isedit = 0;
    // 		console.log("matched", item);
    // 	}
    // 	newremainders.push(item);
    // })
    // remainders.remiders_value = newremainders;
    //      this.props.updateReminder(remainders);

    let { reminders } = this.state;
    let newreminders;
    switch (type) {
      case "advance":
        newreminders = [];
        reminders.advance_reminders_values.forEach((item) => {
          if (item.id === id) {
            item.isedit = 0;
          }
          newreminders.push(item);
        });
        reminders.advance_reminders_values = newreminders;
        break;
      case "overdue":
        newreminders = [];
        reminders.remiders_value.forEach((item) => {
          if (item.id === id) {
            item.isedit = 0;
          }
          newreminders.push(item);
        });
        reminders.remiders_values = newreminders;
        break;
      default:
        console.log("error occured out of switched context!");
    }
    this.setState({ reminders });
    this.props.updateReminder(reminders);
  };

  deleteReminder = (id, type) => (event) => {
    event.preventDefault();
    //      let {remainders} = this.state;
    // let newremainders = [];
    // remainders.remiders_value.forEach((item) => {
    // 	if (item.id !== id) {
    // 		newremainders.push(item);
    // 	}
    // })
    // remainders.remiders_value = newremainders;
    //      this.props.updateReminder(remainders);
    let { reminders } = this.state;
    let newreminders;
    switch (type) {
      case "advance":
        newreminders = [];
        reminders.advance_reminders_values.forEach((item) => {
          if (item.id !== id) {
            newreminders.push(item);
          }
        });
        reminders.advance_reminders_values = newreminders;
        break;
      case "overdue":
        newreminders = [];
        reminders.remiders_value.forEach((item) => {
          if (item.id !== id) {
            newreminders.push(item);
          }
        });
        reminders.remiders_value = newreminders;
        break;
      default:
        console.log("error occured out of switched context!");
    }
    this.setState({ reminders });
    this.props.updateReminder(reminders);
  };

  addReminder = (event, type) => {
    event.preventDefault();
    //      let {remainders} = this.state;
    //      let randNo = Math.floor(Math.random()*(9999-1000+1)+1000);
    //      let obj = {
    //          id: randNo,
    //          name: "Custom Reminder",
    //          remiders_days: 1,
    //          isdeleted: 1,
    //          isedit: 0
    //      }
    // remainders.remiders_value.push(obj);
    //      this.props.updateReminder(remainders);

    let { reminders } = this.state;
    let randNo;
    let obj = {
      id: null,
      name: "Custom Reminder",
      reminders_days: 1,
      isdeleted: 1,
      isedit: 0,
    };
    switch (type) {
      case "advance":
        randNo = Math.floor(Math.random() * (9999 - 1000 + 1) + 1000);
        reminders.advance_reminders_values.push({
          id: randNo,
          name: "Custom Reminder",
          reminders_days: 1,
          isdeleted: 1,
          isedit: 0,
        });
        break;
      case "overdue":
        randNo = Math.floor(Math.random() * (9999 - 1000 + 1) + 1000);
        reminders.remiders_value.push({
          id: randNo,
          name: "Custom Reminder",
          remiders_days: 1,
          isdeleted: 1,
          isedit: 0,
        });
        break;
      default:
        console.log("error occured out of switched context!");
    }
    this.setState({ reminders });
    this.props.updateReminder(reminders);    
  };

  render() {
    if (this.state.reminders === null) {
      return <div>Loading...</div>;
    }
    let tooltips = [wNumb({ decimals: 0, suffix: "days" })];
    return (
      <div>
        <EmailContainer>
          <EmailInnerContainer>
            <EmailBoldLabel style={{ display: "inline-block" }}>
              Enable Reminders?
            </EmailBoldLabel>
            {
              (() => {
                if (this.state.reminders.remainder_enabled) {
                  return (
                    <RadioBlock>
                      <SelectedRadio>Yes</SelectedRadio>
                      <UnselectedRadio onClick={this.switchRadio("remainder_enabled")}>No</UnselectedRadio>
                    </RadioBlock>
                  );
                } else {
                  return (
                    <RadioBlock>
                      <UnselectedRadio onClick={this.switchRadio("remainder_enabled")}>Yes</UnselectedRadio>
                      <SelectedRadio>No</SelectedRadio>
                    </RadioBlock>
                  );
                }
              })()
            }
          </EmailInnerContainer>
          {
            (() => {
              if (this.state.reminders.remainder_enabled) {
                return (
                  <div style={{ background: "#ffffff" }}>
                    <div>                    
                      <ReminderGroupHead>
                        <div style={{fontWeight: "800",display: "flex",alignItems: "center",justifyContent: "space-between"}}>Advance Reminders
                          <div style={{cursor: "pointer",fontSize: "17px",color: "#04ADA8"}} ><FaPlusCircle onClick={(e) => this.addReminder(e, "advance")}/> </div>
                        </div>
                        <div style={{float: "left",fontWeight: "600",color: "#595959",marginTop: "5px"}}> Email 'Attestation Reminder' will be sent in advance of an attestation becoming due, please set reminders as required (Note these are caleder days)</div>
                    </ReminderGroupHead>
                    {this.state.reminders.advance_reminders_values.map((resultItem, index) => {
                        return (
                          <div key={index}>
                            <EmailInnerContainer>
                              {(() => {
                                if (resultItem.isedit === 0) {
                                  return (
                                    <EmailBoldLabel>
                                      {resultItem.name}
                                      <FaPencilAlt
                                        onClick={this.editReminder(
                                          resultItem.id,
                                          "advance"
                                        )}
                                        style={{
                                          marginLeft: "20px",
                                          fontSize: "15px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </EmailBoldLabel>
                                  );
                                } else {
                                  return (
                                    <div>
                                      <MRModalInput
                                        type="text"
                                        name="remindername"
                                        value={resultItem.name}
                                        onChange={this.handleChange(
                                          resultItem.id,
                                          "advance"
                                        )}
                                      />
                                      <FaSave
                                        onClick={this.saveReminderName(
                                          resultItem.id,
                                          "advance"
                                        )}
                                        style={{
                                          marginLeft: "20px",
                                          fontSize: "20px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  );
                                }
                              })()}

                              <div
                                style={{
                                  paddingTop: "40px",
                                  width: "95%",
                                  float: "left",
                                }}
                              >
                                <Nouislider
                                  range={{ min: 0, max: 30 }}
                                  start={resultItem.reminders_days}
                                  step={1}
                                  connect={[true, false]}
                                  tooltips={tooltips}
                                  onChange={this.onChangeInterval(
                                    resultItem.id,
                                    "advance"
                                  )}
                                />
                              </div>
                              {(() => {
                                if (resultItem.isdeleted !== 0) {
                                  return (
                                    <div
                                      style={{
                                        paddingTop: "35px",
                                        float: "right",
                                        fontSize: "25px",
                                      }}
                                    >
                                      <IoIosTrash
                                        onClick={this.deleteReminder(
                                          resultItem.id,
                                          "advance"
                                        )}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  );
                                }
                              })()}
                              <div style={{ clear: "both" }}></div>
                            </EmailInnerContainer>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div
                    style={{
                      margin: "2rem 30px 0",
                      height: "2rem",
                      backgroundColor: "#4473C5",
                    }}
                  ></div>
                  <div>
                    {/*Overdue Reminder*/}
                    {/*<div style={{float: "right", cursor: "pointer",fontSize: "17px",color: "#04ADA8",marginRight: "35px"}}>
                                        <FaPlusCircle onClick={(e)=> this.addReminder(e, 'overdue')}/>
                                    </div>*/}
                    <ReminderGroupHead>
                      <div
                        style={{
                          fontWeight: "800",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        Overdue Reminders
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            fontSize: "17px",
                            color: "#04ADA8",
                          }}
                        >
                          <FaPlusCircle
                            onClick={(e) => this.addReminder(e, "overdue")}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          float: "left",
                          fontWeight: "600",
                          color: "#595959",
                          marginTop: "5px",
                        }}
                      >
                        Email 'Attestation Overdue' will be sent once an
                        attestation becomes overdue, please set reminders as
                        required (Note these are caleder days)
                      </div>
                    </ReminderGroupHead>
                    {this.state.reminders.remiders_value.map(
                      (resultItem, index) => {
                        return (
                          <div key={index}>
                            <EmailInnerContainer>
                              {(() => {
                                if (resultItem.isedit === 0) {
                                  return (
                                    <EmailBoldLabel>
                                      {resultItem.name}
                                      <FaPencilAlt
                                        onClick={this.editReminder(
                                          resultItem.id,
                                          "overdue"
                                        )}
                                        style={{
                                          marginLeft: "20px",
                                          fontSize: "15px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </EmailBoldLabel>
                                  );
                                } else {
                                  return (
                                    <div>
                                      <MRModalInput
                                        type="text"
                                        name="remindername"
                                        value={resultItem.name}
                                        onChange={this.handleChange(
                                          resultItem.id,
                                          "overdue"
                                        )}
                                      />
                                      <FaSave
                                        onClick={this.saveReminderName(
                                          resultItem.id,
                                          "overdue"
                                        )}
                                        style={{
                                          marginLeft: "20px",
                                          fontSize: "20px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  );
                                }
                              })()}

                              <div
                                style={{
                                  paddingTop: "40px",
                                  width: "95%",
                                  float: "left",
                                }}
                              >
                                <Nouislider
                                  range={{ min: 0, max: 30 }}
                                  start={resultItem.remiders_days}
                                  step={1}
                                  connect={[true, false]}
                                  tooltips={tooltips}
                                  onChange={this.onChangeInterval(
                                    resultItem.id,
                                    "overdue"
                                  )}
                                />
                              </div>
                              {(() => {
                                if (resultItem.isdeleted !== 0) {
                                  return (
                                    <div
                                      style={{
                                        paddingTop: "35px",
                                        float: "right",
                                        fontSize: "25px",
                                      }}
                                    >
                                      <IoIosTrash
                                        onClick={this.deleteReminder(
                                          resultItem.id,
                                          "overdue"
                                        )}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  );
                                }
                              })()}
                              <div style={{ clear: "both" }}></div>
                            </EmailInnerContainer>
                          </div>
                        );
                      }
                    )}

                    {/*Overdue Reminder*/}
                  </div>
                </div>
              );
            }
          })()}
        </EmailContainer>
      </div>
    );
  }
}

export default Reminders;
