import React from "react";
import styled from "styled-components";
import Store from "./Common/Store.js";
import APICall from "./Common/APICall.js";
import APIContact from "./Common/APIContact";
import Utils from "./Common/Utils.js";
import ComDecom from "./Common/ComDecom.js";
import Index from "./Components/Index";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ModuleConfigHOC from "./Components/Moduleconfig/ModuleConfigHOC";
import QuestionsManagerHOC from "./Components/Moduleconfig/QuestionsManagerHOC";
import CSLLoader from "./Components/Common/CSLLoader";
import AlertBox from "./Components/Common/AlertBox";
import CSLPageloader from "./Components/Common/CSLPageloader";
import authService from "mcc-front-aux/dist/authService";


import Reload from './Common/Reload.js';
import EventBus from './Common/EventBus.js';

const moment = require('moment');
const last_action_time = moment().unix()
class AppContent extends React.Component {
  state = {
    OverlayHeight: null,
    cardata: null,
    ready: false,
    OverlayTaskHeight: null,
    role: "noperm",
    refreshCount: 0,
    alert_param: null,
    task_loading: false,
  };
  constructor(props) {
    super(props);
    Utils.setEnv("attest");
    this.callContact();
  }

  callContact() {
    if (!Store.getStoreData("is_admin")) {
      let postData = { command: "contactlist", is_lp: true };
      let api = new APIContact();
      let j = api.command(postData, this.processContactData);
    }
  }

  processContactData = (result) => {
    let cl = ComDecom.deCompress(result.result['contactlist'], true, 'ID');
    result.result['contactlist'] = cl
    localStorage.setItem("contactlist", JSON.stringify(result));
    localStorage.setItem("language_pack", JSON.stringify(result.lang_pack));
  };

  processIndex = (result) => {
    if (result.error_code === 0) {
      if (Store.getStoreData("is_admin")) {
      } else {
        console.log('tasks', result);
        if(result.result['role'] === "no_access") {
          let alert_param = {title: 'No Access',
                             message: result.error_msg,
                             ok_text: 'OK',
                             confirm: false,
                             alertHandler: this.alertHandler, stack: {id: 0}};
          this.setState({ ready: true, alert_param: alert_param, task_loading: false});
          return
        }
        Store.updateStore("cardata", result.result);
        Store.updateStore("role", result.result["role"]);
        Store.updateStore("contact_id", result.result["contact_id"]);
        Store.updateStore("curCompanyID", result.result["curCompanyID"]);
        Store.updateStore("gc_company", result.result["gc_company"]);
        Store.updateStore("gc_companies", result.result["gc_companies"]);
        Store.updateStore("module_config", result.result["module_config"]);
        Store.updateStore("mytasks", result.result["my_tasks"]);
        Store.updateStore("mypendingtask", result.result["pending_task"]);
        Store.updateStore("mycompletetask", result.result["completed_task"]);
        Store.updateStore("data_card_value", result.result["data_card_value"]);
        Store.updateStore("followupcount", result.result["followupCount"]);
        Store.updateStore("followuptask", result.result["followup_tasks"]);
        //userStroage Section
        Store.updateStore("userroles", result.result["user_roles"]);
        Store.updateStore("contact", result.result["contact"]);
        Store.updateStore("contacts", result.result["contacts"]);
        Store.updateStore("contactsemail", result.result["contractsemail"]);
        Store.updateStore("reviewee_list", result.result.listcontacts);
        Store.updateStore("attestation_share_group_allusers", result.result["attestation_share_group_allUser"]);
        Store.updateStore("allow_am_edit_qsets", result.result["module_config"].general.allow_am_stp_edit_ques_sets)
        //userStroage Section

        //raiseEvent Start
        let menu = {};
        if(result.result["role"] === 'admin_manager' || result.result["role"] === 'manager'){
          menu['23gbrsfh432'] = {"id":"23gbrsfh432","display":"Questions Manager","url":"microservice/mstop/attest/questionsmanager","order":3,"menu":"questionsmanager", source: "attest"}
        }else if(result.result["role"] === 'am' && 'general' in result.result["module_config"] && result.result["module_config"].general.allow_am_stp_edit_ques_sets === true){
            menu['23gbrsfh432'] = {"id":"23gbrsfh432","display":"Questions Manager","url":"microservice/mstop/attest/questionsmanager","order":3,"menu":"questionsmanager", source: "attest"}
        }
        //raiseEvent End
        console.log("menu===>", menu)
        EventBus.raiseEvent('menu_render', menu)

        let has_gc = result.result.gc_company.length > 1 ? true : false;
        Store.updateStore("has_gc", has_gc);

        let flagedObject = {};
        let flagedValue = result.result["flaged_task"];
        for (let d of flagedValue) {
          if (d[1] === true) {
            flagedObject[d[0]] = true;
            if (d[2] === true) {
              flagedObject[d[0]] = false;
            }
          }
        }

        Store.updateStore("flagged_count_object", flagedObject);

        let gc_assoc = {};
        let gc_users = {};
        let parent_users = {};
        for (let gc of result.result.gc_company) {
          let gc_id = gc.is_gc ? gc.id.toString() : "0";
          gc_assoc[gc_id] = gc.company_name;
          if (!gc.is_gc) {
            continue;
          }
          for (let uid in gc.users) {
            if (!(uid in gc_users)) gc_users[uid] = {};
            gc_users[uid][gc_id] = 1;
          }
        }
        for (let cid in result.result.contacts) {
          if (!(cid in gc_users)) parent_users[cid] = 1;
        }

        Store.updateStore("gc_assoc", gc_assoc);
        Store.updateStore("parent_users", parent_users);
        Store.updateStore("gc_users", gc_users);
        //console.log('parent_users, gc_users, gc_assoc', parent_users, gc_users, gc_assoc)

        let r = {};
        r["result"] = {};
        let cts = localStorage.getItem("contactlist") !== null ? JSON.parse(localStorage.getItem("contactlist")) : r;
        console.log("cts==>", cts);
        let user_roles = {};
        let contactNameValue = "";
        let contacts_all = {};
        let contractsemail_all = {};
        let listcontacts_all = [];
        let attestation_share_group_allUser_new = [];
        let contactid = result.result["contact_id"];
        if ("contactlist" in cts.result && "user_roles" in cts.result) {
          for (let d in cts.result.contactlist) {
            if(d in cts.result.user_roles["attest"]){
              user_roles[d.toString()] = cts.result.user_roles["attest"][d.toString()]
            }
          }
          //user_roles = cts.result.user_roles["attest"];
          //contactNameValue = cts.result.contactlist[contactid.toString()].ContactName;
          contactNameValue = cts.result.user_roles["attest"][contactid.toString()] !== undefined ? cts.result.contactlist[contactid.toString()].ContactName : "";
          for (let k in cts.result.contactlist) {
            let clists = cts.result.contactlist;
            if (user_roles[k.toString()] !== undefined && clists[k.toString()].IsActive === true && clists[k.toString()].IsDeleted === false) {
              contractsemail_all[k.toString()] = cts.result.contactlist[k.toString()].EmailAddress;
              let listConObject = {};
              listConObject.ID = cts.result.contactlist[k.toString()].ID;
              listConObject.ContactName = cts.result.contactlist[k.toString()].ContactName;
              listConObject.LoginID = cts.result.contactlist[k.toString()].EmailAddress;
              listcontacts_all.push(listConObject);
              if (cts.result.user_roles["attest"][k.toString()] !== "no_access") {
                contacts_all[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
                let shareGripuObject = {};
                shareGripuObject.company_id = result.result["curCompanyID"];
                shareGripuObject.email = cts.result.contactlist[k.toString()].EmailAddress;
                shareGripuObject.name = cts.result.contactlist[k.toString()].ContactName;
                shareGripuObject.role = cts.result.user_roles["attest"][k.toString()];
                shareGripuObject.user_id = cts.result.contactlist[k.toString()].ID;
                attestation_share_group_allUser_new.push(shareGripuObject);
              }
            }
          }
          console.log("user_roles", user_roles);
          Store.updateStore("contact", contactNameValue);
          Store.updateStore("userroles", user_roles);
          Store.updateStore("contacts", contacts_all);
          Store.updateStore("contactsemail", contractsemail_all);
          Store.updateStore("reviewee_list", listcontacts_all);
          Store.updateStore("attestation_share_group_allusers", attestation_share_group_allUser_new);
          let language_pack = !localStorage.getItem("language_pack") ? JSON.parse(localStorage.getItem("language_pack")) : {};
          Store.updateStore('language_pack', language_pack);

        }

        let ChildTasksObject = {};
        let childTasksValue = result.result["child_share_task"];
        Store.updateStore("child_share_task_all", childTasksValue);
        for (let d of childTasksValue) {
          if(d[9] in contacts_all){
            let data = {
              contact_id: d[2],
              cur_lane: d[3],
              acknowledgementDueDate: d[4],
              dueDate: d[5],
              Type: d[6],
              parent_task_id: d[7],
            };
            ChildTasksObject[d[0]] = data;
          }
        }
        Store.updateStore("child_tasks_all", ChildTasksObject);

        switch (result.result["role"]) {
          case "admin_manager":
            Store.updateStore("view", "manager_index");
            break;
          case "am":
            Store.updateStore("view", "am_index");
            break;
          case "no_access":
            Store.updateStore("view", "no_access");
            break;
          default:
            Store.updateStore("view", "team_index");
        }
        // this.setState({ ready: true });
        let task_id = this.props.match ? parseInt(this.props.match.params.taskId) : 0;
        //console.log("task id =======>", task_id);
        this.setState({
          ready: true,
          refreshCount: this.state.refreshCount + 1,
        });
      }
    } else {
      let alert_param = {
        title: "System Error",
        message: result.error_msg,
        ok_text: "OK",
        confirm: false,
        alertHandler: this.alertHandler,
        stack: { id: 0 },
      };
      this.setState({ ready: true, alert_param: alert_param });
    }
  };

  processtaskIndex = (result) => {
    if (result.error_code === 0) {
      if (Store.getStoreData("is_admin")) {
      } else {
        // console.log('tasks', result);
        if (result.result["role"] === "no_access") {
          let alert_param = {
            title: "No Access",
            message: result.error_msg,
            ok_text: "Ok",
            confirm: false,
            alertHandler: this.alertHandler,
            stack: { id: 0 },
          };
          this.setState({
            ready: true,
            alert_param: alert_param,
            task_loading: false,
          });
          return;
        }
        Store.updateStore("role", result.result["role"]);
        Store.updateStore("contact_id", result.result["contact_id"]);
        Store.updateStore("curCompanyID", result.result["curCompanyID"]);
        Store.updateStore("gc_company", result.result["gc_company"]);
        Store.updateStore("gc_companies", result.result["gc_companies"]);
        Store.updateStore("module_config", result.result["module_config"]);
        //userStroage Section
        Store.updateStore("userroles", result.result["user_roles"]);
        Store.updateStore("contact", result.result["contact"]);
        Store.updateStore("contacts", result.result["contacts"]);
        Store.updateStore("contactsemail", result.result["contractsemail"]);
        Store.updateStore("reviewee_list", result.result.listcontacts);
        Store.updateStore("attestation_share_group_allusers", result.result["attestation_share_group_allUser"]);
        //userStroage Section
        let has_gc = result.result.gc_company.length > 1 ? true : false;
        Store.updateStore("has_gc", has_gc);

        let gc_assoc = {};
        let gc_users = {};
        let gc_filters = {};
        for (let gc of result.result.gc_company) {
          let gc_id = gc.id.toString();
          gc_assoc[gc_id] = gc.company_name;
          if (gc.is_gc) {
            gc_filters[gc.id.toString()] = 1;
          }
          if (!gc.is_gc) {
            continue;
          }
          for (let uid in gc.users) {
            if (!(uid in gc_users)) gc_users[uid] = {};
            gc_users[uid][gc_id] = 1;
          }
        }
        // console.log("gc_filters==>", gc_users)
        Store.updateStore("gc_filters", gc_filters);
        Store.updateStore("gc_assoc", gc_assoc);
        Store.updateStore("gc_users", gc_users);

        let r = {};
        r["result"] = {};
        let cts = localStorage.getItem("contactlist") !== null ? JSON.parse(localStorage.getItem("contactlist")) : r;
        // console.log('cts==>', cts)
        let user_roles = {};
        let contactNameValue = "";
        let contacts_all = {};
        let contractsemail_all = {};
        let listcontacts_all = [];
        let attestation_share_group_allUser_new = [];
        let contactid = result.result["contact_id"];
        if ("contactlist" in cts.result && "user_roles" in cts.result) {
          user_roles = cts.result.user_roles["attest"];
          //contactNameValue = cts.result.contactlist[contactid.toString()].ContactName;
          contactNameValue = user_roles[contactid.toString()] !== undefined ? cts.result.contactlist[contactid.toString()].ContactName : "";
          for (let k in cts.result.contactlist) {
            let clists = cts.result.contactlist;
            if (user_roles[k.toString()] !== undefined && clists[k.toString()].IsActive === true && clists[k.toString()].IsDeleted === false) {
              contractsemail_all[k.toString()] = cts.result.contactlist[k.toString()].EmailAddress;
              let listConObject = {};
              listConObject.ID = cts.result.contactlist[k.toString()].ID;
              listConObject.ContactName = cts.result.contactlist[k.toString()].ContactName;
              listConObject.LoginID = cts.result.contactlist[k.toString()].EmailAddress;
              listcontacts_all.push(listConObject);
              if (cts.result.user_roles["attest"][k.toString()] !== "no_access") {
                contacts_all[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
                let shareGripuObject = {};
                shareGripuObject.company_id = result.result["curCompanyID"];
                shareGripuObject.email = cts.result.contactlist[k.toString()].EmailAddress;
                shareGripuObject.name = cts.result.contactlist[k.toString()].ContactName;
                shareGripuObject.role = cts.result.user_roles["attest"][k.toString()];
                shareGripuObject.user_id = cts.result.contactlist[k.toString()].ID;
                attestation_share_group_allUser_new.push(shareGripuObject);
              }
            }
          }
          // console.log("user_roles", user_roles);
          Store.updateStore("contact", contactNameValue);
          Store.updateStore("userroles", user_roles);
          Store.updateStore("contacts", contacts_all);
          Store.updateStore("contactsemail", contractsemail_all);
          Store.updateStore("reviewee_list", listcontacts_all);
          Store.updateStore("attestation_share_group_allusers", attestation_share_group_allUser_new);
          let language_pack = localStorage.getItem("language_pack")!==null ? JSON.parse(localStorage.getItem("language_pack")) : {};
          Store.updateStore('language_pack', language_pack);
        }

        switch (result.result["role"]) {
          case "admin_manager":
            Store.updateStore("view", "manager_index");
            break;
          case "am":
            Store.updateStore("view", "am_index");
            break;
          case "no_access":
            Store.updateStore("view", "no_access");
            break;
          default:
            Store.updateStore("view", "team_index");
        }
        // this.setState({ ready: true });
        let task_id = this.props.match ? parseInt(this.props.match.params.taskId) : 0;
        //console.log("task id =======>", task_id);
        this.setState({
          ready: true,
          refreshCount: this.state.refreshCount + 1,
        });
      }
    } else {
      let alert_param = {
        title: "System Error",
        message: result.error_msg,
        ok_text: "OK",
        confirm: false,
        alertHandler: this.alertHandler,
        stack: { id: 0 },
      };
      this.setState({ ready: true, alert_param: alert_param });
    }
  };

  alertHandler = (result, stack) => {
    // this.setState({alert_param: null})
    // window.location = "/select/ServiceBasedLanding";
    Reload.ReloadPage();
  };

  componentDidMount() {
    //this.callApi()
    let windowUrl = window.location.href;
    let pieces = windowUrl.split("/");
    let is_task_id = pieces[pieces.length - 1];
    // console.log("is_task_id", is_task_id)
    EventBus.registerEvent('system_err', 'AppContent', this.system_err)

    if (windowUrl.indexOf("moduleconfig") != -1) {
      this.moduleIndexapi();
    }else if (windowUrl.indexOf("questionsmanager") != -1) {
      this.questionsManagerIndexapi();
    } else if (windowUrl.indexOf("register") != -1 || windowUrl.indexOf("faqregister") != -1 || (is_task_id !== "" && !isNaN(is_task_id))) {
      this.taskindexApi();
      console.log("task_id");
    } else {
      this.callApi();
      console.log("normal");
    }
  }

  system_err = (error) => {
    let alert_param = {
                            title: 'System Error',
                            message: error,
                            ok_text: 'OK',
                            confirm: false,
                            alertHandler: this.alertHandler,
                            stack: {id: 0}
                          }
        this.setState({ ready: true, alert_param: alert_param, task_loading: false});
    }

  questionsManagerIndexapi() {
    // this.setState({ ready: true, task_loading: false});
    let postData = { command: "questionindex" };
    let api = new APICall();
    api.command(postData, this.processQuestionIndex);
  }

  processQuestionIndex = (result) => {
    // console.log("result processModuleIndex", result)
    if (result.error_code === 0) {
      Store.updateStore("gc_company", result.result["gc_company"]);
      Store.updateStore("role", result.result["role"]);
      Store.updateStore("contact_id", result.result["contact_id"]);
      Store.updateStore("curCompanyID", result.result["curCompanyID"]);
      Store.updateStore("module_config", result.result["module_config"]);
      //userStroage Section
      Store.updateStore("userroles", result.result["user_roles"]);
      Store.updateStore("contact", result.result["contact"]);
      Store.updateStore("contactsemail", result.result["contractsemail"]);
      //userStroage Section

      //raiseEvent Start
      let menu = {};
      if(result.result["role"] === 'admin_manager' || result.result["role"] === 'manager'){
        menu['23gbrsfh432'] = {"id":"23gbrsfh432","display":"Questions Manager","url":"microservice/mstop/attest/questionsmanager","order":3,"menu":"questionsmanager", source: "attest"}
      }else if(result.result["role"] === 'am' && 'general' in result.result["module_config"] && result.result["module_config"].general.allow_am_stp_edit_ques_sets === true){
          menu['23gbrsfh432'] = {"id":"23gbrsfh432","display":"Questions Manager","url":"microservice/mstop/attest/questionsmanager","order":3,"menu":"questionsmanager", source: "attest"}
      }
      //raiseEvent End
      console.log("menu===>", menu)
      EventBus.raiseEvent('menu_render', menu)

      let r = {};
      r["result"] = {};
      let cts = localStorage.getItem("contactlist") !== null ? JSON.parse(localStorage.getItem("contactlist")) : r;
      // console.log('cts==>', cts)
      let user_roles = {};
      let contactNameValue = "";
      let contacts_all = {};
      let contractsemail_all = {};
      let contactid = result.result["contact_id"];
      if ("contactlist" in cts.result && "user_roles" in cts.result) {
        user_roles = cts.result.user_roles["attest"];
        //contactNameValue = cts.result.contactlist[contactid.toString()].ContactName;
        contactNameValue = user_roles[contactid.toString()] !== undefined ? cts.result.contactlist[contactid.toString()].ContactName : "";
        for (let k in cts.result.contactlist) {
          let clists = cts.result.contactlist;
          if(clists[k.toString()].IsActive === true && clists[k.toString()].IsDeleted === false){
            contractsemail_all[k.toString()] = cts.result.contactlist[k.toString()].EmailAddress;
            if (cts.result.user_roles["attest"][k.toString()] !== "no_access") {
              contacts_all[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
            }
          }
        }
        // console.log("user_roles", user_roles);
        Store.updateStore("contact", contactNameValue);
        Store.updateStore("userroles", user_roles);
        Store.updateStore("contacts", contacts_all);
        Store.updateStore("contactsemail", contractsemail_all);
        let language_pack = localStorage.getItem("language_pack")!==null ? JSON.parse(localStorage.getItem("language_pack")) : {};
        Store.updateStore('language_pack', language_pack);
      }

      this.setState({ ready: true, refreshCount: this.state.refreshCount + 1 });
    } else {
      let alert_param = {
        title: "System Error",
        message: result.error_msg,
        ok_text: "Ok",
        confirm: false,
        alertHandler: this.alertHandler,
        stack: { id: 0 },
      };
      this.setState({ ready: true, alert_param: alert_param });
    }
  };

  moduleIndexapi() {
    // this.setState({ ready: true, task_loading: false});
    let postData = { command: "moduleindex" };
    let api = new APICall();
    api.command(postData, this.processModuleIndex);
  }

  processModuleIndex = (result) => {
    // console.log("result processModuleIndex", result)
    if (result.error_code === 0) {
      Store.updateStore("gc_company", result.result["gc_company"]);
      Store.updateStore("role", result.result["role"]);
      Store.updateStore("contact_id", result.result["contact_id"]);
      Store.updateStore("curCompanyID", result.result["curCompanyID"]);
      //userStroage Section
      Store.updateStore("userroles", result.result["user_roles"]);
      Store.updateStore("contact", result.result["contact"]);
      Store.updateStore("contactsemail", result.result["contractsemail"]);
      //userStroage Section

      let r = {};
      r["result"] = {};
      let cts = localStorage.getItem("contactlist") !== null ? JSON.parse(localStorage.getItem("contactlist")) : r;
      // console.log('cts==>', cts)
      let user_roles = {};
      let contactNameValue = "";
      let contacts_all = {};
      let contractsemail_all = {};
      let contactid = result.result["contact_id"];
      if ("contactlist" in cts.result && "user_roles" in cts.result) {
        user_roles = cts.result.user_roles["attest"];
        //contactNameValue = cts.result.contactlist[contactid.toString()].ContactName;
        contactNameValue = user_roles[contactid.toString()] !== undefined ? cts.result.contactlist[contactid.toString()].ContactName : "";
        for (let k in cts.result.contactlist) {
          let clists = cts.result.contactlist;
          if(clists[k.toString()].IsActive === true && clists[k.toString()].IsDeleted === false){
            contractsemail_all[k.toString()] = cts.result.contactlist[k.toString()].EmailAddress;
            if (cts.result.user_roles["attest"][k.toString()] !== "no_access") {
              contacts_all[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
            }
          }
        }
        // console.log("user_roles", user_roles);
        Store.updateStore("contact", contactNameValue);
        Store.updateStore("userroles", user_roles);
        Store.updateStore("contacts", contacts_all);
        Store.updateStore("contactsemail", contractsemail_all);
        let language_pack = localStorage.getItem("language_pack")!==null ? JSON.parse(localStorage.getItem("language_pack")) : {};
        Store.updateStore('language_pack', language_pack);
      }

      this.setState({ ready: true, refreshCount: this.state.refreshCount + 1 });
    } else {
      let alert_param = {
        title: "System Error",
        message: result.error_msg,
        ok_text: "Ok",
        confirm: false,
        alertHandler: this.alertHandler,
        stack: { id: 0 },
      };
      this.setState({ ready: true, alert_param: alert_param });
    }
  };

  taskindexApi() {
    let postData = { command: "taskindex" };
    let api = new APICall();
    Utils.log("sending first call");
    api.command(postData, this.processtaskIndex);
    this.setState({ task_loading: false });
  }

  callApi() {
    let postData = { command: "index" };
    let api = new APICall();
    Utils.log("sending first call");
    api.command(postData, this.processIndex);
    let task_loading = Store.getStoreData("is_admin") ? false : true;
    this.setState({ task_loading: task_loading });
  }

  refreshState = () => {
    this.setState({ refreshCount: this.state.refreshCount + 1 });
  };

  render() {
    let task_loading = Store.getStoreData("is_admin") ? false : this.state.task_loading;
    //console.log("this.state", this.state)
    if (task_loading) {
      if (Store.getStoreData("attest-index") === null) {
        return (
          <div>
            <CSLPageloader />
          </div>
        );
      }
    }
    // if(this.state.task_loading){
    //   if(Store.getStoreData('attest-index') === null) {
    //     return <div><CSLPageloader /></div>
    //   }
    // }
    if (!this.state.ready) {
      return (
        <div>
          <CSLLoader />
        </div>
      );
    }
    if (this.state.alert_param !== null) {
      return <AlertBox alertParam={this.state.alert_param} />;
    }

    //console.log('AppJs this.state',  this.state)
    return (
        <Router>
          <Switch>
            <Route path="/moduleconfig" exact>
              <ModuleConfigHOC />
            </Route>
            <Route path="/questionsmanager" exact>
              <QuestionsManagerHOC />
            </Route>
            <Route path="/" exact>
              <Index refreshCallback={this.refreshState} noParam={true} refresh={this.state.refreshCount} />
            </Route>
            <Route path="/:taskId" component={Index} exact />
          </Switch>
        </Router>
    );
  }
}

class App extends React.Component {
  state = { ready: false}

  setComponentState = () => {
    console.log('this.props', this.props)
    let is_debug = false
    let api_url = process.env.REACT_APP_API_URL;
    Store.updateStore('is_debug', is_debug)
    Store.updateStore('api_url', api_url)

    authService.refresh().then(() => {
      this.setState({ready: true})
    })
  }

  componentDidMount = () => {
    this.setComponentState()
  }

  componentDidUpdate = (prevprops) => {
        if(this.props === prevprops) return
        this.setComponentState()
    }

  render() {
    if(!this.state.ready) {
        return <div>Loading...</div>
    }
    return <AppContent />
  }
}

export default App;
